import React, { forwardRef, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  useTheme,
  Typography,
} from "@mui/material";
import TextField from '@mui/material/TextField';
import { CRUD } from "../Constants";
import CustomLabel from "../components/Input/CustomLabel";
import { useCreateProject } from "../api/projects/createProject";
import { useUpdateProject } from "../api/projects/updateProject";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { useTranslation } from "react-i18next";

interface CreateProjectProps {
  open: boolean;
  onClose: () => void;
  action?: string;
  project?: any;
}

const CreateUpdateProjectDialog = forwardRef<HTMLDivElement, CreateProjectProps>(
  (
    {
      open,
      onClose,
      action = CRUD.Create,
      project = { name: "", description: "" },
    },
    ref
  ) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [projectName, setProjectName] = useState(project?.name || "");
    const [projectDescription, setProjectDescription] = useState(project?.description || "");
    const title = action == CRUD.Create ? t('project_form.title_create') : t('project_form.title_edit');
    const actionText = action == CRUD.Create ? t('create') : t('save');

    const { mutate: createProject } = useCreateProject();
    const { mutate: updateProject } = useUpdateProject();

    const handleSubmit = (event: React.FormEvent) => {
      event.preventDefault();
      const clonedProject = Object.assign({}, project);
      clonedProject.name = projectName;
      clonedProject.description = projectDescription;
      setProjectName("");
      setProjectDescription("");
      if (action == CRUD.Create) {
        createProject(clonedProject);
      } else {
        updateProject({ projectId: project.id, project: clonedProject });
      }
      onClose();
    };

    useEffect(() => {
      setProjectName(project?.name || "");
      setProjectDescription(project?.description || "");
    }, [project.id])


    return (
      <Dialog open={open} onClose={onClose} fullWidth={true}>
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent dividers>
            <Stack spacing={4}>
              {action == CRUD.Create &&
                <Typography variant="subtitle2">
                  {t('project_form.description')}
                </Typography>
              }
              <Box>
                <TextField
                  fullWidth
                  id="name"
                  label={t('project_form.fields.project_title_label')}
                  helperText={t('project_form.fields.project_title_helpertext')}
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  id="description"
                  type="text"
                  multiline
                  rows={4}
                  value={projectDescription}
                  label={t('project_form.fields.project_description_label')}
                  helperText={t('project_form.fields.project_description_helpertext')}
                  onChange={(e) => setProjectDescription(e.target.value)}
                />
              </Box>
            </Stack>

          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button type="submit" variant="contained" disabled={projectName.length < 1}>
              {actionText}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    );
  }
);

export default CreateUpdateProjectDialog;

type buildParamsOptions = {
    page?: number;
    perPage?: number;
    filters?: object;
    sortBy?: string;
    sortDir?: string;
    search?: string;
};

export const buildParams = ({ page = 1, perPage = 10, filters = null, sortBy = null, sortDir = 'asc', search = null }: buildParamsOptions) => {
    const params = {
        page: page,
        per_page: perPage,
    }

    if (filters) {
        for (const [key, value] of Object.entries(filters)) {
            params[key] = value;
        }
    }

    if (sortBy) {
        const orderStr = sortDir === 'asc' ? '' : '-';
        params["ordering"] = `${orderStr}${sortBy}`;
    }

    if (search) {
        params["search"] = search;
    }

    return params;
}
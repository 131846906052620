import api from "../base";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { buildParams } from "../params";

type GetCreationsOptions = {
    page?: number;
    perPage?: number;
    filters?: any;
    sortBy?: string;
    sortDir?: string;
    search?: string;
    includeVersions?: boolean;
};

type GetCreationsByProjectOptions = {
    projectId: string;
    page?: number;
    perPage?: number;
};

export async function getCreations({ page = 1, perPage = 10, filters = null, sortBy = null, sortDir = 'asc', search = null, includeVersions = false }: GetCreationsOptions) {
    const params = buildParams({ page, perPage, filters, sortBy, sortDir, search });

    if (includeVersions) {
        params["include_versions"] = true;
    }

    const response = await api.get("/creations/", {
        params: params,
    });
    return response.data;
}

export async function getCreationsByProjectId({ projectId, page = 1, perPage = 10 }: GetCreationsByProjectOptions) {
    const response = await api.get(`/projects/${projectId}/creations/`, {
        params: {
            page: page,
            per_page: perPage,
        }
    });
    return response.data;
}

export const useGetCreations = ({ page = 1, perPage = 10, filters = null, sortBy = null, sortDir = 'asc', search = null, includeVersions = false, config = {} }) => {
    return useQuery({
        ...config,
        queryKey: ["creations", page, perPage, filters, sortBy, sortDir, search, includeVersions],
        queryFn: () => getCreations({ page, perPage, filters, sortBy, sortDir, search, includeVersions }),
        placeholderData: keepPreviousData
    });
};

export const useGetCreationsByProjectId = ({ projectId, page = 1, perPage = 10, config = {} }) => {
    return useQuery({
        ...config,
        queryKey: ["creations", projectId, page, perPage],
        queryFn: () => getCreationsByProjectId({ projectId, page, perPage }),
        placeholderData: keepPreviousData
    });
};

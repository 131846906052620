import { useIDPContext } from "@copyrightdelta/drx-frontend-api";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export const LoginRedirect: React.FC = () => {
    const { user, login, logginInProgress, authenticated, idpReady, identityProviderStatus } = useIDPContext();

    useEffect(() => {
        const handleLogin = async () => {
            if (window.location.search.includes("?code")) {
                return;
            } else if (identityProviderStatus === "LOGGING_OUT") {
                return;
            } else if (identityProviderStatus === "LOGGING_IN") {
                return;
            }

            if (idpReady && !authenticated && !logginInProgress) {
                await login();
                return;
            } else if (idpReady && user?.isVisitor) {
                await login();
                return
            }


        };

        handleLogin();
    }, [idpReady, authenticated, logginInProgress, user?.isVisitor]);

    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <CircularProgress />
        </Box>
    );
}
import { Box, Typography } from '@mui/material'
import React from 'react'

const TxtDisplay = ({ url }) => {
    const [text, setText] = React.useState('')

    React.useEffect(() => {
        fetch(url)
            .then(response => response.text())
            .then(data => {
                setText(data)
            })
    }, [url])

    return (
        <Box sx={{
            width: "60%",
            margin: "0 auto",
        }}>
            <Typography variant="body2" align='left' sx={{ whiteSpace: "pre-wrap" }}>
                {text}
            </Typography>
        </Box>
    )
}

export default TxtDisplay

import { Typography, useTheme } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  timelineItemClasses,
} from "@mui/lab";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Pagination from "./Pagination";

interface VersionDisplayProps {
  versions: any[];
  current_version?: number;
  navigate: (location) => void;
}

const VersionHistoryTimeline: React.FC<VersionDisplayProps> = ({
  versions,
  navigate,
  current_version = -1,
}) => {
  const theme = useTheme();
  const [pageNum, setPageNum] = useState(1);
  const perPage = 5;
  const pageCount = Math.ceil(versions.length / perPage);
  const { projectId, creationId, versionNr } = useParams();
  let reversedVersions = [].concat(versions);
  reversedVersions.reverse();

  // Get page where the current_version is located
  useEffect(() => {
    const copyVersions = [].concat(versions).reverse();
    const idx = copyVersions.findIndex((version) => version.version === current_version) + 1;
    const currPage = Math.ceil(idx / perPage);
    setPageNum(currPage);
  }, [versions]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNum(value);
  };

  const onClickNavigate = (version) => {
    navigate(`/projects/${projectId}/${creationId}/${version}`);
  };

  const pagedVersionList = reversedVersions.slice(
    (pageNum - 1) * perPage,
    pageNum * perPage
  );

  return (
    <>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
          paddingBottom: "0px",
          marginBottom: "0px",
        }}
      >
        {pagedVersionList.map((version, index) => (
          <TimelineItem
            key={index}
            onClick={() => onClickNavigate(version.version)}
            sx={{ cursor: "pointer" }}
          >
            <TimelineSeparator>
              <TimelineDot
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "22px",
                  height: "22px",
                  boxShadow: "none",
                  backgroundColor:
                    current_version === version.version
                      ? theme.palette.primary.main
                      : theme.palette.secondary.main,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    color:
                      current_version === version.version
                        ? theme.palette.primary.contrastText
                        : theme.palette.primary.main,
                  }}
                >
                  {version?.version}
                </Typography>
              </TimelineDot>
              {index == reversedVersions.length - 1 ? null : (
                <TimelineConnector
                  sx={{ backgroundColor: theme.palette.divider }}
                />
              )}
            </TimelineSeparator>
            <TimelineContent>
              <Typography
                variant="body1"
                style={{
                  color:
                    current_version === version.version
                      ? theme.palette.primary.main
                      : undefined,
                }}
              >
                {version.name}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  fontSize: "0.8rem",
                  color: theme.palette.text.secondary,
                }}
              >
                {moment(version.date_created).format("LLL")}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <Pagination count={pageCount} onChange={handlePageChange} page={pageNum} size={"small"} padding={"0px 0px"} />
    </>
  );
};

export default VersionHistoryTimeline;

import api from "../base";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface UpdateUser {
    language: string;
};

export async function updateUser(user: UpdateUser) {
    try {
        const response = await api.patch(`/user/`, user);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
}


export const useUpdateUser = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (user: UpdateUser) => updateUser(user),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user'] })
        }
    })
};
import { ListItemIcon, ListItemText, Menu, MenuItem, useTheme } from '@mui/material'
import React, { useState } from 'react'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import ConfirmDeleteModal from '../modals/ConfirmDeleteDialog';
import SharingLinkDetailDialog from './SharingLinkDetailDialog';

interface SharingLinkMenuProps {
    anchorEl: HTMLElement | null;
    handleClose: () => void;
    selectedShare: any;
    handleDelete: () => void;
}

const SharingLinkMenu: React.FC<SharingLinkMenuProps> = ({ anchorEl, handleClose, selectedShare, handleDelete }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const menuOpen = Boolean(anchorEl);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const visitShare = async (share) => {
        // remove trailing slash from window.location.origin if present
        let host = window.location.origin;
        if (host.endsWith("/")) {
            host = window.location.origin.slice(0, -1);
        }

        window.open(`${host}/share/${share.id}`, "_blank");
    };

    const viewDetails = (selectedShare) => {
        // setSelectedShare(selectedShare);
        setDetailsOpen(true);
    }

    const handleDetailsClose = () => {
        setDetailsOpen(false);
        handleClose();
    }

    return (
        <>
            <SharingLinkDetailDialog detailsOpen={detailsOpen} handleDetailsClose={handleDetailsClose} selectedShare={selectedShare} />
            <Menu
                id="share-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
            >
                <MenuItem dense onClick={() => visitShare(selectedShare)}>
                    <ListItemIcon>
                        <VisibilityRoundedIcon />
                    </ListItemIcon>
                    <ListItemText>{t('view')}</ListItemText>
                </MenuItem>
                <MenuItem dense onClick={() => viewDetails(selectedShare)}>
                    <ListItemIcon>
                        <CommentRoundedIcon />
                    </ListItemIcon>
                    <ListItemText>{t('view_details')}</ListItemText>
                </MenuItem>
                <MenuItem
                    dense
                    onClick={() => setDeleteModalOpen(true)}
                    sx={{ color: theme.palette.error.main }}
                >
                    <ListItemIcon>
                        <DeleteIcon sx={{ color: theme.palette.error.main }} />
                    </ListItemIcon>
                    <ListItemText>{t('delete')}</ListItemText>
                </MenuItem>
            </Menu>
            <ConfirmDeleteModal
                open={isDeleteModalOpen}
                message={t("delete_sharing_link_message")}
                onConfirm={handleDelete}
                onClose={() => setDeleteModalOpen(false)}
            />
        </>
    )
}

export default SharingLinkMenu

import React from 'react';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';
import 'video.js/dist/video-js.css';

interface VideoJSPlayerProps {
    src: string;
    contentType: string;
    getUpdatedFileDownloadUrl: () => any;
}

export const VideoJS: React.FC<VideoJSPlayerProps> = ({ src, getUpdatedFileDownloadUrl, contentType }) => {
    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);
    const [currentSrc, setCurrentSrc] = React.useState(src);

    const options = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        height: 300,
        sources: [{
            src: currentSrc,
            type: "video/mp4"
        }]
    };

    let debounceTimeoutId: NodeJS.Timeout | null = null;

    const updateSource = async (player: Player, secondsBeforeExpiry: number = 0) => {
        if (debounceTimeoutId !== null) {
            // If retryWithNewUrl was called within the last 10 seconds, do nothing
            // console.log("Debouncing...");
            return;
        }

        const currentTime = player.currentTime();
        const url = await getUpdatedFileDownloadUrl();
        setCurrentSrc(url);
        // console.log("Retrying with new URL: ", url);
        player.src({
            src: url,
            type: "video/mp4"
        });
        if (!player.paused()) {
            player.play()
        }
        player.currentTime(currentTime);

        debounceTimeoutId = setTimeout(() => {
            debounceTimeoutId = null;
        }, 2000);
    }

    const onReady = (player: Player) => {
        playerRef.current = player;

        // // You can handle player events here, for example:
        player.on('stalled', () => {
            videojs.log('player is stalled');
            updateSource(player)
        });

        player.on('error', () => {
            console.log("Error occurred while loading video source.");
            console.log("Error:", player.error().code, player.error().message);
            if (player.error().code === 2) {
                updateSource(player);
            }
        });
    };


    React.useEffect(() => {

        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
            const videoElement = document.createElement("video-js");

            videoElement.classList.add('vjs-big-play-centered');
            videoRef.current.appendChild(videoElement);

            const player = playerRef.current = videojs(videoElement, options, () => {
                videojs.log('player is ready');
                onReady && onReady(player);
            });

            // You could update an existing player in the `else` block here
            // on prop change, for example:
        } else {
            const player = playerRef.current;

            player.autoplay(options.autoplay);
            player.src(options.sources);
        }
    }, [options, videoRef]);

    // Dispose the Video.js player when the functional component unmounts
    React.useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        <div data-vjs-player>
            <div ref={videoRef} onContextMenu={(event) => event.preventDefault()} />
        </div>
    );
}

export default VideoJS;
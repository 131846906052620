import axios from 'axios';
import { API_ENDPOINT, THEME } from "../Settings";

const api = axios.create({
    baseURL: API_ENDPOINT + "/api/v1",
});

export const setProfileIdInHeader = () => {
    const selectedProfile = localStorage.getItem('selectedProfileId');
    if (selectedProfile && selectedProfile !== 'undefined') {
        api.defaults.headers['cpd-profile-id'] = selectedProfile;
    }
}

// Add an interceptor to set the authentication header
api.interceptors.request.use((config) => {
    // if selectedProfile in local storage, set a header with the selected profile
    const selectedProfile = localStorage.getItem('selectedProfileId');
    if (selectedProfile && selectedProfile !== 'undefined') {
        config.headers['cpd-profile-id'] = selectedProfile;
    }

    const idp_token_json = localStorage.getItem('drx-identity-token')
    let token = null
    let origToken = null
    if (idp_token_json) {
        const parsedToken = JSON.parse(idp_token_json)
        token = parsedToken.access_token
        origToken = parsedToken.original_token
    }
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    if (origToken) {
        config.headers['x-authorization-orig'] = `Bearer ${origToken}`;
    }

    config.headers['cpd-tenant-id'] = THEME;
    return config;
});

export default api;

import api from "../base";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { buildParams } from "../params";

type GetSharingLinksOptions = {
    page?: number;
    perPage?: number;
    active?: boolean;
    sortBy?: string;
    sortDir?: string;
    search?: string;
};

export async function getSharingLinks({ page = 1, perPage = 10, active = null, sortBy = null, sortDir = 'asc', search = null }: GetSharingLinksOptions) {
    // params should allow true, false or null/undefined for is_active
    // if null, it should not be present in the query string
    const params = buildParams({ page, perPage, sortBy, sortDir, search });

    if (active !== null && active !== undefined) {
        params["is_active"] = active;
    }

    const response = await api.get("/sharing-links/", {
        params: params,
    });
    return response.data;
}

export const useGetSharingLinks = ({ page = 1, perPage = 10, active = null, sortBy = null, sortDir = 'asc', search = null, config = {} }) => {
    return useQuery({
        ...config,
        queryKey: ["links", page, perPage, active, sortBy, sortDir, search],
        queryFn: () => getSharingLinks({ page, perPage, active, sortBy, sortDir, search }),
        placeholderData: keepPreviousData,
        staleTime: 1000 * 60 * 10, // 10 minutes
    });
};


type GetSharingLinksAccessOptions = {
    page?: number;
    perPage?: number;
    sharingId?: string;
};

export async function getSharingLinksAccess({ page = 1, perPage = 10, sharingId = null }: GetSharingLinksAccessOptions) {
    // params should allow true, false or null/undefined for is_active
    // if null, it should not be present in the query string
    const params = {
        page: page,
        per_page: perPage,
    }

    const response = await api.get(`/sharing-links/${sharingId}/access-log/`, {
        params: params,
    });
    return response.data;
}

export const useGetSharingLinksAccess = ({ page = 1, perPage = 10, sharingId = null, config = {} }) => {
    return useQuery({
        ...config,
        queryKey: ["links", page, perPage, sharingId],
        queryFn: () => getSharingLinksAccess({ page, perPage, sharingId }),
        placeholderData: keepPreviousData,
        staleTime: 1000 * 60 * 10, // 10 minutes
    });
};
import {
  createCreation,
  deleteCreation,
  getCreation,
  getCreations,
  getVersions,
  createVersion,
  getVersion,
  deleteVersion,
} from "./creations";
import { getStorage } from "./user";
import { getfile, getShares, shareFile, getSharedFile, unshareFile, trackSharedFileAccess, requestOtpCode } from "./files";
import { listStats } from "./stats";


export const sharingAPI = {
  getFile: getfile,
  getShares: getShares,
  shareFile: shareFile,
  unshareFile: unshareFile,
  getSharedFile: getSharedFile,
  trackSharedFileAccess: trackSharedFileAccess,
  requestOtpCode: requestOtpCode,
};

export const versionsAPI = {
  get: getVersions,
  create: createVersion,
  getSingle: getVersion,
  delete: deleteVersion
};

export const creationAPI = {
  get: getCreations,
  getSingle: getCreation,
  create: createCreation,
  delete: deleteCreation,
};

export const statsAPI = {
  list: listStats,
}

export const userAPI = {
  getStorage: getStorage,
}

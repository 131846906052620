import api from "../base";

type resendTimestampConfirmationEmailOptions = {
    creationId: string;
    versionNum: string;
};

export async function resendTimestampConfirmationEmail({ creationId, versionNum }: resendTimestampConfirmationEmailOptions) {
    const response = await api.post(`/creations/${creationId}/versions/${versionNum}/send-timestamp-confirmation-email/`, {});
    return response
}

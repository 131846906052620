import api from "../base";
import { useMutation, useQueryClient } from "@tanstack/react-query";


export async function deleteCreationVersion(creationVersion: any, forceDelete = false) {
    let url = `/creations/${creationVersion.creation_id}/versions/${creationVersion.version}/`;
    if (forceDelete) {
        url += "?force_delete=true";
    }
    const response = await api.delete(url);
    return response.data;
}

export const useDeleteCreationVersion = (onError = null) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (creationVersion: any) => deleteCreationVersion(creationVersion),
        onSuccess: (creationVersion) => {
            queryClient.invalidateQueries({ queryKey: ['creation-versions'] })
            queryClient.invalidateQueries({ queryKey: ['creations'] })
        },
        onError: (error) => {
            if (error) onError(error)
        }
    });
};
import { Box, Typography } from '@mui/material'
import React from 'react'
import linkExpired from "../../../assets/images/linkExpired.png";
import { useTranslation } from 'react-i18next';


const SharedLinkNotFound = () => {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                backgroundColor: "background.paper",
                borderRadius: "10px",
                textAlign: "center",
                width: { xs: "90%", lg: "50%" },
                maxWidth: "1000px",
                mt: 1
            }}
        >
            <Box component="img" src={linkExpired} sx={{ m: 1, width: "100px", height: "100px" }} />
            <Typography paragraph sx={{ fontWeight: "500px", m: "20px 0px" }}>
                {t('link_expired')}
            </Typography>
        </Box>
    )
}

export default SharedLinkNotFound

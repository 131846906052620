import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { Stack, Typography } from '@mui/material';
interface SortConfig {
    key: string;
    direction: 'asc' | 'desc';
}

interface SortableHeadingProps {
    keyProp: string; // Renamed due to 'key' being a reserved prop in React
    value: string;
    sortConfig: SortConfig;
    requestSort: (key: string) => void;
}

const SortableHeading: React.FC<SortableHeadingProps> = ({ keyProp, value, sortConfig, requestSort }) => {
    const [isHovered, setIsHovered] = useState(false);
    const iconStyle = { fontSize: 16 };
    // Inline style for showing the arrow only on hover or when the column is sorted
    const hoverStyle = isHovered || sortConfig.key === keyProp ? {} : { visibility: 'hidden' };
    return (
        <TableCell
            onClick={() => requestSort(keyProp)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{ cursor: 'pointer' }} // Optional, for showing a pointer cursor on hover
        >
            <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>{value}</Typography>
                {sortConfig.key === keyProp ? (
                    sortConfig.direction === 'asc' ? (
                        <ArrowUpwardRoundedIcon sx={iconStyle} />
                    ) : (
                        <ArrowDownwardRoundedIcon sx={iconStyle} />
                    )
                ) : (
                    <ArrowUpwardRoundedIcon sx={{ ...hoverStyle, ...iconStyle }} />
                )}
            </Stack>
        </TableCell>
    );
};

export default SortableHeading;

import api from "../base";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

type GetProjectsOptions = {
    page?: number;
    perPage?: number;
};

export async function getProjects({ page = 1, perPage = 10 }: GetProjectsOptions) {
    const response = await api.get("/projects/", {
        params: {
            page: page,
            per_page: perPage,
        }
    });
    return response.data;
}

export const useGetProjects = ({ page = 1, perPage = 10, config = {} }) => {
    return useQuery({
        ...config,
        queryKey: ["projects", page, perPage],
        queryFn: () => getProjects({ page, perPage }),
        placeholderData: keepPreviousData,
        staleTime: 1000 * 60 * 10, // 10 minutes
    });
};

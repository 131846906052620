import { createContext } from 'react';

interface TenantConfig {
    show_storage?: boolean;
    show_about_dialog?: boolean;
    logo_url?: string;
    header_link?: any;
    languages?: string[];
}

export const TenantConfigContext = createContext<TenantConfig>({});
import api from "../base";
import { useMutation, useQueryClient } from "@tanstack/react-query";


export async function deleteProject(projectId: string) {
    const response = await api.delete(`/projects/${projectId}/`);
    return response.data;
}
export const useDeleteProject = (onError = null) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (projectId: string) => deleteProject(projectId),
        onSuccess: (projectId) => {
            queryClient.invalidateQueries({ queryKey: ['projects'] })
            queryClient.invalidateQueries({ queryKey: ['project', projectId] })
            // Also invalidate any creations
            queryClient.invalidateQueries({ queryKey: ['creations'] })
            queryClient.invalidateQueries({ queryKey: ['creation'] })
        },
        onError: (error) => {
            if (error) onError(error)
        }
    });
};
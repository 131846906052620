import axios from "axios";
import api from "./base";
import { deleteCreationVersion } from "./creations/deleteCreationVersion";

export interface PresignedUrlResponse {
    creation_id: string;
    creation_version_id: string;
    version: number;
    presigned_url: {
        url: string;
        fields: {
            key: string;
            [headerName: string]: string;
        };
    };
}


export const uploadToS3 = async (presignedData: PresignedUrlResponse, file: File) => {
    let axios_client = axios;
    let url = presignedData.presigned_url.url;

    // Check if this is an internal API call (Azure blob storage)
    if (url.startsWith("/")) {
        url = url.replace("/api/v1", "");
        axios_client = api as any;

        // Implement chunked upload for Azure
        const chunkSize = 1024 * 1024 * 5; // TODO: INCREASE to 5MB
        const totalChunks = Math.ceil(file.size / chunkSize);
        try {
            for (let i = 0; i < totalChunks; i++) {
                console.log(`Uploading chunk ${i + 1}/${totalChunks}...`);
                const start = i * chunkSize;
                const end = Math.min(start + chunkSize, file.size);
                const chunk = file.slice(start, end);

                const formData = new FormData();
                formData.append('file_name', file.name);
                formData.append('chunk_index', i.toString());
                formData.append('chunk_data', chunk);
                formData.append('total_chunks', totalChunks.toString());
                formData.append('creation_version_id', presignedData.creation_version_id);
                formData.append('key', presignedData.presigned_url.fields.key);

                const response = await axios_client.post(url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                console.log(`Chunk ${i + 1}/${totalChunks} uploaded:`, response.data.message);
            }

            return { status: 'success' };
        } catch (error) {
            console.error('Error uploading file:', error);
            const req = {
                creation_id: presignedData.creation_id,
                version: presignedData.version,
            }
            await deleteCreationVersion(req, true);
            throw error;
        }
    } else {
        // Original S3 upload logic
        const formData = new FormData();

        // Add all fields from the presigned_url object
        for (const [key, value] of Object.entries(presignedData.presigned_url.fields)) {
            formData.append(key, value);
        }

        // Append the file
        formData.append('file', file, file.name);

        try {
            const response = await axios_client.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // Successfully uploaded
            return response;
        } catch (error) {
            console.error('Error uploading file:', error);
            // Delete creation version if upload fails
            const req = {
                creation_id: presignedData.creation_id,
                version: presignedData.version,
            }
            await deleteCreationVersion(req, true);
            throw error;
        }
    }
};

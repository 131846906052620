import Grid from '@mui/material/Grid'
import RecentCreationsItem from './RecentCreationsItem';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetCreations } from '../api/creations/getCreations';

const RecentCreationsGrid = () => {
    const perPage = 5;
    const page = 1;
    const { data: creations } = useGetCreations({ page, perPage });

    const { t } = useTranslation();
    return (
        creations?.count > 0 &&
        <Stack sx={{ direction: "column", gap: 4, mb: 2, mt: 2 }}>
            <Typography variant="h6">
                {t("recent creations")}
            </Typography>
            <Grid container spacing={2} columns={5}
                className="myProjectScrollbar"
                sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    maxWidth: "100vw",
                    overflowX: "scroll",
                    overflowY: "hidden",
                    position: "relative",
                }}>
                {creations?.results.map((creation, index) => (
                    <RecentCreationsItem
                        key={index}
                        id={creation.id}
                        projectId={creation.project.id}
                        projectName={creation.project.name}
                        creationType={creation.creation_type}
                        currentVersionName={creation.current_version?.name}
                        dateModified={creation.current_version?.date_created}
                    />
                ))}
            </Grid>
        </Stack>
    )
}

export default RecentCreationsGrid

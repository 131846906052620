import React from 'react'
import { API_ENDPOINT, } from "../../../Settings";
import FileDisplay from '../../../components/FileDisplay';
import { Box, Typography } from '@mui/material';

const audioFileExtensions = ["mp3", "flac", "ogg", "wav", "m4a", "aac"];
const audioFileContentTypes = [
  "audio/mpeg",
  "audio/wav",
  "audio/x-wav",
  "audio/wave",
  "audio/vnd.wav",
  "audio/vnd.wave",
  "audio/mp3",
  "audio/ogg",
  "audio/flac",
  "audio/x-flac",
  "audio/x-aac",
  "audio/x-m4a",
  "audio/mp4",
  "audio/mp4a",
  "audio/aac",
  "audio/x-aac",
  "video / 3gpp"
]

type ExternalFileDisplayProps = {
  url: string
  name: string
}

const ExternalFileDisplay: React.FC<ExternalFileDisplayProps> = ({ url, name }) => {
  if (url.startsWith("/")) {
    url = API_ENDPOINT + url;
  }
  const mimeType = new URL(url).searchParams.get("content_type");
  const fileExtension = url?.split(".").pop().split("?")[0];

  const isAudioFile = audioFileExtensions.includes(fileExtension) || audioFileContentTypes.includes(mimeType);
  const fileDisplay = () => {
    return (
      <FileDisplay
        url={url}
        fileExtention={fileExtension}
        mimeType={mimeType}
        fileName={name}
      />)
  }


  return isAudioFile ? (
    <>
      {fileDisplay()}
    </>
  ) : (
    <Box
      sx={{
        m: "0 auto",
        boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.1)",
        p: { lg: "30px", xs: "10px" },
        pt: { lg: "10px", xs: "5px" },
        borderRadius: "5px",
        backgroundColor: "background.paper",
        mb: 10
      }}
    >
      <Box
        sx={{
          overflow: "auto", height: "100%"
        }}
        className="versionOverflow"
      >
        <Typography variant={"h6"} sx={{ mt: 1, mb: 2 }}>{name}</Typography>
        {fileDisplay()}
      </Box>
    </Box >
  )
}

export default ExternalFileDisplay

import { uploadToS3 } from "./s3";
import api from "./base";

export async function getCreation(creationId) {
    const response = await api.get(`/creations/${creationId}/`);
    return response.data
}

export async function getVersion(creationId, versionNr) {
    const response = await api.get(`/creations/${creationId}/versions/${versionNr}/`);
    return response.data
}

export async function deleteCreation(creationId) {
    const response = await api.delete(`/creations/${creationId}/`);
}

export async function deleteVersion(creationId, versionNr) {
    const response = await api.delete(`/creations/${creationId}/versions/${versionNr}/`);
}

export async function createCreation(name, description, projectId, uploadType, file: File, contentHash) {

    try {
        const response = await api.post(`/creations/`,
            {
                "filename": file.name,
                "name": name,
                "description": description,
                "creation_type": uploadType,
                "project_id": projectId,
                "content_type": file.type,
                "content_hash": contentHash
            });
        await uploadToS3(response.data, file)
        return response;
    } catch (error) {
        console.error(error);
        return error.response;
    }

}


export async function createVersion(creationId, name, description, projectId, uploadType, file: File, contentHash: string) {

    try {
        const response = await api.post(`/creations/${creationId}/versions/`,
            {
                "filename": file.name,
                "name": name,
                "description": description,
                "creation_type": uploadType,
                "project_id": projectId,
                "content_type": file.type,
                "content_hash": contentHash
            });
        await uploadToS3(response.data, file)
        return response;
    } catch (error) {
        console.error(error);
        return error.response;
    }

}

export async function getVersions(creationId) {
    try {
        const response = await api.get(`/creations/${creationId}/versions/?page=1&per_page=20`);
        return response.data.results.sort((a, b) => a.version - b.version);
    } catch (error) {

        console.error(error);
        return {}
    }

}

export async function getCreations(projectId = null) {
    let url = projectId == null ? `/creations/` : `/projects/${projectId}/creations/`;

    try {
        const response = await api.get(url);
        return response.data.results;
    } catch (error) {

        console.error(error);
        return {}
    }
}
import api from "./base";

export async function getStorage() {
  try {
    const response = await api.get(`/user/storage`);
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
}

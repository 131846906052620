import api from "../base";
import { useMutation, useQueryClient } from "@tanstack/react-query";


export async function createProject(project) {
    const response = await api.post("/projects/", project);
    return response.data;
}

export const useCreateProject = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (project) => createProject(project),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['projects'] })
        },
    });
};
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";

import ProjectDetail from "./pages/ProjectDetail";
import MyProjects from "./pages/MyProjects";
import VersionedFile from "./pages/VersionedFile";

import ExternalShare from "./pages/external/ExternalShare";
import ResponsiveLayout from "./pages/Layout";
import Stats from "./pages/admin/Dashboard";
import SharingLinks from "./pages/SharingLinks";
import { TenantConfigContext } from "./context/tenantConfigContext";
import { useGetTenantConfig } from "./api/tenant/getTenantConfig";
import { useIDPContext } from '@copyrightdelta/drx-frontend-api';
import { LoginRedirect } from "./pages/LoginRedirect";
import { API_ENDPOINT, THEME } from "./Settings";


const RedirectToHome: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  });

  return <></>;
}

const App: React.FC = () => {
  const { user, token, switchProfile, selectedProfile, profiles } = useIDPContext()

  const { data: tenantConfig } = useGetTenantConfig({ config: { enabled: !!THEME && !!API_ENDPOINT, } });

  // switch to correct local profile
  const handleCheckProfile = async () => {
    const storedSelectedProfileId = localStorage.getItem('selectedProfileId')
    if (storedSelectedProfileId && storedSelectedProfileId !== selectedProfile) {
      await switchProfile(storedSelectedProfileId)
    }
  }

  useEffect(() => {
    if (profiles?.length > 0) {
      handleCheckProfile()
    }
  }, [token])


  return (
    <>
      <TenantConfigContext.Provider value={tenantConfig}>
        <Router>
          <Routes>
            {/* Public Routes */}
            <Route path="/share/:shareId" element={<ExternalShare />} />

            {/* Private Routes */}
            {(token && !user.isVisitor) && (
              <>
                <Route path="/" element={<ResponsiveLayout />}>
                  <Route index element={<MyProjects />} />
                  <Route path="/login/check" element={<RedirectToHome />} />
                  <Route
                    path="/projects/:projectId/:creationId/:versionNr"
                    element={<VersionedFile />}
                  />
                  <Route
                    path="/projects/:projectId/:creationId"
                    element={<VersionedFile />}
                  />
                  <Route path="/projects/:projectId" element={<ProjectDetail />} />
                  <Route path="/projects/" element={<MyProjects />} />
                  <Route path="/links/" element={<SharingLinks />} />
                  <Route
                    path="/admin"
                    element={<Stats />} />
                </Route>
                <Route path="/share/:shareId" element={<ExternalShare />} />
              </>
            )}
            {/* Default Redirect to Login Route */}
            <Route path="*" element={<LoginRedirect />} />
          </Routes>
        </Router>
      </TenantConfigContext.Provider >
    </>
  );
}


export default App;

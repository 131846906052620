import { createSvgIcon } from "@mui/material";

export const InboxIcon = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 37 36"
        strokeWidth={1.5}
        // stroke="currentColor"
        className="h-6 w-6"
    >
        <path
            d="M35.9939 14.9112L35.9845 14.8737L30.7252 1.50962C30.4908 0.754931 29.7923 0.234619 29.0002 0.234619H7.68141C6.88453 0.234619 6.17672 0.764307 5.95172 1.52837L1.03453 14.7565L1.02047 14.7893L1.01109 14.8268C0.950156 15.0565 0.931406 15.2909 0.964219 15.5206C0.959531 15.5956 0.954844 15.6706 0.954844 15.7456V32.8971C0.956083 33.6526 1.25675 34.3768 1.79096 34.911C2.32517 35.4452 3.04936 35.7459 3.80484 35.7471H33.2048C34.7752 35.7471 36.0548 34.4674 36.0595 32.8971V15.7456C36.0595 15.6846 36.0595 15.6237 36.0548 15.5721C36.0736 15.3424 36.0548 15.1221 35.9939 14.9112ZM22.1283 12.8956L22.1142 13.6315C22.0767 15.7362 20.6236 17.1518 18.5002 17.1518C17.4642 17.1518 16.5736 16.819 15.9314 16.1862C15.2892 15.5534 14.9377 14.6721 14.9189 13.6315L14.9048 12.8956H5.25797L8.98453 3.83462H27.697L31.5267 12.8956H22.1283ZM4.55016 16.4956H11.9236C13.0627 19.1721 15.4861 20.7518 18.5048 20.7518C20.0845 20.7518 21.5517 20.3112 22.7377 19.4768C23.7783 18.7456 24.5892 17.7237 25.1142 16.4956H32.4502V32.1471H4.55016V16.4956Z"
        />
    </svg>
    ,
    "InboxIcon"
);
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Tooltip,
  Alert,
  useTheme,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CreateUpdateProjectDialog from "../modals/CreateUpdateProjectDialog";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CRUD } from "../Constants";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ConfirmDeleteModal from "../modals/ConfirmDeleteDialog";
import { getUserAvatarWithText } from "../Helper";
import RecentCreationsGrid from "../components/RecentCreationsGrid";
import { useGetProjects } from "../api/projects/getProjects";
import { useUpdateProject } from "../api/projects/updateProject";
import { useDeleteProject } from "../api/projects/deleteProject";
import Pagination from "../components/Pagination";
import TableLink from "../components/Table/Link";
import { THEME } from "../Settings";
import ErrorAlert from "../components/Snackbar/ErrorAlert";

const MyProjects = () => {
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [selectedProject, setSelectedProject] = useState({});
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [isDeleteErrorOpen, setDeleteErrorOpen] = useState(false);
  const perPage = 10;

  const { error: getProjectError, data: projects } = useGetProjects({ page, perPage });
  const { mutate: updateProject } = useUpdateProject();
  const { mutate: deleteProject } = useDeleteProject(() => setDeleteErrorOpen(true));

  const theme = useTheme();

  useEffect(() => {
    if (projects) {
      setPageCount(Math.ceil(projects.count / perPage))
    }
  }, [projects]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleClickUpdate = (project) => {
    setSelectedProject(project);
    setUpdateModalOpen(true);
  };

  const editStarProject = (project) => {
    project.starred = !project.starred;
    updateProject({ projectId: project.id, project });
  };


  const handleClickDelete = (project) => {
    setSelectedProject(project);
    setDeleteModalOpen(true);
  }

  const handleDelete = (project) => {
    deleteProject(project.id);
    setDeleteModalOpen(false);
    setSelectedProject({})
  };

  const handleCloseModal = () => {
    setUpdateModalOpen(false);
    setDeleteModalOpen(false);
  };

  const { t } = useTranslation();

  return (

    <Box>
      {THEME != "BUMASTEMRA" && (
        <>
          {/* HEADER TEXT */}
          <Typography
            variant="h5"
            sx={{
              fontWeight: "600",
              margin: "15px 0px",
            }}
          >
            {t("my projects")}
          </Typography>
          {/* Description / Helper Text */}
          <Typography variant="subtitle1" color={theme.palette.text.secondary}>
            {t("my_projects_description")}
          </Typography>
        </>
      )}

      {/* MODALS FOR CREATE AND UPDATE PROJECT */}
      <CreateUpdateProjectDialog
        open={isUpdateModalOpen}
        onClose={handleCloseModal}
        action={CRUD.Update}
        project={selectedProject}
      />
      <ConfirmDeleteModal
        open={isDeleteModalOpen}
        message={t("delete_project_message")}
        onConfirm={() => handleDelete(selectedProject)}
        onClose={() => setDeleteModalOpen(false)}
      />
      {/* Error Snackbar */}
      <ErrorAlert
        open={isDeleteErrorOpen}
        onClose={() => setDeleteErrorOpen(false)}
        message={t("delete_project_error")}
      />

      {/* HORIZONTAL RECENT PROJECTS */}
      <RecentCreationsGrid />

      {/* PROJECT TABLE */}
      {
        getProjectError ? <Alert severity="error">Failed to load projects</Alert> :
          projects?.results.length == 0 ? <Typography variant="body1" sx={{
            margin: "15px 0px",
          }}>
            {t('no_projects_created')}</Typography> :
            <TableContainer sx={{ overflowX: 'scroll', maxWidth: '100vw' }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("name")}</TableCell>
                    <TableCell>{t("uploaded by")}</TableCell>
                    <TableCell>{t("created")}</TableCell>
                    <TableCell>{t("last modified")}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projects?.results?.map((project, index) => (
                    <TableRow key={project?.id}>
                      <TableCell>
                        <TableLink href={`/projects/${project.id}`}>
                          <Box sx={{
                            display: "flex", alignItems: "center", gap: 1,
                          }}>
                            <FolderIcon />
                            {project.name}
                          </Box>
                        </TableLink>
                      </TableCell>
                      <TableCell>
                        {getUserAvatarWithText(project?.created_by)}
                      </TableCell>
                      <TableCell>
                        {moment(project.date_created).format("LLL")}
                      </TableCell>
                      <TableCell>
                        {moment(project.date_updated).format("LLL")}
                      </TableCell>
                      <TableCell>
                        {/* <Tooltip title={project.starred ? "Unstar" : "Star"}>
                        <IconButton onClick={() => editStarProject(project)}>
                          {project.starred ? (
                            <StarOutlinedIcon sx={{ color: "gold" }} />
                          ) : (
                            <StarBorderOutlinedIcon />
                          )}
                        </IconButton>
                      </Tooltip> */}
                        <Tooltip title={t('edit')}>
                          <IconButton onClick={() => handleClickUpdate(project)}>
                            <EditOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t('delete')}>
                          <IconButton onClick={() => handleClickDelete(project)}>
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
      }
      <Pagination count={pageCount} onChange={handlePageChange} />
    </Box >
  );
};

export default MyProjects;

import { Button, Modal, Typography, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ConfirmDeleteModal = ({ open, message, onConfirm, onClose }) => {
    const { t } = useTranslation();
    return (
        <Dialog open={open} onClose={onClose} fullWidth={true}>
            <DialogTitle>{t("confirm_delete")}</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>{t('no')}</Button>
                <Button variant='contained' color='error' onClick={onConfirm}>{t('yes')}</Button>
            </DialogActions>
        </Dialog>
    )
};

export default ConfirmDeleteModal;
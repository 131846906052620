import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material'
import { LineChart } from '@mui/x-charts';
import React, { useState } from 'react'
import ChartOptions from './ChartOptions';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface LineChartModalProps {
    data: any,
    timeframe: string,
    statNameLookup: any,
    cumulative: boolean,
    open: boolean,
    onClose: () => void
}

const LineChartModal = (props: LineChartModalProps) => {
    const { data, timeframe, statNameLookup, cumulative, open, onClose } = props;
    const [localCumulative, setLocalCumulative] = useState(cumulative);
    const [localTimeframe, setLocalTimeframe] = useState(timeframe);

    const handleTimeframe = (
        event: React.MouseEvent<HTMLElement>,
        newTimeframe: string | null,
    ) => {
        setLocalTimeframe(newTimeframe);
    };

    // if cumulative is set, update the 'value' field of each object in the array to be the cumulative value
    let chartData = data[localTimeframe];
    if (localCumulative) {
        chartData = data[localTimeframe].reduce((acc, curr, index) => {
            const cumulativeValue = index === 0 ? curr.value : acc[index - 1].value + curr.value;
            return [...acc, { ...curr, value: cumulativeValue }];
        }, []);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth="md"
        >
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    {statNameLookup[data["name"]]}
                    <IconButton onClick={onClose}><CloseRoundedIcon /></IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack direction="column" gap={2} alignContent="center" alignItems="center">
                    <ChartOptions timeframe={localTimeframe} cumulative={localCumulative} setTimeframe={setLocalTimeframe} setCumulative={setLocalCumulative} handleTimeframe={handleTimeframe} />
                    <LineChart
                        dataset={chartData}
                        series={[{
                            dataKey: 'value',
                            showMark: false,
                            label: 'Total'
                        }]}
                        xAxis={[{
                            id: 'timePeriod',
                            dataKey: 'time_period',
                            scaleType: 'band',
                            tickLabelStyle: {
                                angle: 45,
                                textAnchor: 'start',
                                fontSize: 12,
                            },
                        }]}
                        width={800}
                        height={400}
                        margin={{ top: 50, right: 50, bottom: 100, left: 50 }}
                        slotProps={{
                            legend: {
                                direction: 'row',
                                position: { vertical: 'bottom', horizontal: 'middle' },
                                padding: 0,
                            },
                        }}
                    />
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default LineChartModal

import api from "../base";

type DownloadCreationVersionOptions = {
    creationId: string;
    versionNum: string;
    filename: string;
};

export async function getCreationVersionTimestampCert({ creationId, versionNum, filename }: DownloadCreationVersionOptions) {
    const response = await api.get(`/creations/${creationId}/versions/${versionNum}/download-certificate/`);
    return response.data?.certificate_base64
}

import api from "../base";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export async function updateProject(projectId: string, project: any) {
    const response = await api.patch(`/projects/${projectId}/`,
        {
            name: project?.name,
            description: project?.description,
            starred: project?.starred,
        });
    return response.data;
}


export const useUpdateProject = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ projectId, project }: any) => updateProject(projectId, project),
        onSuccess: ({ projectId }) => {
            queryClient.invalidateQueries({ queryKey: ['projects'] })
            queryClient.invalidateQueries({ queryKey: ['project', projectId] })
        }
    })
};
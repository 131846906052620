import { CircularProgress } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import H5AudioPlayer from "react-h5-audio-player";

type AudioPlayerProps = {
    src: string;
    fileName: string;
    createdBy: string;
    getUpdatedFileDownloadUrl: () => any;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ src, fileName, createdBy, getUpdatedFileDownloadUrl }) => {
    const audioRef = useRef<H5AudioPlayer>(null);
    const [audioSrc, setAudioSrc] = useState(src);

    const [lastTime, setLastTime] = useState(0);
    const [stallTimeout, setStallTimeout] = useState<NodeJS.Timeout | null>(null);


    const handleError = async () => {
        const audioEl: HTMLAudioElement = audioRef.current.audio.current;
        const isPlaying = !audioEl.paused;
        const currentTime = audioEl.currentTime;
        const newUrl = await getUpdatedFileDownloadUrl();
        setAudioSrc(newUrl);
        audioEl.src = newUrl;
        audioEl.currentTime = currentTime;
        audioEl.load();

        if (isPlaying) {
            audioEl.addEventListener('canplaythrough', () => {
                audioEl.play();
            }, { once: true });
        }
    };

    const checkStall = () => {
        const audioEl: HTMLAudioElement = audioRef.current.audio.current;
        if (audioEl.currentTime === lastTime && !audioEl.paused) {
            handleError();
        } else {
            setLastTime(audioEl.currentTime);
        }
    };

    useEffect(() => {
        const audioEl: HTMLAudioElement = audioRef.current.audio.current;
        const handleTimeUpdate = () => {
            if (stallTimeout) {
                clearTimeout(stallTimeout);
            }
            setStallTimeout(setTimeout(checkStall, 3000)); // Check for stall every 3 seconds
        };

        audioEl.addEventListener('timeupdate', handleTimeUpdate);

        return () => {
            audioEl.removeEventListener('timeupdate', handleTimeUpdate);
            if (stallTimeout) {
                clearTimeout(stallTimeout);
            }
        };
    }, [lastTime, stallTimeout]);


    return (
        <H5AudioPlayer
            ref={audioRef}
            src={audioSrc}
            autoPlay={false}
            autoPlayAfterSrcChange={false}
            customVolumeControls={[]}
            customAdditionalControls={[
                <h5
                    style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        display: "block",
                        margin: "0",
                        textAlign: "left",
                    }}
                >
                    {fileName}
                </h5>,
                <p style={{ color: "rgba(92, 92, 111, 1)", margin: "10px 0px" }}>
                    {createdBy}
                </p>,
            ]}
            showJumpControls={true}
            onStalled={handleError}
        />
    )
}

export default AudioPlayer

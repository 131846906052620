import { useCallback, useEffect, useRef, useState } from "react";

export const UPLOAD_TYPES = {
  Recording: "Recording",
  Lyrics: "Lyric",
  SheetMusic: "Sheet Music",
  Video: "Video",
  Image: "Picture",
  Agreement: "Agreement",
  Other: "Other",
};

export const CRUD = {
  Read: "read",
  Create: "create",
  Update: "update",
  Delete: "delete",
};

export function getFileExtension(filename) {
  if (filename.indexOf(".") === -1) return ""; // or return a default value
  return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
}

export function getUploadTypes(filename) {
  switch (getFileExtension(filename).toLowerCase()) {
    case "mp3":
    case "wav":
    case "ogg":
    case "flac":
    case "m4a":
    case "aac":
      return [UPLOAD_TYPES.Recording, UPLOAD_TYPES.Other];
    case "png":
    case "jpg":
    case "jpeg":
      return [
        UPLOAD_TYPES.Image,
        UPLOAD_TYPES.Lyrics,
        UPLOAD_TYPES.SheetMusic,
        UPLOAD_TYPES.Other,
      ];
    case "mp4":
    case "mov":
      return [UPLOAD_TYPES.Video];
    case "txt":
    case "pdf":
    case "docx":
    case "doc":
      return [UPLOAD_TYPES.Lyrics, UPLOAD_TYPES.Agreement, UPLOAD_TYPES.Other];

    default:
      return [UPLOAD_TYPES.Other, UPLOAD_TYPES.Recording, UPLOAD_TYPES.Lyrics, UPLOAD_TYPES.Video, UPLOAD_TYPES.Image, UPLOAD_TYPES.Agreement, UPLOAD_TYPES.SheetMusic];
  }
}

export function useStateCallback(initialState) {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null); // init mutable ref container for callbacks

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb; // store current, passed callback in ref
    setState(state);
  }, []); // keep object reference stable, exactly like `useState`

  useEffect(() => {
    // cb.current is `null` on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
}

export const LANGUAGES = [
  { label: "English", code: "en", flag: "gb" },
  // french
  { label: "Français", code: "fr", flag: "fr" },
  // dutch
  { label: "Nederlands", code: "nl", flag: "nl" },
  // finnish
  { label: "Suomi", code: "fi", flag: "fi" },
  // swedish
  { label: "Svenska", code: "sv", flag: "se" },
  // norwegian
  { label: "Norsk", code: "nb", flag: "no" },
  // german
  { label: "Deutsch", code: "de", flag: "de" },
  // spanish
  { label: "Español", code: "es", flag: "es" },
  // italian
  { label: "Italiano", code: "it", flag: "it" },
  // korean
  { label: "한국어", code: "ko", flag: "kr" },
  // japanese
  { label: "日本語", code: "ja", flag: "jp" },
  // chinese
  { label: "中文", code: "zh", flag: "cn" },
  // arabic
  { label: "العربية", code: "ar", flag: "sa" },
];

const VISITOR_ROLE = "visitor";
const USER_ROLE = "user";
const ADMIN_ROLE = "admin";
const MMS_ADMIN_ROLE = "mms.admin";

export function isVisitor(token) {
    if (!token) {
        return false;
    }
    const roles = token?.access_token_parsed?.drx_data?.roles;
    return roles?.includes(VISITOR_ROLE) ?? false;
}

export function isUser(token) {
    if (!token) {
        return false;
    }
    const roles = token?.access_token_parsed?.drx_data?.roles;
    return roles?.includes(USER_ROLE) ?? false;
}

export function isAdmin(token) {
    if (!token) {
        return false;
    }
    const roles = token?.access_token_parsed?.drx_data?.roles;
    return (roles?.includes(ADMIN_ROLE) || roles?.includes(MMS_ADMIN_ROLE)) ?? false;
}
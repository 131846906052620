// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import 'moment/locale/fr';
import 'moment/locale/nl';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/sv';
import 'moment/locale/nb';
import 'moment/locale/fi';
import 'moment/locale/da';
import 'moment/locale/ko';
import 'moment/locale/ja';
import 'moment/locale/zh-cn';
// import 'moment/locale/ar';

import moment from "moment";
import { THEME } from './Settings'
import { themes } from "./theme/themes";

const resources = {
  en: {
    translation: {
      "admin": "Admin",
      "dashboard": "Dashboard",
      "create project": "Create Project",
      "upload file": "Upload File",
      "upload date": "Upload Date",
      "my projects": "My Projects",
      "projects": "Projects",
      "starred": "Starred",
      "shared": "Shared",
      "share": "Share",
      "expires": "Expires",
      "expired": "Expired",
      "expired on": "Expired on",
      "expires in": "Expires in",
      "expires on": "Expires on",
      "never expires": "Never expires",
      "revoked": "Revoked",
      "view": "View",
      "viewed": "Viewed",
      "view_details": "View Details",
      "view_count": "Total Views",
      "settings": "Settings",
      "recent creations": "Recent Creations",
      "recent projects": "Recent Projects",
      "project": "Project",
      "creation": "Creation",
      "creations": "Creations",
      "version": "Version",
      "versions": "Versions",
      "versions uploaded": "Versions Uploaded",
      "timestamps": "Timestamps",
      "timestamp": "Timestamp",
      "file": "File",
      "files": "Files",
      "storage": "Storage",
      "users": "Users",
      "shared_link": "Shared Link",
      "shared links": "Shared Links",
      "cumulative": "Cumulative",
      "Click or drag file": "Click or drag to upload a file.",
      "file formats": "Supported file types: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov (Max 500MB)",
      "search": "Search",
      "new version": "New Version",
      "version history": "Version History",
      "shared_with": "Shared With",
      "shared_links": "Shared Links",
      "info": "Info",
      "profile": "Profile",
      "logout": "Logout",
      "version not shared": "This version has not been shared yet",
      "file hash": "File Hash",
      "confirm_delete": "Confirm Delete",
      "description": "Description",
      "no_description_available": "No description available",
      "created": "Created",
      "date_created": "Date Created",
      "date_shared": "Date Shared",
      "notes": "Notes",
      "actions": "Actions",
      "download_certificate": "Download Certificate",
      "download_file": "Download File",
      "downloading_certificate": "Downloading Certificate",
      "downloading_file": "Downloading File",
      "resend_confirmation_email": "Resend Confirmation Email",
      "active_links": "Active Links",
      "revoked_expired_links": "Revoked & Expired Links",
      "no_projects_created": "You have not created any projects yet, click \"Create Project\" in the sidebar to get started.",
      "unexpected_error": "An unexpected error occurred. Please try again later.",
      "timestamp_confirmation_email_sent": "Timestamp confirmation email sent",
      "show_all_versions": "Show all versions",
      "type_to_search": "Type to search...",
      "go_back_to": "Go back to",
      "select_profile": "Choose a different profile",
      "app_description": "",
      "creation_version_view_description": "You can upload new versions of your work, view the version history, or share your work (again) with others. The timestamp certificate and your file can be downloaded via 'actions'",
      "my_projects_description": "This page gives an overview of all your projects. A project folder stores the different files that contribute to a musical work, such as your audio files and song texts. This can be per song, or for multiple songs.",
      "project_detail_description": "Each time you upload a file, you'll receive a confirmation email with a (timestamp) certificate. Click on any file name in the list to share the file with others or to view further details of the timestamp.",
      "create": "Create",
      "save": "Save",
      "cancel": "Cancel",
      "no": "No",
      "yes": "Yes",
      "edit": "Edit",
      "delete": "Delete",
      "close": "Close",
      "vault storage": "Vault Storage",
      "usedData": "You have used <strong>{{dataMb}}</strong>",
      "usedDataWithLimit": "You have used <strong>{{dataMb}} ({{dataPercent}}%)</strong> of your available space",
      "name": "Name",
      "uploaded by": "Uploaded By",
      "first upload date": "First Upload Date",
      "last modified": "Last Modified",
      "last updated": "Last Updated",
      "filename": "Filename",
      "file type": "File Type",
      "file size": "File Size",
      "file_processing_error": "There was an error when processing this file. Please contact support",
      "file_currently_processing": "This file is currently being processed. Please check back later for Timestamp information",
      "delete_project_message": "Are you sure you want to permanently delete this project? All works and their versions in this project will be permanently deleted.",
      "delete_creation_message": "Are you sure you want to permanently delete this work? All versions of this work will be permanently deleted.",
      "delete_creation_version_message": "Are you sure you want to permanently delete this version of your work?",
      "delete_sharing_link_message": "Are you sure you want to delete this sharing link?",
      "delete_project_error": "There was an error when deleting this project. Please try again later",
      "delete_creation_version_error": "There was an error when deleting this version of your work. Please try again later",
      "project_form": {
        "title_create": "Create Project",
        "title_edit": "Update Project",
        "description": "Set up a project folder to store your files",
        "fields": {
          "project_title_label": "Project Title",
          "project_title_helpertext": "The title of the project",
          "project_description_label": "Project Description",
          "project_description_helpertext": "Description of your project (optional)",
        }
      },
      "upload_creation_form": {
        "title": "Upload Creation",
        "upload_error_already_exists": "You have already uploaded this file before. Please select a different file.",
        "upload_error_file_too_large": "The file you are trying to upload is too large. Please upload a file smaller than 500MB.",
        "upload_error_file_type_not_allowed": "The file type you are trying to upload is not supported. Please upload a file with one of the following types: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov",
        "select_project_description": "Select the project you would like to upload this creation under. If you do not have a project yet, you can create a new one. If this is a new version of an existing creation, you can also select the creation to upload the new version under.",
        "select_project_helpertext": {
          "default": "Select a Project to upload this file under.",
          "project_selected_no_creations": "This file will be uploaded as a new creation under the project",
          "project_selected": "Select a Creation below to upload as a new version.",
          "creation_selected": "This file will be uploaded as a new version under the creation"
        },
        "headers": {
          "uploaded_file_details": "Uploaded File Details",
          "creation_details": "Creation Details",
          "select_project": "Select Project"
        },
        "fields": {
          "creation_name_label": "Creation Name",
          "creation_name_helpertext": "The title of the newly uploaded creation.",
          "creation_type_label": "Creation Type",
          "creation_type_helpertext": "The type of creation being uploaded",
          "creation_description_label": "Description",
          "creation_description_helpertext": "A brief description about this creation. If you are uploading a new version, you may want to describe the changes made.",
        },
        "buttons": {
          "create_new_project": "Create New Project",
          "upload": "Upload"
        }
      },
      "share_creation_form": {
        "title": "Share Creation Version: {{ creation_name }}",
        "description": "Share this version of your work with someone else. Through a unique link in the email, your work can be viewed or listened to for the period you specify below.",
        "fields": {
          "email_label": "Email Address",
          "email_helpertext": "Enter the email address of the person you want to share with",
          "duration_label": "Duration",
          "duration_helpertext": " Specify how long the unique link is valid for",
          "notes_label": "Notes",
          "notes_helpertext": "Add a note for the recipient",
        },
      },
      "shared_links_search_form": {
        "placeholder": "Search by project, creation or recipient email"
      },
      "duration_units": {
        "minutes": "minutes",
        "hour": "hour",
        "hours": "hours",
        "day": "day",
        "days": "days",
        "week": "week",
        "weeks": "weeks",
        "month": "month",
        "months": "months",
        "year": "year",
        "years": "years",
        "all": "all time",
      },
      "Recording": "Recording",
      "Lyric": "Lyric",
      "Sheet Music": "Sheet Music",
      "Video": "Video",
      "Picture": "Picture",
      "Agreement": "Agreement",
      "Other": "Other",
      "shared_by": "Shared by {{ name }} on {{ date }}",
      "timestamped_by": "Timestamped by {{ name }}",
      "link_expires_on": "This link expires on {{ date }}",
      "link_expired": "This link has expired or the file permissions have been modified.",
      "external_share": {
        "otp_code_requested_check_email": "One-time access code requested, please check your email",
        "otp_code_invalid": "The one-time access code you entered is invalid",
        "otp_code_request_error": "An error occurred while requesting the one-time access code",
        "access_protected_content": "Access to secure content",
        "content_exclusive_for": "This content is exclusively available for",
        "enter_otp": "Enter your one-time access code",
        "enter_otp_caption": "Please enter the 6-digit alphanumeric code we sent to your email address",
        "otp_dont_have_code": "Didn't receive a code?",
        "request_otp": "Request access code",
        "resend_otp": "Resend code",
        "otp_proceed_request_otp": "To proceed to the content, you can request a one-time access code using the button below.",
        "already_member_login": "Do you already have an account with this email address?",
        "login_to_access": "Log in to gain access.",
        "member_login": "Login",
        "or": "or",
      },
      "about": {
        "BUMASTEMRA": `In MyMusicSafe, you can store your music or lyrics in a secure environment and share them with others. You determine how long others have access to your file(s). They can only view or listen to the files within the secure environment.
Both concepts and final versions of works/files can be stored in organized project folders. Each time you upload a file, you will receive a confirmation with a timestamp indicating that you registered this work at a specific moment.
This confirmation will be sent via email and is available for download as a PDF with all relevant metadata.
Is your work ready to be submitted to BumaStemra? Then go to the 'Works' page and select 'Submit New Work' to register your work.`
      },
    },
  },
  fr: {
    "translation": {
      "admin": "Admin",
      "dashboard": "Tableau de bord",
      "create project": "Créer un projet",
      "upload file": "Télécharger un fichier",
      "upload date": "Date de téléchargement",
      "my projects": "Mes projets",
      "projects": "Projets",
      "starred": "Favoris",
      "shared": "Partagé",
      "share": "Partager",
      "expires": "Expire",
      "expired": "Expiré",
      "expired on": "Expiré le",
      "expires in": "Expire dans",
      "expires on": "Expire le",
      "never expires": "N'expire jamais",
      "revoked": "Révoqué",
      "view": "Voir",
      "viewed": "Vu",
      "view_details": "Voir les détails",
      "view_count": "Nombre total de vues",
      "settings": "Paramètres",
      "recent creations": "Créations récentes",
      "recent projects": "Projets récents",
      "project": "Projet",
      "creation": "Création",
      "creations": "Créations",
      "version": "Version",
      "versions": "Versions",
      "versions uploaded": "Versions téléchargées",
      "timestamps": "Horodatages",
      "timestamp": "Horodatage",
      "file": "Fichier",
      "files": "Fichiers",
      "storage": "Stockage",
      "users": "Utilisateurs",
      "shared_link": "Lien partagé",
      "shared links": "Liens partagés",
      "cumulative": "Cumulatif",
      "Click or drag file": "Cliquez ou glissez pour télécharger un fichier.",
      "file formats": "Types de fichiers pris en charge : .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov (500 Mo max)",
      "search": "Rechercher",
      "new version": "Nouvelle version",
      "version history": "Historique des versions",
      "shared_with": "Partagé avec",
      "shared_links": "Liens partagés",
      "info": "Info",
      "profile": "Profil",
      "logout": "Déconnexion",
      "version not shared": "Cette version n'a pas encore été partagée",
      "file hash": "Empreinte du fichier",
      "confirm_delete": "Confirmer la suppression",
      "description": "Description",
      "no_description_available": "Aucune description disponible",
      "created": "Créé",
      "date_created": "Date de création",
      "date_shared": "Date de partage",
      "notes": "Notes",
      "actions": "Actions",
      "download_certificate": "Télécharger le certificat",
      "download_file": "Télécharger le fichier",
      "downloading_certificate": "Téléchargement du certificat",
      "downloading_file": "Téléchargement du fichier",
      "resend_confirmation_email": "Renvoyer l'email de confirmation",
      "active_links": "Liens actifs",
      "revoked_expired_links": "Liens révoqués & expirés",
      "no_projects_created": "Vous n'avez pas encore créé de projets, cliquez sur \"Créer un projet\" dans la barre latérale pour commencer.",
      "unexpected_error": "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
      "timestamp_confirmation_email_sent": "Email de confirmation d'horodatage envoyé",
      "show_all_versions": "Afficher toutes les versions",
      "type_to_search": "Tapez pour rechercher...",
      "go_back_to": "Retourner à",
      "select_profile": "Choisir un autre profil",
      "app_description": "",
      "creation_version_view_description": "Vous pouvez télécharger de nouvelles versions de votre travail, consulter l'historique des versions ou partager à nouveau votre travail avec d'autres. Le certificat d'horodatage et votre fichier peuvent être téléchargés via les 'actions'",
      "my_projects_description": "Cette page donne un aperçu de tous vos projets. Un dossier de projet stocke les différents fichiers qui contribuent à une œuvre musicale, tels que vos fichiers audio et les textes de chansons. Cela peut être par chanson, ou pour plusieurs chansons.",
      "project_detail_description": "Chaque fois que vous téléchargez un fichier, vous recevez un email de confirmation avec un certificat (horodatage). Cliquez sur n'importe quel nom de fichier dans la liste pour partager le fichier avec d'autres ou pour voir plus de détails sur l'horodatage.",
      "create": "Créer",
      "save": "Sauvegarder",
      "cancel": "Annuler",
      "no": "Non",
      "yes": "Oui",
      "edit": "Modifier",
      "delete": "Supprimer",
      "close": "Fermer",
      "vault storage": "Stockage sécurisé",
      "usedData": "Vous avez utilisé <strong>{{dataMb}}</strong>",
      "usedDataWithLimit": "Vous avez utilisé <strong>{{dataMb}} ({{dataPercent}}%)</strong> de votre espace disponible",
      "name": "Nom",
      "uploaded by": "Téléchargé par",
      "first upload date": "Date du premier téléchargement",
      "last modified": "Dernière modification",
      "last updated": "Dernière mise à jour",
      "filename": "Nom du fichier",
      "file type": "Type de fichier",
      "file size": "Taille du fichier",
      "file_processing_error": "Une erreur s'est produite lors du traitement de ce fichier. Veuillez contacter le support",
      "file_currently_processing": "Ce fichier est actuellement en cours de traitement. Veuillez revenir plus tard pour les informations sur l'horodatage",
      "delete_project_message": "Êtes-vous sûr de vouloir supprimer définitivement ce projet ? Toutes les œuvres et leurs versions dans ce projet seront définitivement supprimées.",
      "delete_creation_message": "Êtes-vous sûr de vouloir supprimer définitivement cette œuvre ? Toutes les versions de cette œuvre seront définitivement supprimées.",
      "delete_creation_version_message": "Êtes-vous sûr de vouloir supprimer définitivement cette version de votre œuvre ?",
      "delete_sharing_link_message": "Êtes-vous sûr de vouloir supprimer ce lien de partage ?",
      "delete_project_error": "Une erreur s'est produite lors de la suppression de ce projet. Veuillez réessayer plus tard",
      "delete_creation_version_error": "Une erreur s'est produite lors de la suppression de cette version de votre œuvre. Veuillez réessayer plus tard",
      "project_form": {
        "title_create": "Créer un projet",
        "title_edit": "Mettre à jour le projet",
        "description": "Configurer un dossier de projet pour stocker vos fichiers",
        "fields": {
          "project_title_label": "Titre du projet",
          "project_title_helpertext": "Le titre du projet",
          "project_description_label": "Description du projet",
          "project_description_helpertext": "Description de votre projet (facultatif)"
        }
      },
      "upload_creation_form": {
        "title": "Télécharger une création",
        "upload_error_already_exists": "Vous avez déjà téléchargé ce fichier auparavant. Veuillez sélectionner un autre fichier.",
        "upload_error_file_too_large": "Le fichier que vous essayez de télécharger est trop volumineux. Veuillez télécharger un fichier inférieur à 500 Mo.",
        "upload_error_file_type_not_allowed": "Le type de fichier que vous essayez de télécharger n'est pas pris en charge. Veuillez télécharger un fichier avec l'un des types suivants : .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov",
        "select_project_description": "Sélectionnez le projet sous lequel vous souhaitez télécharger cette création. Si vous n'avez pas encore de projet, vous pouvez en créer un nouveau. Si c'est une nouvelle version d'une création existante, vous pouvez également sélectionner la création pour télécharger la nouvelle version.",
        "select_project_helpertext": {
          "default": "Sélectionnez un projet sous lequel télécharger ce fichier.",
          "project_selected_no_creations": "Ce fichier sera téléchargé en tant que nouvelle création sous le projet",
          "project_selected": "Sélectionnez une création ci-dessous pour télécharger une nouvelle version.",
          "creation_selected": "Ce fichier sera téléchargé en tant que nouvelle version sous la création"
        },
        "headers": {
          "uploaded_file_details": "Détails du fichier téléchargé",
          "creation_details": "Détails de la création",
          "select_project": "Sélectionner le projet"
        },
        "fields": {
          "creation_name_label": "Nom de la création",
          "creation_name_helpertext": "Le titre de la création nouvellement téléchargée.",
          "creation_type_label": "Type de création",
          "creation_type_helpertext": "Le type de création téléchargée",
          "creation_description_label": "Description",
          "creation_description_helpertext": "Une brève description de cette création. Si vous téléchargez une nouvelle version, vous pouvez décrire les modifications apportées."
        },
        "buttons": {
          "create_new_project": "Créer un nouveau projet",
          "upload": "Télécharger"
        }
      },
      "share_creation_form": {
        "title": "Partager la version de la création : {{ creation_name }}",
        "description": "Partagez cette version de votre travail avec quelqu'un d'autre. Grâce à un lien unique dans l'email, votre travail peut être consulté ou écouté pendant la période que vous spécifiez ci-dessous.",
        "fields": {
          "email_label": "Adresse email",
          "email_helpertext": "Entrez l'adresse email de la personne avec qui vous voulez partager",
          "duration_label": "Durée",
          "duration_helpertext": "Spécifiez la durée de validité du lien unique",
          "notes_label": "Notes",
          "notes_helpertext": "Ajoutez une note pour le destinataire"
        }
      },
      "shared_links_search_form": {
        "placeholder": "Rechercher par projet, création ou email du destinataire"
      },
      "duration_units": {
        "minutes": "minutes",
        "hour": "heure",
        "hours": "heures",
        "day": "jour",
        "days": "jours",
        "week": "semaine",
        "weeks": "semaines",
        "month": "mois",
        "months": "mois",
        "year": "année",
        "years": "années",
        "all": "tout le temps"
      },
      "Recording": "Enregistrement",
      "Lyric": "Paroles",
      "Sheet Music": "Partition",
      "Video": "Vidéo",
      "Picture": "Image",
      "Agreement": "Accord",
      "Other": "Autre",
      "shared_by": "Partagé par {{ name }} le {{ date }}",
      "timestamped_by": "Horodaté par {{ name }}",
      "link_expires_on": "Ce lien expire le {{ date }}",
      "link_expired": "Ce lien a expiré ou les permissions du fichier ont été modifiées.",
      "external_share": {
        "otp_code_requested_check_email": "Code d'accès unique demandé, veuillez vérifier votre email",
        "otp_code_invalid": "Le code d'accès unique que vous avez saisi est invalide",
        "otp_code_request_error": "Une erreur s'est produite lors de la demande du code d'accès unique",
        "access_protected_content": "Accès au contenu sécurisé",
        "content_exclusive_for": "Ce contenu est exclusivement disponible pour",
        "enter_otp": "Entrez votre code d'accès unique",
        "enter_otp_caption": "Veuillez entrer le code alphanumérique à 6 chiffres que nous avons envoyé à votre adresse email",
        "otp_dont_have_code": "Vous n'avez pas reçu de code ?",
        "request_otp": "Demander un code d'accès",
        "resend_otp": "Renvoyer le code",
        "otp_proceed_request_otp": "Pour accéder au contenu, vous pouvez demander un code d'accès unique en utilisant le bouton ci-dessous.",
        "already_member_login": "Avez-vous déjà un compte avec cette adresse e-mail ?",
        "login_to_access": "Connectez-vous pour accéder.",
        "member_login": "Connexion",
        "or": "ou"
      }
    }
  },
  nl: {
    translation: {
      "admin": "Admin",
      "dashboard": "Dashboard",
      "create project": "Project aanmaken",
      "upload file": "Bestand uploaden",
      "upload date": "Upload datum",
      "my projects": "Mijn projecten",
      "projects": "Projecten",
      "starred": "Favorieten",
      "shared": "Gedeeld",
      "share": "Delen",
      "view": "Bekijk",
      "viewed": "Bekeken",
      "view_details": "Bekijk details",
      "view_count": "Totaal bekeken",
      "expires": "Verloopt",
      "expired": "Verlopen",
      "expired on": "Verlopen op",
      "expires in": "Verloopt in",
      "expires on": "Verloopt op",
      "never expires": "Verloopt nooit",
      "revoked": "Ingetrokken",
      "settings": "Instellingen",
      "recent creations": "Recente creaties",
      "recent projects": "Recente projecten",
      "project": "Project",
      "creation": "Creatie",
      "creations": "Creaties",
      "version": "Versie",
      "versions": "Versies",
      "versions uploaded": "Versies geüpload",
      "timestamps": "Datumstempels",
      "timestamp": "Datumstempel",
      "file": "Bestand",
      "files": "Bestanden",
      "storage": "Opslag",
      "users": "Gebruikers",
      "shared links": "Gedeelde links",
      "cumulative": "Cumulatief",
      "Click or drag file": "Sleep hier je bestand naartoe of klik om te uploaden.",
      "file formats": "Ondersteunde bestandstypen: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov (Maximaal 500MB)",
      "search": "Zoeken",
      "new version": "Nieuwe versie",
      "version history": "Versiegeschiedenis",
      "shared_with": "Gedeeld met",
      "shared_link": "Gedeelde link",
      "shared_links": "Gedeelde links",
      "info": "Info",
      "profile": "Profiel",
      "logout": "Uitloggen",
      "version not shared": "Deze versie is nog niet gedeeld",
      "file hash": "Bestand hash",
      "confirm_delete": "Bevestig verwijderen",
      "description": "Beschrijving",
      "no_description_available": "Geen beschrijving beschikbaar",
      "created": "Aangemaakt",
      "date_created": "Datum aangemaakt",
      "date_shared": "Datum gedeeld",
      "notes": "Notities",
      "actions": "Acties",
      "download_certificate": "Download certificaat",
      "download_file": "Download bestand",
      "downloading_certificate": "Certificaat downloaden",
      "downloading_file": "Bestand downloaden",
      "resend_confirmation_email": "Bevestigingsmail opnieuw versturen",
      "active_links": "Actieve links",
      "revoked_expired_links": "Ingetrokken & verlopen links",
      "no_projects_created": "Je hebt nog geen projecten aangemaakt, klik op \"Project aanmaken\" in de zijbalk om te beginnen.",
      "unexpected_error": "Er is een onverwachte fout opgetreden. Probeer het later opnieuw.",
      "timestamp_confirmation_email_sent": "Timestamp bevestigingsmail verstuurd",
      "show_all_versions": "Toon alle versies",
      "type_to_search": "Typ om te zoeken...",
      "go_back_to": "Ga terug naar",
      // Select Profile
      "select_profile": "Kies hier een ander profiel",
      // Page Descriptions
      "app_description": "",
      "creation_version_view_description": "Je kunt nieuwe versies van je werk uploaden, de versiegeschiedenis bekijken of je werk (nogmaals) delen met anderen. Het (datumstempel)certificaat en je bestand zijn opnieuw te downloaden via 'acties'.",
      "my_projects_description": "Deze pagina geeft een overzicht van al je projecten. In een projectmap sla je de verschillende bestanden op die te maken hebben met een muziekwerk, zoals je audiobestanden en liedteksten. Dit kan per liedje zijn, of voor meerdere liedjes.",
      "project_detail_description": "Elke keer dat je een bestand uploadt, ontvang je een bevestigingsmail met het (datumstempel)certificaat. Klik op een bestandsnaam in de lijst om het bestand te delen met anderen, of om verdere details van de datumstempel te bekijken.",
      // Buttons
      "create": "Aanmaken",
      "save": "Opslaan",
      "cancel": "Annuleren",
      "no": "Nee",
      "yes": "Ja",
      "edit": "Bewerken",
      "delete": "Verwijderen",
      "close": "Sluiten",
      // Vault Storage Sidebar
      "vault storage": "Kluis opslag",
      "usedData": "Je hebt <strong>{{dataMb}}</strong> gebruikt",
      "usedDataWithLimit": "Je hebt <strong>{{dataMb}} ({{dataPercent}}%)</strong> van je beschikbare ruimte gebruikt",
      // Table headers
      "name": "Naam",
      "uploaded by": "Geupload door",
      "first upload date": "Datum eerste upload",
      "last modified": "Laatst gewijzigd",
      "last updated": "Laatst bijgewerkt",
      "filename": "Bestandsnaam",
      "file type": "Bestandstype",
      "file size": "Bestandsgrootte",
      //File Processing
      "file_processing_error": "Er is een fout opgetreden bij het verwerken van dit bestand. Neem contact op met de ondersteuning",
      "file_currently_processing": "Dit bestand wordt momenteel verwerkt. Kom later terug voor datumstempelinformatie",
      // Delete Messages
      "delete_project_message": "Weet je zeker dat je dit project definitief wil verwijderen? Alle werken en hun versies in dit project zullen definitief worden verwijderd.",
      "delete_creation_message": "Weet je zeker dat je dit werk definitief wil verwijderen? Alle versies van dit werk worden definitief verwijderd.",
      "delete_creation_version_message": "Weet je zeker dat je deze versie van je werk definitief wil verwijderen?",
      "delete_sharing_link_message": "Weet je zeker dat je deze deellink wil verwijderen?",
      // Delete Error Messages
      "delete_project_error": "Er is een fout opgetreden bij het verwijderen van dit project. Probeer het later opnieuw",
      "delete_creation_version_error": "Er is een fout opgetreden bij het verwijderen van deze versie van je werk. Probeer het later opnieuw",
      // Project Create Form
      "project_form": {
        "title_create": "Project aanmaken",
        "title_edit": "Project bijwerken",
        "description": "Creëer een projectmap voor het opslaan van je bestanden",
        "fields": {
          "project_title_label": "Project titel",
          "project_title_helpertext": "Geef je project een titel",
          "project_description_label": "Toelichting project",
          "project_description_helpertext": "Omschrijving van je project (optioneel)",
        }
      },
      // File/Creation Upload Form
      "upload_creation_form": {
        "title": "Bestand uploaden",
        "upload_error_already_exists": "Je hebt dit bestand al eerder geüpload. Selecteer een ander bestand.",
        "upload_error_file_too_large": "Het bestand dat je probeert te uploaden is te groot. Upload een bestand kleiner dan 500MB.",
        "upload_error_file_type_not_allowed": "Het bestandstype dat je probeert te uploaden wordt niet ondersteund. Upload een bestand met een van de volgende typen: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov",
        "select_project_description": "Selecteer het project waaronder je deze creatie wilt uploaden. Als je nog geen project hebt, kun je een nieuw project aanmaken. Als dit een nieuwe versie is van een bestaande creatie, kun je ook de creatie selecteren om de nieuwe versie onder te uploaden.",
        "select_project_helpertext": {
          "default": "Selecteer een Project om dit bestand onder te uploaden.",
          "project_selected_no_creations": "Dit bestand wordt geüpload als een nieuwe creatie onder het project",
          "project_selected": "Selecteer hieronder een Creatie om als een nieuwe versie te uploaden.",
          "creation_selected": "Dit bestand wordt geüpload als een nieuwe versie onder de creatie"
        },
        "headers": {
          "uploaded_file_details": "Details bestand",
          "creation_details": "Creatie details",
          "select_project": "Selecteer project"
        },
        "fields": {
          "creation_name_label": "Titel",
          "creation_name_helpertext": "Kies een titel of pas deze aan",
          "creation_type_label": "Type",
          "creation_type_helpertext": "Het type bestand dat wordt geupload",
          "creation_description_label": "Toelichting",
          "creation_description_helpertext": "Omschrijving van je werk of nieuwe versie (optioneel)",
        },
        "buttons": {
          "create_new_project": "Nieuw project aanmaken",
          "upload": "Uploaden"
        }
      },
      // Share Creation Form
      "share_creation_form": {
        "title": "Deel je werk: {{ creation_name }}",
        "description": "Deel deze versie van je werk met iemand anders. Door middel van een unieke link in de e-mail kan je werk worden bekeken of beluisterd gedurende de periode die je hieronder aangeeft.",
        "fields": {
          "email_label": "e-mailadres",
          "email_helpertext": "Voer het e-mailadres in van de persoon met wie je wilt delen",
          "duration_label": "Periode",
          "duration_helpertext": "Bepaal hoe lang de unieke link geldig is",
          "notes_label": "Notities",
          "notes_helpertext": "Voeg een notitie toe voor de ontvanger",
        },
      },
      // Shared Links search form
      "shared_links_search_form": {
        "placeholder": "Zoeken op project, creatie of ontvanger e-mail"
      },
      "duration_units": {
        "minutes": "minuten",
        "hour": "uur",
        "hours": "uren",
        "day": "dag",
        "days": "dagen",
        "week": "week",
        "weeks": "weken",
        "month": "maand",
        "months": "maanden",
        "year": "jaar",
        "years": "jaren",
        "all": "altijd",
      },
      // Creation Types
      "Recording": "Opname",
      "Lyric": "Tekst",
      "Sheet Music": "Bladmuziek",
      "Video": "Video",
      "Picture": "Afbeelding",
      "Agreement": "Overeenkomst",
      "Other": "Anders",
      // External Sharing
      "shared_by": "Gedeeld door {{ name }} op {{ date }}",
      "timestamped_by": "Datumstempel door {{ name }}",
      "link_expires_on": "Deze link verloopt op {{ date }}",
      "link_expired": "Deze link is verlopen of de bestandsrechten zijn gewijzigd.",
      "external_share": {
        "otp_code_requested_check_email": "Toegangscode aangevraagd, controleer uw e-mail",
        "otp_code_invalid": "De toegangscode die u heeft ingevoerd is ongeldig",
        "otp_code_request_error": "Er is een fout opgetreden bij het aanvragen van de toegangscode",
        "access_protected_content": "Toegang tot beveiligde content",
        "content_exclusive_for": "Deze inhoud is exclusief beschikbaar voor",
        "enter_otp": "Voer je eenmalige toegangscode in",
        "enter_otp_caption": "Voer de 6-cijferige alfanumerieke code in die we naar je e-mailadres hebben gestuurd.",
        "otp_dont_have_code": "Geen code ontvangen?",
        "request_otp": "Vraag toegangscode aan",
        "resend_otp": "Vraag code opnieuw aan",
        "otp_proceed_request_otp": "Om door te gaan naar de inhoud, kun je een eenmalige toegangscode aanvragen via de knop hieronder.",
        "already_member_login": "Heb je al een account met dit e-mailadres?",
        "login_to_access": "Log dan in om meteen toegang te krijgen.",
        "member_login": "Inloggen",
        "or": "of",
      },
      // ABOUT PAGE
      "about": {
        "BUMASTEMRA": `In MyMusicSafe kan je jouw muziek of teksten in een beveiligde omgeving opslaan en delen met anderen. Jij bepaalt hoe lang anderen toegang hebben tot je bestand(en). Zij kunnen de bestanden alleen bekijken of beluisteren binnen de beveiligde omgeving.
    Zowel concepten als definitieve versies van werken/bestanden kunnen worden opgeslagen in overzichtelijke projectmappen. Elke keer dat je een bestand uploadt, ontvang je een bevestiging met een datumstempel die aangeeft dat jij dit werk op een specifiek moment hebt geregistreerd.
    Deze bevestiging wordt per e-mail verzonden en is beschikbaar om te downloaden als PDF met alle relevante metadata. 
    Is je werk klaar en wil je dit aanmelden? Ga dan naar de 'Werken'-pagina en kies voor 'Nieuw werk aanmelden' om je werk aan te melden.`
      },
    }
  },
  fi: {
    "translation": {
      "admin": "Ylläpitäjä",
      "dashboard": "Kojelauta",
      "create project": "Luo projekti",
      "upload file": "Lataa tiedosto",
      "upload date": "Latauspäivä",
      "my projects": "Omat projektit",
      "projects": "Projektit",
      "starred": "Tähdellä merkityt",
      "shared": "Jaetut",
      "share": "Jaa",
      "expires": "Vanhenee",
      "expired": "Vanhentunut",
      "expired on": "Vanhentunut",
      "expires in": "Vanhenee",
      "expires on": "Vanhenee",
      "never expires": "Ei vanhene koskaan",
      "revoked": "Peruutettu",
      "view": "Näytä",
      "viewed": "Näytetty",
      "view_details": "Näytä tiedot",
      "view_count": "Katselukerrat",
      "settings": "Asetukset",
      "recent creations": "Viimeisimmät luomukset",
      "recent projects": "Viimeisimmät projektit",
      "project": "Projekti",
      "creation": "Luomus",
      "creations": "Luomukset",
      "version": "Versio",
      "versions": "Versiot",
      "versions uploaded": "Ladatut versiot",
      "timestamps": "Aikaleimat",
      "timestamp": "Aikaleima",
      "file": "Tiedosto",
      "files": "Tiedostot",
      "storage": "Tallennus",
      "users": "Käyttäjät",
      "shared_link": "Jaettu linkki",
      "shared links": "Jaetut linkit",
      "cumulative": "Kumulatiivinen",
      "Click or drag file": "Klikkaa tai raahaa ladataksesi tiedosto.",
      "file formats": "Tuetut tiedostotyypit: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov (Max 500MB)",
      "search": "Haku",
      "new version": "Uusi versio",
      "version history": "Versiohistoria",
      "shared_with": "Jaettu kanssasi",
      "shared_links": "Jaetut linkit",
      "info": "Tieto",
      "profile": "Profiili",
      "logout": "Kirjaudu ulos",
      "version not shared": "Tätä versiota ei ole vielä jaettu",
      "file hash": "Tiedoston tiiviste",
      "confirm_delete": "Vahvista poisto",
      "description": "Kuvaus",
      "no_description_available": "Ei kuvausta saatavilla",
      "created": "Luotu",
      "date_created": "Luontipäivä",
      "date_shared": "Jakamispäivä",
      "notes": "Muistiinpanot",
      "actions": "Toiminnot",
      "download_certificate": "Lataa todistus",
      "download_file": "Lataa tiedosto",
      "downloading_certificate": "Ladataan todistusta",
      "downloading_file": "Ladataan tiedostoa",
      "resend_confirmation_email": "Lähetä vahvistus sähköpostitse uudelleen",
      "active_links": "Aktiiviset linkit",
      "revoked_expired_links": "Peruutetut ja vanhentuneet linkit",
      "no_projects_created": "Et ole vielä luonut projekteja, klikkaa \"Luo projekti\" sivupalkissa aloittaaksesi.",
      "unexpected_error": "Odottamaton virhe tapahtui. Yritä uudelleen myöhemmin.",
      "timestamp_confirmation_email_sent": "Aikaleiman vahvistus sähköposti lähetetty",
      "show_all_versions": "Näytä kaikki versiot",
      "type_to_search": "Kirjoita hakeaksesi...",
      "go_back_to": "Palaa",
      "select_profile": "Valitse toinen profiili",
      "app_description": "",
      "creation_version_view_description": "Voit ladata uusia versioita työstäsi, tarkastella versiohistoriaa tai jakaa työsi (uudelleen) muiden kanssa. Aikaleimatodistuksen ja tiedoston voi ladata 'toiminnot' kautta.",
      "my_projects_description": "Tällä sivulla on yleiskatsaus kaikista projekteistasi. Projektikansio tallentaa eri tiedostot, jotka liittyvät musiikkityöhön, kuten äänitiedostosi ja laulutekstisi. Tämä voi olla yhden tai useamman laulun osalta.",
      "project_detail_description": "Joka kerta kun lataat tiedoston, saat vahvistussähköpostin (aikaleima) todistuksen kera. Klikkaa tiedoston nimeä listassa jakaaksesi sen muiden kanssa tai tarkastellaksesi tarkempia tietoja aikaleimasta.",
      "create": "Luo",
      "save": "Tallenna",
      "cancel": "Peruuta",
      "no": "Ei",
      "yes": "Kyllä",
      "edit": "Muokkaa",
      "delete": "Poista",
      "close": "Sulje",
      "vault storage": "Holvin tallennus",
      "usedData": "Olet käyttänyt <strong>{{dataMb}}</strong>",
      "usedDataWithLimit": "Olet käyttänyt <strong>{{dataMb}} ({{dataPercent}}%)</strong> käytettävissä olevasta tilasta",
      "name": "Nimi",
      "uploaded by": "Lähettäjä",
      "first upload date": "Ensimmäinen latauspäivä",
      "last modified": "Viimeksi muokattu",
      "last updated": "Viimeksi päivitetty",
      "filename": "Tiedostonimi",
      "file type": "Tiedostotyyppi",
      "file size": "Tiedostokoko",
      "file_processing_error": "Tiedoston käsittelyssä tapahtui virhe. Ota yhteyttä tukeen.",
      "file_currently_processing": "Tämä tiedosto on parhaillaan käsittelyssä. Tarkista aikaleimatiedot myöhemmin.",
      "delete_project_message": "Haluatko varmasti poistaa tämän projektin pysyvästi? Kaikki tämän projektin työt ja niiden versiot poistetaan pysyvästi.",
      "delete_creation_message": "Haluatko varmasti poistaa tämän työn pysyvästi? Kaikki tämän työn versiot poistetaan pysyvästi.",
      "delete_creation_version_message": "Haluatko varmasti poistaa tämän työn version pysyvästi?",
      "delete_sharing_link_message": "Haluatko varmasti poistaa tämän jakolinkin?",
      "delete_project_error": "Projektin poistamisessa tapahtui virhe. Yritä uudelleen myöhemmin.",
      "delete_creation_version_error": "Työn version poistamisessa tapahtui virhe. Yritä uudelleen myöhemmin.",
      "project_form": {
        "title_create": "Luo projekti",
        "title_edit": "Päivitä projekti",
        "description": "Luo projektikansio tiedostojesi tallentamista varten",
        "fields": {
          "project_title_label": "Projektin otsikko",
          "project_title_helpertext": "Projektin otsikko",
          "project_description_label": "Projektin kuvaus",
          "project_description_helpertext": "Projektin kuvaus (valinnainen)"
        }
      },
      "upload_creation_form": {
        "title": "Lataa luomus",
        "upload_error_already_exists": "Olet jo ladannut tämän tiedoston aikaisemmin. Valitse toinen tiedosto.",
        "upload_error_file_too_large": "Lataamasi tiedosto on liian suuri. Lataa alle 500MB tiedosto.",
        "upload_error_file_type_not_allowed": "Lataamasi tiedostotyyppi ei ole tuettu. Lataa tiedosto, joka on tyyppiä: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov",
        "select_project_description": "Valitse projekti, jonka alle haluat ladata tämän luomuksen. Jos sinulla ei ole vielä projektia, voit luoda uuden. Jos tämä on uuden version lataus olemassaolevasta luomuksesta, voit myös valita luomuksen, jonka alle lataat uuden version.",
        "select_project_helpertext": {
          "default": "Valitse projekti, jonka alle lataat tämän tiedoston.",
          "project_selected_no_creations": "Tämä tiedosto ladataan uutena luomuksena projektin alle",
          "project_selected": "Valitse luomus alta, jos haluat ladata uuden version.",
          "creation_selected": "Tämä tiedosto ladataan uutena versiona luomuksen alle"
        },
        "headers": {
          "uploaded_file_details": "Ladattavan tiedoston tiedot",
          "creation_details": "Luomuksen tiedot",
          "select_project": "Valitse projekti"
        },
        "fields": {
          "creation_name_label": "Luomuksen nimi",
          "creation_name_helpertext": "Äskettäin ladatun luomuksen otsikko.",
          "creation_type_label": "Luomuksen tyyppi",
          "creation_type_helpertext": "Ladattavan luomuksen tyyppi",
          "creation_description_label": "Kuvaus",
          "creation_description_helpertext": "Lyhyt kuvaus tästä luomuksesta. Jos lataat uutta versiota, voit kuvata tehdyt muutokset."
        },
        "buttons": {
          "create_new_project": "Luo uusi projekti",
          "upload": "Lataa"
        }
      },
      "share_creation_form": {
        "title": "Jaa luomuksen versio: {{ creation_name }}",
        "description": "Jaa tämä versio työstäsi jollekin toiselle. Sähköpostin ainutlaatuisen linkin kautta työsi voidaan nähdä tai kuunnella alla määrittelemäsi ajan.",
        "fields": {
          "email_label": "Sähköpostiosoite",
          "email_helpertext": "Anna sähköpostiosoite henkilölle, jolle haluat jakaa",
          "duration_label": "Kesto",
          "duration_helpertext": " Määritä, kuinka kauan ainutlaatuinen linkki on voimassa",
          "notes_label": "Muistiinpanot",
          "notes_helpertext": "Lisää huomautus vastaanottajalle"
        }
      },
      "shared_links_search_form": {
        "placeholder": "Hae projektin, luomuksen tai vastaanottajan sähköpostin perusteella"
      },
      "duration_units": {
        "minutes": "minuuttia",
        "hour": "tunti",
        "hours": "tuntia",
        "day": "päivä",
        "days": "päivää",
        "week": "viikko",
        "weeks": "viikkoa",
        "month": "kuukausi",
        "months": "kuukautta",
        "year": "vuosi",
        "years": "vuotta",
        "all": "koko aika"
      },
      "Recording": "Äänitys",
      "Lyric": "Sanoitus",
      "Sheet Music": "Nuotit",
      "Video": "Video",
      "Picture": "Kuva",
      "Agreement": "Sopimus",
      "Other": "Muu",
      "shared_by": "Jaettu {{ name }} toimesta {{ date }}",
      "timestamped_by": "Aikaleiman lisännyt {{ name }}",
      "link_expires_on": "Tämä linkki vanhenee {{ date }}",
      "link_expired": "Tämä linkki on vanhentunut tai tiedoston käyttöoikeudet on muutettu.",
      "external_share": {
        "otp_code_requested_check_email": "Kertakäyttöinen pääsykoodi pyydetty, tarkista sähköpostisi",
        "otp_code_invalid": "Syöttämäsi kertakäyttöinen pääsykoodi on virheellinen",
        "otp_code_request_error": "Virhe tapahtui pyydettäessä kertakäyttöistä pääsykoodia",
        "access_protected_content": "Pääsy suojattuun sisältöön",
        "content_exclusive_for": "Tämä sisältö on saatavilla yksinomaan",
        "enter_otp": "Syötä kertakäyttöinen pääsykoodisi",
        "enter_otp_caption": "Syötä 6-numeroinen koodi, jonka lähetimme sähköpostiisi",
        "otp_dont_have_code": "Etkö saanut koodia?",
        "request_otp": "Pyydä pääsykoodi",
        "resend_otp": "Lähetä koodi uudelleen",
        "otp_proceed_request_otp": "Jatkaaksesi sisältöön, voit pyytää kertakäyttöisen pääsykoodin alla olevalla painikkeella.",
        "already_member_login": "Onko sinulla jo tili tällä sähköpostiosoitteella?",
        "login_to_access": "Kirjaudu saadaksesi pääsyn.",
        "member_login": "Kirjaudu sisään",
        "or": "tai"
      },
    }
  },
  sv: {
    "translation": {
      "admin": "Admin",
      "dashboard": "Instrumentpanel",
      "create project": "Skapa projekt",
      "upload file": "Ladda upp fil",
      "upload date": "Uppladdningsdatum",
      "my projects": "Mina projekt",
      "projects": "Projekt",
      "starred": "Stjärnmärkt",
      "shared": "Delad",
      "share": "Dela",
      "expires": "Utgår",
      "expired": "Utgånget",
      "expired on": "Utgånget den",
      "expires in": "Utgår om",
      "expires on": "Utgår den",
      "never expires": "Går aldrig ut",
      "revoked": "Återkallad",
      "view": "Visa",
      "viewed": "Visad",
      "view_details": "Visa detaljer",
      "view_count": "Totalt antal visningar",
      "settings": "Inställningar",
      "recent creations": "Senaste skapelser",
      "recent projects": "Senaste projekt",
      "project": "Projekt",
      "creation": "Skapelse",
      "creations": "Skapelser",
      "version": "Version",
      "versions": "Versioner",
      "versions uploaded": "Uppladdade versioner",
      "timestamps": "Tidsstämplar",
      "timestamp": "Tidsstämpel",
      "file": "Fil",
      "files": "Filer",
      "storage": "Lagring",
      "users": "Användare",
      "shared_link": "Delad länk",
      "shared links": "Delade länkar",
      "cumulative": "Kumulativ",
      "Click or drag file": "Klicka eller dra för att ladda upp en fil.",
      "file formats": "Stödda filtyper: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov (Max 500MB)",
      "search": "Sök",
      "new version": "Ny version",
      "version history": "Versionshistorik",
      "shared_with": "Delad med",
      "shared_links": "Delade länkar",
      "info": "Info",
      "profile": "Profil",
      "logout": "Logga ut",
      "version not shared": "Denna version har inte delats ännu",
      "file hash": "Filhash",
      "confirm_delete": "Bekräfta radering",
      "description": "Beskrivning",
      "no_description_available": "Ingen beskrivning tillgänglig",
      "created": "Skapad",
      "date_created": "Skapad datum",
      "date_shared": "Delad datum",
      "notes": "Anteckningar",
      "actions": "Åtgärder",
      "download_certificate": "Ladda ner certifikat",
      "download_file": "Ladda ner fil",
      "downloading_certificate": "Laddar ner certifikat",
      "downloading_file": "Laddar ner fil",
      "resend_confirmation_email": "Skicka om bekräftelsemail",
      "active_links": "Aktiva länkar",
      "revoked_expired_links": "Återkallade och utgångna länkar",
      "no_projects_created": "Du har inte skapat några projekt ännu, klicka på \"Skapa projekt\" i sidomenyn för att komma igång.",
      "unexpected_error": "Ett oväntat fel uppstod. Försök igen senare.",
      "timestamp_confirmation_email_sent": "Bekräftelsemail för tidsstämpel skickat",
      "show_all_versions": "Visa alla versioner",
      "type_to_search": "Skriv för att söka...",
      "go_back_to": "Gå tillbaka till",
      "select_profile": "Välj en annan profil",
      "app_description": "",
      "creation_version_view_description": "Du kan ladda upp nya versioner av ditt arbete, visa versionshistoriken eller dela ditt arbete (igen) med andra. Tidsstämpelcertifikatet och din fil kan laddas ner via 'åtgärder'.",
      "my_projects_description": "Den här sidan ger en översikt över alla dina projekt. En projektmapp lagrar de olika filerna som bidrar till ett musikaliskt verk, såsom dina ljudfiler och sångtexter. Detta kan vara per låt, eller för flera låtar.",
      "project_detail_description": "Varje gång du laddar upp en fil får du ett bekräftelsemail med ett (tidsstämpel)certifikat. Klicka på ett filnamn i listan för att dela filen med andra eller för att visa ytterligare detaljer om tidsstämpeln.",
      "create": "Skapa",
      "save": "Spara",
      "cancel": "Avbryt",
      "no": "Nej",
      "yes": "Ja",
      "edit": "Redigera",
      "delete": "Radera",
      "close": "Stäng",
      "vault storage": "Valvlagring",
      "usedData": "Du har använt <strong>{{dataMb}}</strong>",
      "usedDataWithLimit": "Du har använt <strong>{{dataMb}} ({{dataPercent}}%)</strong> av ditt tillgängliga utrymme",
      "name": "Namn",
      "uploaded by": "Uppladdad av",
      "first upload date": "Första uppladdningsdatum",
      "last modified": "Senast ändrad",
      "last updated": "Senast uppdaterad",
      "filename": "Filnamn",
      "file type": "Filtyp",
      "file size": "Filstorlek",
      "file_processing_error": "Ett fel uppstod när denna fil behandlades. Vänligen kontakta support",
      "file_currently_processing": "Denna fil behandlas för närvarande. Vänligen kom tillbaka senare för tidsstämpelinformation.",
      "delete_project_message": "Är du säker på att du vill permanent radera detta projekt? Alla verk och deras versioner i detta projekt kommer att raderas permanent.",
      "delete_creation_message": "Är du säker på att du vill permanent radera detta verk? Alla versioner av detta verk kommer att raderas permanent.",
      "delete_creation_version_message": "Är du säker på att du vill permanent radera denna version av ditt verk?",
      "delete_sharing_link_message": "Är du säker på att du vill radera denna delningslänk?",
      "delete_project_error": "Ett fel uppstod vid radering av detta projekt. Försök igen senare",
      "delete_creation_version_error": "Ett fel uppstod vid radering av denna version av ditt verk. Försök igen senare",
      "project_form": {
        "title_create": "Skapa projekt",
        "title_edit": "Uppdatera projekt",
        "description": "Ställ in en projektmapp för att lagra dina filer",
        "fields": {
          "project_title_label": "Projekttitel",
          "project_title_helpertext": "Projektets titel",
          "project_description_label": "Projektbeskrivning",
          "project_description_helpertext": "Beskrivning av ditt projekt (valfritt)"
        }
      },
      "upload_creation_form": {
        "title": "Ladda upp skapelse",
        "upload_error_already_exists": "Du har redan laddat upp denna fil tidigare. Vänligen välj en annan fil.",
        "upload_error_file_too_large": "Filen du försöker ladda upp är för stor. Vänligen ladda upp en fil som är mindre än 500MB.",
        "upload_error_file_type_not_allowed": "Filtypen du försöker ladda upp stöds inte. Vänligen ladda upp en fil av följande typer: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov",
        "select_project_description": "Välj det projekt du vill ladda upp denna skapelse under. Om du inte har ett projekt ännu kan du skapa ett nytt. Om detta är en ny version av en befintlig skapelse kan du också välja skapelsen för att ladda upp den nya versionen under.",
        "select_project_helpertext": {
          "default": "Välj ett projekt att ladda upp denna fil under.",
          "project_selected_no_creations": "Denna fil kommer att laddas upp som en ny skapelse under projektet",
          "project_selected": "Välj en skapelse nedan för att ladda upp som en ny version.",
          "creation_selected": "Denna fil kommer att laddas upp som en ny version under skapelsen"
        },
        "headers": {
          "uploaded_file_details": "Uppladdade fildetaljer",
          "creation_details": "Skapelsedetaljer",
          "select_project": "Välj projekt"
        },
        "fields": {
          "creation_name_label": "Skapelsenamn",
          "creation_name_helpertext": "Titeln på den nyligen uppladdade skapelsen.",
          "creation_type_label": "Skapelsetyp",
          "creation_type_helpertext": "Typen av skapelse som laddas upp",
          "creation_description_label": "Beskrivning",
          "creation_description_helpertext": "En kort beskrivning av denna skapelse. Om du laddar upp en ny version kan du vilja beskriva de gjorda ändringarna."
        },
        "buttons": {
          "create_new_project": "Skapa nytt projekt",
          "upload": "Ladda upp"
        }
      },
      "share_creation_form": {
        "title": "Dela skapelseversion: {{ creation_name }}",
        "description": "Dela denna version av ditt verk med någon annan. Genom en unik länk i mailet kan ditt verk visas eller lyssnas på under den period du anger nedan.",
        "fields": {
          "email_label": "E-postadress",
          "email_helpertext": "Ange e-postadressen till personen du vill dela med",
          "duration_label": "Varaktighet",
          "duration_helpertext": "Ange hur länge den unika länken är giltig",
          "notes_label": "Anteckningar",
          "notes_helpertext": "Lägg till en anteckning till mottagaren"
        }
      },
      "shared_links_search_form": {
        "placeholder": "Sök efter projekt, skapelse eller mottagarens e-post"
      },
      "duration_units": {
        "minutes": "minuter",
        "hour": "timme",
        "hours": "timmar",
        "day": "dag",
        "days": "dagar",
        "week": "vecka",
        "weeks": "veckor",
        "month": "månad",
        "months": "månader",
        "year": "år",
        "years": "år",
        "all": "all tid"
      },
      "Recording": "Inspelning",
      "Lyric": "Text",
      "Sheet Music": "Notblad",
      "Video": "Video",
      "Picture": "Bild",
      "Agreement": "Avtal",
      "Other": "Annat",
      "shared_by": "Delad av {{ name }} den {{ date }}",
      "timestamped_by": "Tidsstämplad av {{ name }}",
      "link_expires_on": "Denna länk utgår den {{ date }}",
      "link_expired": "Denna länk har gått ut eller filbehörigheterna har ändrats.",
      "external_share": {
        "otp_code_requested_check_email": "Engångskod begärd, vänligen kontrollera din e-post",
        "otp_code_invalid": "Engångskoden du angav är ogiltig",
        "otp_code_request_error": "Ett fel uppstod när engångskoden begärdes",
        "access_protected_content": "Åtkomst till skyddat innehåll",
        "content_exclusive_for": "Detta innehåll är exklusivt tillgängligt för",
        "enter_otp": "Ange din engångskod",
        "enter_otp_caption": "Ange den 6-siffriga alfanumeriska koden som vi skickade till din e-postadress",
        "otp_dont_have_code": "Fick du ingen kod?",
        "request_otp": "Begär åtkomstkod",
        "resend_otp": "Skicka koden igen",
        "otp_proceed_request_otp": "För att få åtkomst till innehållet kan du begära en engångskod med knappen nedan.",
        "already_member_login": "Har du redan ett konto med den här e-postadressen?",
        "login_to_access": "Logga in för att få åtkomst.",
        "member_login": "Logga in",
        "or": "eller"
      }
    }
  },
  nb: {
    "translation": {
      "admin": "Admin",
      "dashboard": "Dashbord",
      "create project": "Opprett prosjekt",
      "upload file": "Last opp fil",
      "upload date": "Opplastingsdato",
      "my projects": "Mine prosjekter",
      "projects": "Prosjekter",
      "starred": "Favoritter",
      "shared": "Delt",
      "share": "Del",
      "expires": "Utløper",
      "expired": "Utløpt",
      "expired on": "Utløpt den",
      "expires in": "Utløper om",
      "expires on": "Utløper den",
      "never expires": "Utløper aldri",
      "revoked": "Tilbakekalt",
      "view": "Vis",
      "viewed": "Vist",
      "view_details": "Vis detaljer",
      "view_count": "Totalt antall visninger",
      "settings": "Innstillinger",
      "recent creations": "Nylige skapelser",
      "recent projects": "Nylige prosjekter",
      "project": "Prosjekt",
      "creation": "Skapelse",
      "creations": "Skapelser",
      "version": "Versjon",
      "versions": "Versjoner",
      "versions uploaded": "Opplastede versjoner",
      "timestamps": "Tidsstempler",
      "timestamp": "Tidsstempel",
      "file": "Fil",
      "files": "Filer",
      "storage": "Lagring",
      "users": "Brukere",
      "shared_link": "Delt lenke",
      "shared links": "Delte lenker",
      "cumulative": "Kumulativ",
      "Click or drag file": "Klikk eller dra for å laste opp en fil.",
      "file formats": "Støttede filtyper: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov (Maks 500MB)",
      "search": "Søk",
      "new version": "Ny versjon",
      "version history": "Versjonshistorikk",
      "shared_with": "Delt med",
      "shared_links": "Delte lenker",
      "info": "Info",
      "profile": "Profil",
      "logout": "Logg ut",
      "version not shared": "Denne versjonen har ikke blitt delt ennå",
      "file hash": "Fil-hash",
      "confirm_delete": "Bekreft sletting",
      "description": "Beskrivelse",
      "no_description_available": "Ingen beskrivelse tilgjengelig",
      "created": "Opprettet",
      "date_created": "Opprettelsesdato",
      "date_shared": "Delingsdato",
      "notes": "Notater",
      "actions": "Handlinger",
      "download_certificate": "Last ned sertifikat",
      "download_file": "Last ned fil",
      "downloading_certificate": "Laster ned sertifikat",
      "downloading_file": "Laster ned fil",
      "resend_confirmation_email": "Send bekreftelses-e-post på nytt",
      "active_links": "Aktive lenker",
      "revoked_expired_links": "Tilbakekalte og utløpte lenker",
      "no_projects_created": "Du har ikke opprettet noen prosjekter ennå, klikk \"Opprett prosjekt\" i sidepanelet for å komme i gang.",
      "unexpected_error": "En uventet feil oppstod. Vennligst prøv igjen senere.",
      "timestamp_confirmation_email_sent": "Tidsstempel bekreftelses-e-post sendt",
      "show_all_versions": "Vis alle versjoner",
      "type_to_search": "Skriv for å søke...",
      "go_back_to": "Gå tilbake til",
      "select_profile": "Velg en annen profil",
      "app_description": "",
      "creation_version_view_description": "Du kan laste opp nye versjoner av arbeidet ditt, se versjonshistorikken eller dele arbeidet ditt (på nytt) med andre. Tidsstempel-sertifikatet og filen din kan lastes ned via 'handlinger'",
      "my_projects_description": "Denne siden gir en oversikt over alle prosjektene dine. En prosjektmappe lagrer de forskjellige filene som bidrar til et musikalsk verk, som lydfiler og sangtekster. Dette kan være per sang eller for flere sanger.",
      "project_detail_description": "Hver gang du laster opp en fil, vil du motta en bekreftelses-e-post med et (tidsstempel) sertifikat. Klikk på et filnavn i listen for å dele filen med andre eller for å se flere detaljer om tidsstempelet.",
      "create": "Opprett",
      "save": "Lagre",
      "cancel": "Avbryt",
      "no": "Nei",
      "yes": "Ja",
      "edit": "Rediger",
      "delete": "Slett",
      "close": "Lukk",
      "vault storage": "Hvelvlagring",
      "usedData": "Du har brukt <strong>{{dataMb}}</strong>",
      "usedDataWithLimit": "Du har brukt <strong>{{dataMb}} ({{dataPercent}}%)</strong> av tilgjengelig plass",
      "name": "Navn",
      "uploaded by": "Lastet opp av",
      "first upload date": "Første opplastingsdato",
      "last modified": "Sist endret",
      "last updated": "Sist oppdatert",
      "filename": "Filnavn",
      "file type": "Filtype",
      "file size": "Filstørrelse",
      "file_processing_error": "Det oppstod en feil under behandlingen av denne filen. Vennligst kontakt support",
      "file_currently_processing": "Denne filen behandles for øyeblikket. Vennligst sjekk tilbake senere for tidsstempelinformasjon",
      "delete_project_message": "Er du sikker på at du vil slette dette prosjektet permanent? Alle arbeider og deres versjoner i dette prosjektet vil bli slettet permanent.",
      "delete_creation_message": "Er du sikker på at du vil slette dette arbeidet permanent? Alle versjoner av dette arbeidet vil bli slettet permanent.",
      "delete_creation_version_message": "Er du sikker på at du vil slette denne versjonen av arbeidet ditt?",
      "delete_sharing_link_message": "Er du sikker på at du vil slette denne delingslenken?",
      "delete_project_error": "Det oppstod en feil ved sletting av dette prosjektet. Vennligst prøv igjen senere",
      "delete_creation_version_error": "Det oppstod en feil ved sletting av denne versjonen av arbeidet ditt. Vennligst prøv igjen senere",
      "project_form": {
        "title_create": "Opprett prosjekt",
        "title_edit": "Oppdater prosjekt",
        "description": "Sett opp en prosjektmappe for å lagre filene dine",
        "fields": {
          "project_title_label": "Prosjekttittel",
          "project_title_helpertext": "Tittelen på prosjektet",
          "project_description_label": "Prosjektbeskrivelse",
          "project_description_helpertext": "Beskrivelse av prosjektet ditt (valgfritt)"
        }
      },
      "upload_creation_form": {
        "title": "Last opp skapelse",
        "upload_error_already_exists": "Du har allerede lastet opp denne filen før. Vennligst velg en annen fil.",
        "upload_error_file_too_large": "Filen du prøver å laste opp er for stor. Vennligst last opp en fil som er mindre enn 500MB.",
        "upload_error_file_type_not_allowed": "Filtypen du prøver å laste opp støttes ikke. Vennligst last opp en fil med en av følgende typer: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov",
        "select_project_description": "Velg prosjektet du vil laste opp denne skapelsen under. Hvis du ikke har et prosjekt ennå, kan du opprette et nytt. Hvis dette er en ny versjon av en eksisterende skapelse, kan du også velge skapelsen for å laste opp den nye versjonen under.",
        "select_project_helpertext": {
          "default": "Velg et prosjekt for å laste opp denne filen under.",
          "project_selected_no_creations": "Denne filen vil bli lastet opp som en ny skapelse under prosjektet",
          "project_selected": "Velg en skapelse nedenfor for å laste opp som en ny versjon.",
          "creation_selected": "Denne filen vil bli lastet opp som en ny versjon under skapelsen"
        },
        "headers": {
          "uploaded_file_details": "Detaljer om opplastet fil",
          "creation_details": "Skapelsesdetaljer",
          "select_project": "Velg prosjekt"
        },
        "fields": {
          "creation_name_label": "Skapelsesnavn",
          "creation_name_helpertext": "Tittelen på den nyopplastede skapelsen.",
          "creation_type_label": "Skapelsestype",
          "creation_type_helpertext": "Typen skapelse som lastes opp",
          "creation_description_label": "Beskrivelse",
          "creation_description_helpertext": "En kort beskrivelse av denne skapelsen. Hvis du laster opp en ny versjon, kan du beskrive endringene som er gjort."
        },
        "buttons": {
          "create_new_project": "Opprett nytt prosjekt",
          "upload": "Last opp"
        }
      },
      "share_creation_form": {
        "title": "Del skapelsesversjon: {{ creation_name }}",
        "description": "Del denne versjonen av arbeidet ditt med noen andre. Gjennom en unik lenke i e-posten kan arbeidet ditt vises eller høres i den perioden du spesifiserer nedenfor.",
        "fields": {
          "email_label": "E-postadresse",
          "email_helpertext": "Skriv inn e-postadressen til personen du vil dele med",
          "duration_label": "Varighet",
          "duration_helpertext": "Spesifiser hvor lenge den unike lenken er gyldig",
          "notes_label": "Notater",
          "notes_helpertext": "Legg til en notat for mottakeren"
        }
      },
      "shared_links_search_form": {
        "placeholder": "Søk etter prosjekt, skapelse eller mottakers e-post"
      },
      "duration_units": {
        "minutes": "minutter",
        "hour": "time",
        "hours": "timer",
        "day": "dag",
        "days": "dager",
        "week": "uke",
        "weeks": "uker",
        "month": "måned",
        "months": "måneder",
        "year": "år",
        "years": "år",
        "all": "all tid"
      },
      "Recording": "Innspilling",
      "Lyric": "Tekst",
      "Sheet Music": "Noter",
      "Video": "Video",
      "Picture": "Bilde",
      "Agreement": "Avtale",
      "Other": "Annet",
      "shared_by": "Delt av {{ name }} den {{ date }}",
      "timestamped_by": "Tidsstemplet av {{ name }}",
      "link_expires_on": "Denne lenken utløper den {{ date }}",
      "link_expired": "Denne lenken har utløpt eller filrettighetene har blitt endret.",
      "external_share": {
        "otp_code_requested_check_email": "Engangskode forespurt, vennligst sjekk e-posten din",
        "otp_code_invalid": "Engangskoden du skrev inn er ugyldig",
        "otp_code_request_error": "En feil oppstod under forespørsel om engangskode",
        "access_protected_content": "Tilgang til beskyttet innhold",
        "content_exclusive_for": "Dette innholdet er eksklusivt tilgjengelig for",
        "enter_otp": "Skriv inn engangskoden din",
        "enter_otp_caption": "Vennligst skriv inn den 6-sifrede alfanumeriske koden vi sendte til din e-postadresse",
        "otp_dont_have_code": "Fikk du ikke en kode?",
        "request_otp": "Be om tilgangskode",
        "resend_otp": "Send koden på nytt",
        "otp_proceed_request_otp": "For å få tilgang til innholdet kan du be om en engangskode med knappen nedenfor.",
        "already_member_login": "Har du allerede en konto med denne e-postadressen?",
        "login_to_access": "Logg inn for å få tilgang.",
        "member_login": "Logg inn",
        "or": "eller"
      }
    }
  },
  de: {
    "translation": {
      "admin": "Admin",
      "dashboard": "Dashboard",
      "create project": "Projekt erstellen",
      "upload file": "Datei hochladen",
      "upload date": "Upload-Datum",
      "my projects": "Meine Projekte",
      "projects": "Projekte",
      "starred": "Favoriten",
      "shared": "Geteilt",
      "share": "Teilen",
      "expires": "Läuft ab",
      "expired": "Abgelaufen",
      "expired on": "Abgelaufen am",
      "expires in": "Läuft ab in",
      "expires on": "Läuft ab am",
      "never expires": "Läuft nie ab",
      "revoked": "Widerrufen",
      "view": "Ansehen",
      "viewed": "Angesehen",
      "view_details": "Details anzeigen",
      "view_count": "Gesamtansichten",
      "settings": "Einstellungen",
      "recent creations": "Letzte Erstellungen",
      "recent projects": "Letzte Projekte",
      "project": "Projekt",
      "creation": "Erstellung",
      "creations": "Erstellungen",
      "version": "Version",
      "versions": "Versionen",
      "versions uploaded": "Hochgeladene Versionen",
      "timestamps": "Zeitstempel",
      "timestamp": "Zeitstempel",
      "file": "Datei",
      "files": "Dateien",
      "storage": "Speicher",
      "users": "Benutzer",
      "shared_link": "Geteilter Link",
      "shared links": "Geteilte Links",
      "cumulative": "Kumulativ",
      "Click or drag file": "Klicken oder ziehen, um eine Datei hochzuladen.",
      "file formats": "Unterstützte Dateitypen: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov (Max. 500MB)",
      "search": "Suchen",
      "new version": "Neue Version",
      "version history": "Versionsverlauf",
      "shared_with": "Geteilt mit",
      "shared_links": "Geteilte Links",
      "info": "Info",
      "profile": "Profil",
      "logout": "Abmelden",
      "version not shared": "Diese Version wurde noch nicht geteilt",
      "file hash": "Datei-Hash",
      "confirm_delete": "Löschen bestätigen",
      "description": "Beschreibung",
      "no_description_available": "Keine Beschreibung verfügbar",
      "created": "Erstellt",
      "date_created": "Erstellungsdatum",
      "date_shared": "Freigabedatum",
      "notes": "Notizen",
      "actions": "Aktionen",
      "download_certificate": "Zertifikat herunterladen",
      "download_file": "Datei herunterladen",
      "downloading_certificate": "Zertifikat wird heruntergeladen",
      "downloading_file": "Datei wird heruntergeladen",
      "resend_confirmation_email": "Bestätigungs-E-Mail erneut senden",
      "active_links": "Aktive Links",
      "revoked_expired_links": "Widerrufene & abgelaufene Links",
      "no_projects_created": "Sie haben noch keine Projekte erstellt. Klicken Sie auf \"Projekt erstellen\" in der Seitenleiste, um zu beginnen.",
      "unexpected_error": "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
      "timestamp_confirmation_email_sent": "Zeitstempel-Bestätigungs-E-Mail gesendet",
      "show_all_versions": "Alle Versionen anzeigen",
      "type_to_search": "Zum Suchen eingeben...",
      "go_back_to": "Zurück zu",
      "select_profile": "Ein anderes Profil wählen",
      "app_description": "",
      "creation_version_view_description": "Sie können neue Versionen Ihrer Arbeit hochladen, den Versionsverlauf einsehen oder Ihre Arbeit (erneut) mit anderen teilen. Das Zeitstempelzertifikat und Ihre Datei können über 'Aktionen' heruntergeladen werden.",
      "my_projects_description": "Diese Seite bietet einen Überblick über all Ihre Projekte. Ein Projektordner speichert die verschiedenen Dateien, die zu einem musikalischen Werk beitragen, wie Ihre Audiodateien und Songtexte. Dies kann pro Lied oder für mehrere Lieder sein.",
      "project_detail_description": "Jedes Mal, wenn Sie eine Datei hochladen, erhalten Sie eine Bestätigungs-E-Mail mit einem (Zeitstempel-)Zertifikat. Klicken Sie auf einen Dateinamen in der Liste, um die Datei mit anderen zu teilen oder weitere Details zum Zeitstempel anzuzeigen.",
      "create": "Erstellen",
      "save": "Speichern",
      "cancel": "Abbrechen",
      "no": "Nein",
      "yes": "Ja",
      "edit": "Bearbeiten",
      "delete": "Löschen",
      "close": "Schließen",
      "vault storage": "Tresorspeicher",
      "usedData": "Sie haben <strong>{{dataMb}}</strong> verwendet",
      "usedDataWithLimit": "Sie haben <strong>{{dataMb}} ({{dataPercent}}%)</strong> Ihres verfügbaren Speicherplatzes verwendet",
      "name": "Name",
      "uploaded by": "Hochgeladen von",
      "first upload date": "Erstes Hochladedatum",
      "last modified": "Zuletzt geändert",
      "last updated": "Zuletzt aktualisiert",
      "filename": "Dateiname",
      "file type": "Dateityp",
      "file size": "Dateigröße",
      "file_processing_error": "Beim Verarbeiten dieser Datei ist ein Fehler aufgetreten. Bitte kontaktieren Sie den Support.",
      "file_currently_processing": "Diese Datei wird gerade verarbeitet. Bitte überprüfen Sie später die Zeitstempel-Informationen.",
      "delete_project_message": "Möchten Sie dieses Projekt wirklich dauerhaft löschen? Alle Arbeiten und ihre Versionen in diesem Projekt werden dauerhaft gelöscht.",
      "delete_creation_message": "Möchten Sie diese Arbeit wirklich dauerhaft löschen? Alle Versionen dieser Arbeit werden dauerhaft gelöscht.",
      "delete_creation_version_message": "Möchten Sie diese Version Ihrer Arbeit wirklich dauerhaft löschen?",
      "delete_sharing_link_message": "Möchten Sie diesen Freigabelink wirklich löschen?",
      "delete_project_error": "Beim Löschen dieses Projekts ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
      "delete_creation_version_error": "Beim Löschen dieser Version Ihrer Arbeit ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
      "project_form": {
        "title_create": "Projekt erstellen",
        "title_edit": "Projekt aktualisieren",
        "description": "Richten Sie einen Projektordner ein, um Ihre Dateien zu speichern",
        "fields": {
          "project_title_label": "Projekttitel",
          "project_title_helpertext": "Der Titel des Projekts",
          "project_description_label": "Projektbeschreibung",
          "project_description_helpertext": "Beschreibung Ihres Projekts (optional)"
        }
      },
      "upload_creation_form": {
        "title": "Erstellung hochladen",
        "upload_error_already_exists": "Sie haben diese Datei bereits hochgeladen. Bitte wählen Sie eine andere Datei aus.",
        "upload_error_file_too_large": "Die Datei, die Sie hochladen möchten, ist zu groß. Bitte laden Sie eine Datei hoch, die kleiner als 500MB ist.",
        "upload_error_file_type_not_allowed": "Der Dateityp, den Sie hochladen möchten, wird nicht unterstützt. Bitte laden Sie eine Datei mit einem der folgenden Typen hoch: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov",
        "select_project_description": "Wählen Sie das Projekt aus, unter dem Sie diese Erstellung hochladen möchten. Wenn Sie noch kein Projekt haben, können Sie ein neues erstellen. Wenn dies eine neue Version einer bestehenden Erstellung ist, können Sie auch die Erstellung auswählen, um die neue Version hochzuladen.",
        "select_project_helpertext": {
          "default": "Wählen Sie ein Projekt, unter dem diese Datei hochgeladen werden soll.",
          "project_selected_no_creations": "Diese Datei wird als neue Erstellung unter dem Projekt hochgeladen",
          "project_selected": "Wählen Sie unten eine Erstellung aus, um als neue Version hochzuladen.",
          "creation_selected": "Diese Datei wird als neue Version unter der Erstellung hochgeladen"
        },
        "headers": {
          "uploaded_file_details": "Hochgeladene Dateidetails",
          "creation_details": "Erstellungsdetails",
          "select_project": "Projekt auswählen"
        },
        "fields": {
          "creation_name_label": "Erstellungsname",
          "creation_name_helpertext": "Der Titel der neu hochgeladenen Erstellung.",
          "creation_type_label": "Erstellungstyp",
          "creation_type_helpertext": "Der Typ der hochgeladenen Erstellung",
          "creation_description_label": "Beschreibung",
          "creation_description_helpertext": "Eine kurze Beschreibung dieser Erstellung. Wenn Sie eine neue Version hochladen, möchten Sie vielleicht die vorgenommenen Änderungen beschreiben."
        },
        "buttons": {
          "create_new_project": "Neues Projekt erstellen",
          "upload": "Hochladen"
        }
      },
      "share_creation_form": {
        "title": "Erstellungsversion teilen: {{ creation_name }}",
        "description": "Teilen Sie diese Version Ihrer Arbeit mit jemand anderem. Über einen einzigartigen Link in der E-Mail kann Ihre Arbeit für den unten angegebenen Zeitraum angesehen oder angehört werden.",
        "fields": {
          "email_label": "E-Mail-Adresse",
          "email_helpertext": "Geben Sie die E-Mail-Adresse der Person ein, mit der Sie teilen möchten",
          "duration_label": "Dauer",
          "duration_helpertext": "Geben Sie an, wie lange der einzigartige Link gültig ist",
          "notes_label": "Notizen",
          "notes_helpertext": "Fügen Sie eine Notiz für den Empfänger hinzu"
        }
      },
      "shared_links_search_form": {
        "placeholder": "Nach Projekt, Erstellung oder Empfänger-E-Mail suchen"
      },
      "duration_units": {
        "minutes": "Minuten",
        "hour": "Stunde",
        "hours": "Stunden",
        "day": "Tag",
        "days": "Tage",
        "week": "Woche",
        "weeks": "Wochen",
        "month": "Monat",
        "months": "Monate",
        "year": "Jahr",
        "years": "Jahre",
        "all": "alle Zeit"
      },
      "Recording": "Aufnahme",
      "Lyric": "Liedtext",
      "Sheet Music": "Notenblatt",
      "Video": "Video",
      "Picture": "Bild",
      "Agreement": "Vereinbarung",
      "Other": "Andere",
      "shared_by": "Geteilt von {{ name }} am {{ date }}",
      "timestamped_by": "Zeitgestempelt von {{ name }}",
      "link_expires_on": "Dieser Link läuft am {{ date }} ab",
      "link_expired": "Dieser Link ist abgelaufen oder die Dateiberechtigungen wurden geändert.",
      "external_share": {
        "otp_code_requested_check_email": "Einmaliger Zugangscode angefordert, bitte überprüfen Sie Ihre E-Mail",
        "otp_code_invalid": "Der eingegebene einmalige Zugangscode ist ungültig",
        "otp_code_request_error": "Beim Anfordern des einmaligen Zugangscodes ist ein Fehler aufgetreten",
        "access_protected_content": "Zugriff auf geschützten Inhalt",
        "content_exclusive_for": "Dieser Inhalt ist ausschließlich verfügbar für",
        "enter_otp": "Geben Sie Ihren einmaligen Zugangscode ein",
        "enter_otp_caption": "Bitte geben Sie den 6-stelligen alphanumerischen Code ein, den wir an Ihre E-Mail-Adresse gesendet haben",
        "otp_dont_have_code": "Keinen Code erhalten?",
        "request_otp": "Zugangscode anfordern",
        "resend_otp": "Code erneut senden",
        "otp_proceed_request_otp": "Um zum Inhalt zu gelangen, können Sie mit dem untenstehenden Button einen einmaligen Zugangscode anfordern.",
        "already_member_login": "Haben Sie bereits ein Konto mit dieser E-Mail-Adresse?",
        "login_to_access": "Melden Sie sich an, um Zugriff zu erhalten.",
        "member_login": "Anmelden",
        "or": "oder"
      }
    }
  },
  es: {
    "translation": {
      "admin": "Administrador",
      "dashboard": "Tablero",
      "create project": "Crear Proyecto",
      "upload file": "Subir Archivo",
      "upload date": "Fecha de Subida",
      "my projects": "Mis Proyectos",
      "projects": "Proyectos",
      "starred": "Destacado",
      "shared": "Compartido",
      "share": "Compartir",
      "expires": "Expira",
      "expired": "Expirado",
      "expired on": "Expirado el",
      "expires in": "Expira en",
      "expires on": "Expira el",
      "never expires": "Nunca expira",
      "revoked": "Revocado",
      "view": "Ver",
      "viewed": "Visto",
      "view_details": "Ver Detalles",
      "view_count": "Total de Vistas",
      "settings": "Configuración",
      "recent creations": "Creaciones Recientes",
      "recent projects": "Proyectos Recientes",
      "project": "Proyecto",
      "creation": "Creación",
      "creations": "Creaciones",
      "version": "Versión",
      "versions": "Versiones",
      "versions uploaded": "Versiones Subidas",
      "timestamps": "Marcas de Tiempo",
      "timestamp": "Marca de Tiempo",
      "file": "Archivo",
      "files": "Archivos",
      "storage": "Almacenamiento",
      "users": "Usuarios",
      "shared_link": "Enlace Compartido",
      "shared links": "Enlaces Compartidos",
      "cumulative": "Acumulativo",
      "Click or drag file": "Haz clic o arrastra para subir un archivo.",
      "file formats": "Tipos de archivo admitidos: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov (Máx 500MB)",
      "search": "Buscar",
      "new version": "Nueva Versión",
      "version history": "Historial de Versiones",
      "shared_with": "Compartido Con",
      "shared_links": "Enlaces Compartidos",
      "info": "Información",
      "profile": "Perfil",
      "logout": "Cerrar Sesión",
      "version not shared": "Esta versión no se ha compartido aún",
      "file hash": "Hash del Archivo",
      "confirm_delete": "Confirmar Eliminación",
      "description": "Descripción",
      "no_description_available": "No hay descripción disponible",
      "created": "Creado",
      "date_created": "Fecha de Creación",
      "date_shared": "Fecha de Compartición",
      "notes": "Notas",
      "actions": "Acciones",
      "download_certificate": "Descargar Certificado",
      "download_file": "Descargar Archivo",
      "downloading_certificate": "Descargando Certificado",
      "downloading_file": "Descargando Archivo",
      "resend_confirmation_email": "Reenviar Correo de Confirmación",
      "active_links": "Enlaces Activos",
      "revoked_expired_links": "Enlaces Revocados y Expirados",
      "no_projects_created": "Aún no has creado ningún proyecto, haz clic en \"Crear Proyecto\" en la barra lateral para empezar.",
      "unexpected_error": "Ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.",
      "timestamp_confirmation_email_sent": "Correo de confirmación de marca de tiempo enviado",
      "show_all_versions": "Mostrar todas las versiones",
      "type_to_search": "Escribe para buscar...",
      "go_back_to": "Volver a",
      "select_profile": "Elegir un perfil diferente",
      "app_description": "",
      "creation_version_view_description": "Puedes subir nuevas versiones de tu trabajo, ver el historial de versiones o compartir tu trabajo (nuevamente) con otros. El certificado de marca de tiempo y tu archivo pueden descargarse a través de 'acciones'",
      "my_projects_description": "Esta página proporciona una vista general de todos tus proyectos. Una carpeta de proyecto almacena los diferentes archivos que contribuyen a una obra musical, como tus archivos de audio y letras de canciones. Esto puede ser por canción o para múltiples canciones.",
      "project_detail_description": "Cada vez que subas un archivo, recibirás un correo de confirmación con un certificado (marca de tiempo). Haz clic en cualquier nombre de archivo en la lista para compartir el archivo con otros o para ver más detalles de la marca de tiempo.",
      "create": "Crear",
      "save": "Guardar",
      "cancel": "Cancelar",
      "no": "No",
      "yes": "Sí",
      "edit": "Editar",
      "delete": "Eliminar",
      "close": "Cerrar",
      "vault storage": "Almacenamiento Seguro",
      "usedData": "Has utilizado <strong>{{dataMb}}</strong>",
      "usedDataWithLimit": "Has utilizado <strong>{{dataMb}} ({{dataPercent}}%)</strong> de tu espacio disponible",
      "name": "Nombre",
      "uploaded by": "Subido por",
      "first upload date": "Fecha de la Primera Subida",
      "last modified": "Última Modificación",
      "last updated": "Última Actualización",
      "filename": "Nombre del Archivo",
      "file type": "Tipo de Archivo",
      "file size": "Tamaño del Archivo",
      "file_processing_error": "Hubo un error al procesar este archivo. Por favor, contacta con el soporte",
      "file_currently_processing": "Este archivo está siendo procesado actualmente. Por favor, vuelve más tarde para obtener la información de la marca de tiempo",
      "delete_project_message": "¿Estás seguro de que deseas eliminar permanentemente este proyecto? Todas las obras y sus versiones en este proyecto se eliminarán permanentemente.",
      "delete_creation_message": "¿Estás seguro de que deseas eliminar permanentemente esta obra? Todas las versiones de esta obra se eliminarán permanentemente.",
      "delete_creation_version_message": "¿Estás seguro de que deseas eliminar permanentemente esta versión de tu obra?",
      "delete_sharing_link_message": "¿Estás seguro de que deseas eliminar este enlace compartido?",
      "delete_project_error": "Hubo un error al eliminar este proyecto. Por favor, inténtalo de nuevo más tarde",
      "delete_creation_version_error": "Hubo un error al eliminar esta versión de tu obra. Por favor, inténtalo de nuevo más tarde",
      "project_form": {
        "title_create": "Crear Proyecto",
        "title_edit": "Actualizar Proyecto",
        "description": "Configura una carpeta de proyecto para almacenar tus archivos",
        "fields": {
          "project_title_label": "Título del Proyecto",
          "project_title_helpertext": "El título del proyecto",
          "project_description_label": "Descripción del Proyecto",
          "project_description_helpertext": "Descripción de tu proyecto (opcional)"
        }
      },
      "upload_creation_form": {
        "title": "Subir Creación",
        "upload_error_already_exists": "Ya has subido este archivo antes. Por favor, selecciona un archivo diferente.",
        "upload_error_file_too_large": "El archivo que intentas subir es demasiado grande. Por favor, sube un archivo menor de 500MB.",
        "upload_error_file_type_not_allowed": "El tipo de archivo que intentas subir no es compatible. Por favor, sube un archivo con uno de los siguientes tipos: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov",
        "select_project_description": "Selecciona el proyecto en el que deseas subir esta creación. Si aún no tienes un proyecto, puedes crear uno nuevo. Si esta es una nueva versión de una creación existente, también puedes seleccionar la creación para subir la nueva versión.",
        "select_project_helpertext": {
          "default": "Selecciona un Proyecto para subir este archivo.",
          "project_selected_no_creations": "Este archivo se subirá como una nueva creación bajo el proyecto",
          "project_selected": "Selecciona una Creación abajo para subir como una nueva versión.",
          "creation_selected": "Este archivo se subirá como una nueva versión bajo la creación"
        },
        "headers": {
          "uploaded_file_details": "Detalles del Archivo Subido",
          "creation_details": "Detalles de la Creación",
          "select_project": "Seleccionar Proyecto"
        },
        "fields": {
          "creation_name_label": "Nombre de la Creación",
          "creation_name_helpertext": "El título de la creación recién subida.",
          "creation_type_label": "Tipo de Creación",
          "creation_type_helpertext": "El tipo de creación que se está subiendo",
          "creation_description_label": "Descripción",
          "creation_description_helpertext": "Una breve descripción sobre esta creación. Si estás subiendo una nueva versión, es posible que desees describir los cambios realizados."
        },
        "buttons": {
          "create_new_project": "Crear Nuevo Proyecto",
          "upload": "Subir"
        }
      },
      "share_creation_form": {
        "title": "Compartir Versión de Creación: {{ creation_name }}",
        "description": "Comparte esta versión de tu obra con otra persona. A través de un enlace único en el correo electrónico, tu obra puede ser vista o escuchada durante el período que especifiques a continuación.",
        "fields": {
          "email_label": "Dirección de Correo Electrónico",
          "email_helpertext": "Ingresa la dirección de correo electrónico de la persona con la que deseas compartir",
          "duration_label": "Duración",
          "duration_helpertext": "Especifica cuánto tiempo es válido el enlace único",
          "notes_label": "Notas",
          "notes_helpertext": "Añadir una nota para el destinatario"
        }
      },
      "shared_links_search_form": {
        "placeholder": "Buscar por proyecto, creación o correo electrónico del destinatario"
      },
      "duration_units": {
        "minutes": "minutos",
        "hour": "hora",
        "hours": "horas",
        "day": "día",
        "days": "días",
        "week": "semana",
        "weeks": "semanas",
        "month": "mes",
        "months": "meses",
        "year": "año",
        "years": "años",
        "all": "todo el tiempo"
      },
      "Recording": "Grabación",
      "Lyric": "Letra",
      "Sheet Music": "Partitura",
      "Video": "Video",
      "Picture": "Imagen",
      "Agreement": "Acuerdo",
      "Other": "Otro",
      "shared_by": "Compartido por {{ name }} el {{ date }}",
      "timestamped_by": "Marcado por {{ name }}",
      "link_expires_on": "Este enlace expira el {{ date }}",
      "link_expired": "Este enlace ha expirado o los permisos del archivo han sido modificados.",
      "external_share": {
        "otp_code_requested_check_email": "Código de acceso único solicitado, por favor revisa tu correo electrónico",
        "otp_code_invalid": "El código de acceso único que ingresaste no es válido",
        "otp_code_request_error": "Se produjo un error al solicitar el código de acceso único",
        "access_protected_content": "Acceso al contenido seguro",
        "content_exclusive_for": "Este contenido está disponible exclusivamente para",
        "enter_otp": "Ingresa tu código de acceso único",
        "enter_otp_caption": "Por favor, ingresa el código alfanumérico de 6 dígitos que enviamos a tu dirección de correo electrónico",
        "otp_dont_have_code": "¿No recibiste un código?",
        "request_otp": "Solicitar código de acceso",
        "resend_otp": "Reenviar código",
        "otp_proceed_request_otp": "Para acceder al contenido, puedes solicitar un código de acceso único usando el botón de abajo.",
        "already_member_login": "¿Ya tienes una cuenta con esta dirección de correo electrónico?",
        "login_to_access": "Inicia sesión para acceder.",
        "member_login": "Iniciar sesión",
        "or": "o"
      }
    }
  },
  it: {
    "translation": {
      "admin": "Amministratore",
      "dashboard": "Pannello di controllo",
      "create project": "Crea Progetto",
      "upload file": "Carica File",
      "upload date": "Data di Caricamento",
      "my projects": "I Miei Progetti",
      "projects": "Progetti",
      "starred": "Preferiti",
      "shared": "Condivisi",
      "share": "Condividi",
      "expires": "Scade",
      "expired": "Scaduto",
      "expired on": "Scaduto il",
      "expires in": "Scade tra",
      "expires on": "Scade il",
      "never expires": "Non scade mai",
      "revoked": "Revocato",
      "view": "Visualizza",
      "viewed": "Visualizzato",
      "view_details": "Visualizza Dettagli",
      "view_count": "Visualizzazioni Totali",
      "settings": "Impostazioni",
      "recent creations": "Creazioni Recenti",
      "recent projects": "Progetti Recenti",
      "project": "Progetto",
      "creation": "Creazione",
      "creations": "Creazioni",
      "version": "Versione",
      "versions": "Versioni",
      "versions uploaded": "Versioni Caricate",
      "timestamps": "Marcature temporali",
      "timestamp": "Marcatura temporale",
      "file": "File",
      "files": "File",
      "storage": "Archivio",
      "users": "Utenti",
      "shared_link": "Link Condiviso",
      "shared links": "Link Condivisi",
      "cumulative": "Cumulativo",
      "Click or drag file": "Clicca o trascina per caricare un file.",
      "file formats": "Tipi di file supportati: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov (Max 500MB)",
      "search": "Cerca",
      "new version": "Nuova Versione",
      "version history": "Cronologia Versioni",
      "shared_with": "Condiviso Con",
      "shared_links": "Link Condivisi",
      "info": "Informazioni",
      "profile": "Profilo",
      "logout": "Disconnetti",
      "version not shared": "Questa versione non è stata ancora condivisa",
      "file hash": "Hash del File",
      "confirm_delete": "Conferma Eliminazione",
      "description": "Descrizione",
      "no_description_available": "Nessuna descrizione disponibile",
      "created": "Creato",
      "date_created": "Data di Creazione",
      "date_shared": "Data di Condivisione",
      "notes": "Note",
      "actions": "Azioni",
      "download_certificate": "Scarica Certificato",
      "download_file": "Scarica File",
      "downloading_certificate": "Scaricamento Certificato in corso",
      "downloading_file": "Scaricamento File in corso",
      "resend_confirmation_email": "Rinvia Email di Conferma",
      "active_links": "Link Attivi",
      "revoked_expired_links": "Link Revocati & Scaduti",
      "no_projects_created": "Non hai ancora creato alcun progetto, clicca su \"Crea Progetto\" nella barra laterale per iniziare.",
      "unexpected_error": "Si è verificato un errore imprevisto. Per favore riprova più tardi.",
      "timestamp_confirmation_email_sent": "Email di conferma della marcatura temporale inviata",
      "show_all_versions": "Mostra tutte le versioni",
      "type_to_search": "Digita per cercare...",
      "go_back_to": "Torna a",
      "select_profile": "Scegli un altro profilo",
      "app_description": "",
      "creation_version_view_description": "Puoi caricare nuove versioni del tuo lavoro, visualizzare la cronologia delle versioni o condividere nuovamente il tuo lavoro con altri. Il certificato di marcatura temporale e il tuo file possono essere scaricati tramite 'azioni'",
      "my_projects_description": "Questa pagina offre una panoramica di tutti i tuoi progetti. Una cartella di progetto memorizza i diversi file che contribuiscono a un'opera musicale, come i tuoi file audio e i testi delle canzoni. Questo può essere per una singola canzone o per più canzoni.",
      "project_detail_description": "Ogni volta che carichi un file, riceverai un'email di conferma con un certificato (marcatura temporale). Clicca su qualsiasi nome di file nell'elenco per condividere il file con altri o per visualizzare ulteriori dettagli della marcatura temporale.",
      "create": "Crea",
      "save": "Salva",
      "cancel": "Annulla",
      "no": "No",
      "yes": "Sì",
      "edit": "Modifica",
      "delete": "Elimina",
      "close": "Chiudi",
      "vault storage": "Archivio Sicuro",
      "usedData": "Hai utilizzato <strong>{{dataMb}}</strong>",
      "usedDataWithLimit": "Hai utilizzato <strong>{{dataMb}} ({{dataPercent}}%)</strong> dello spazio disponibile",
      "name": "Nome",
      "uploaded by": "Caricato Da",
      "first upload date": "Data del Primo Caricamento",
      "last modified": "Ultima Modifica",
      "last updated": "Ultimo Aggiornamento",
      "filename": "Nome File",
      "file type": "Tipo di File",
      "file size": "Dimensione del File",
      "file_processing_error": "Si è verificato un errore durante l'elaborazione di questo file. Per favore contatta il supporto",
      "file_currently_processing": "Questo file è attualmente in elaborazione. Controlla più tardi per informazioni sulla marcatura temporale",
      "delete_project_message": "Sei sicuro di voler eliminare definitivamente questo progetto? Tutte le opere e le loro versioni in questo progetto verranno eliminate definitivamente.",
      "delete_creation_message": "Sei sicuro di voler eliminare definitivamente questa opera? Tutte le versioni di questa opera verranno eliminate definitivamente.",
      "delete_creation_version_message": "Sei sicuro di voler eliminare definitivamente questa versione della tua opera?",
      "delete_sharing_link_message": "Sei sicuro di voler eliminare questo link di condivisione?",
      "delete_project_error": "Si è verificato un errore durante l'eliminazione di questo progetto. Per favore riprova più tardi",
      "delete_creation_version_error": "Si è verificato un errore durante l'eliminazione di questa versione della tua opera. Per favore riprova più tardi",
      "project_form": {
        "title_create": "Crea Progetto",
        "title_edit": "Aggiorna Progetto",
        "description": "Imposta una cartella di progetto per memorizzare i tuoi file",
        "fields": {
          "project_title_label": "Titolo del Progetto",
          "project_title_helpertext": "Il titolo del progetto",
          "project_description_label": "Descrizione del Progetto",
          "project_description_helpertext": "Descrizione del tuo progetto (opzionale)"
        }
      },
      "upload_creation_form": {
        "title": "Carica Creazione",
        "upload_error_already_exists": "Hai già caricato questo file in precedenza. Per favore seleziona un file diverso.",
        "upload_error_file_too_large": "Il file che stai cercando di caricare è troppo grande. Per favore carica un file inferiore a 500MB.",
        "upload_error_file_type_not_allowed": "Il tipo di file che stai cercando di caricare non è supportato. Per favore carica un file con uno dei seguenti tipi: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov",
        "select_project_description": "Seleziona il progetto sotto il quale desideri caricare questa creazione. Se non hai ancora un progetto, puoi crearne uno nuovo. Se questa è una nuova versione di una creazione esistente, puoi anche selezionare la creazione per caricare la nuova versione.",
        "select_project_helpertext": {
          "default": "Seleziona un Progetto sotto il quale caricare questo file.",
          "project_selected_no_creations": "Questo file verrà caricato come nuova creazione sotto il progetto",
          "project_selected": "Seleziona una Creazione qui sotto per caricarla come nuova versione.",
          "creation_selected": "Questo file verrà caricato come nuova versione sotto la creazione"
        },
        "headers": {
          "uploaded_file_details": "Dettagli del File Caricato",
          "creation_details": "Dettagli della Creazione",
          "select_project": "Seleziona Progetto"
        },
        "fields": {
          "creation_name_label": "Nome della Creazione",
          "creation_name_helpertext": "Il titolo della creazione appena caricata.",
          "creation_type_label": "Tipo di Creazione",
          "creation_type_helpertext": "Il tipo di creazione che stai caricando",
          "creation_description_label": "Descrizione",
          "creation_description_helpertext": "Una breve descrizione di questa creazione. Se stai caricando una nuova versione, potresti voler descrivere le modifiche apportate."
        },
        "buttons": {
          "create_new_project": "Crea Nuovo Progetto",
          "upload": "Carica"
        }
      },
      "share_creation_form": {
        "title": "Condividi Versione della Creazione: {{ creation_name }}",
        "description": "Condividi questa versione del tuo lavoro con qualcun altro. Tramite un link unico nell'email, il tuo lavoro può essere visualizzato o ascoltato per il periodo che specifichi qui sotto.",
        "fields": {
          "email_label": "Indirizzo Email",
          "email_helpertext": "Inserisci l'indirizzo email della persona con cui desideri condividere",
          "duration_label": "Durata",
          "duration_helpertext": "Specifica per quanto tempo il link unico sarà valido",
          "notes_label": "Note",
          "notes_helpertext": "Aggiungi una nota per il destinatario"
        }
      },
      "shared_links_search_form": {
        "placeholder": "Cerca per progetto, creazione o email del destinatario"
      },
      "duration_units": {
        "minutes": "minuti",
        "hour": "ora",
        "hours": "ore",
        "day": "giorno",
        "days": "giorni",
        "week": "settimana",
        "weeks": "settimane",
        "month": "mese",
        "months": "mesi",
        "year": "anno",
        "years": "anni",
        "all": "tutto il tempo"
      },
      "Recording": "Registrazione",
      "Lyric": "Testo",
      "Sheet Music": "Partitura",
      "Video": "Video",
      "Picture": "Immagine",
      "Agreement": "Accordo",
      "Other": "Altro",
      "shared_by": "Condiviso da {{ name }} il {{ date }}",
      "timestamped_by": "Marcato temporalmente da {{ name }}",
      "link_expires_on": "Questo link scade il {{ date }}",
      "link_expired": "Questo link è scaduto o i permessi del file sono stati modificati.",
      "external_share": {
        "otp_code_requested_check_email": "Codice di accesso unico richiesto, controlla la tua email",
        "otp_code_invalid": "Il codice di accesso unico inserito non è valido",
        "otp_code_request_error": "Si è verificato un errore durante la richiesta del codice di accesso unico",
        "access_protected_content": "Accesso al contenuto protetto",
        "content_exclusive_for": "Questo contenuto è disponibile esclusivamente per",
        "enter_otp": "Inserisci il tuo codice di accesso unico",
        "enter_otp_caption": "Inserisci il codice alfanumerico a 6 cifre che abbiamo inviato al tuo indirizzo email",
        "otp_dont_have_code": "Non hai ricevuto un codice?",
        "request_otp": "Richiedi codice di accesso",
        "resend_otp": "Rinvia il codice",
        "otp_proceed_request_otp": "Per accedere al contenuto, puoi richiedere un codice di accesso unico usando il pulsante qui sotto.",
        "already_member_login": "Hai già un account con questo indirizzo email?",
        "login_to_access": "Accedi per ottenere l'accesso.",
        "member_login": "Accesso",
        "or": "o"
      }
    }
  },
  ko: {
    "translation": {
      "admin": "관리자",
      "dashboard": "대시보드",
      "create project": "프로젝트 생성",
      "upload file": "파일 업로드",
      "upload date": "업로드 날짜",
      "my projects": "내 프로젝트",
      "projects": "프로젝트",
      "starred": "즐겨찾기",
      "shared": "공유됨",
      "share": "공유",
      "expires": "만료됨",
      "expired": "만료됨",
      "expired on": "만료 일자",
      "expires in": "만료까지",
      "expires on": "만료 일자",
      "never expires": "만료되지 않음",
      "revoked": "취소됨",
      "view": "보기",
      "viewed": "조회됨",
      "view_details": "세부 정보 보기",
      "view_count": "총 조회수",
      "settings": "설정",
      "recent creations": "최근 작품",
      "recent projects": "최근 프로젝트",
      "project": "프로젝트",
      "creation": "작품",
      "creations": "작품들",
      "version": "버전",
      "versions": "버전들",
      "versions uploaded": "업로드된 버전",
      "timestamps": "타임스탬프",
      "timestamp": "타임스탬프",
      "file": "파일",
      "files": "파일들",
      "storage": "저장소",
      "users": "사용자",
      "shared_link": "공유 링크",
      "shared links": "공유 링크들",
      "cumulative": "누적",
      "Click or drag file": "파일을 클릭하거나 드래그하여 업로드하세요.",
      "file formats": "지원되는 파일 형식: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov (최대 500MB)",
      "search": "검색",
      "new version": "새 버전",
      "version history": "버전 기록",
      "shared_with": "공유 대상",
      "shared_links": "공유 링크들",
      "info": "정보",
      "profile": "프로필",
      "logout": "로그아웃",
      "version not shared": "이 버전은 아직 공유되지 않았습니다",
      "file hash": "파일 해시",
      "confirm_delete": "삭제 확인",
      "description": "설명",
      "no_description_available": "설명이 없습니다",
      "created": "생성됨",
      "date_created": "생성 날짜",
      "date_shared": "공유 날짜",
      "notes": "메모",
      "actions": "작업",
      "download_certificate": "인증서 다운로드",
      "download_file": "파일 다운로드",
      "downloading_certificate": "인증서 다운로드 중",
      "downloading_file": "파일 다운로드 중",
      "resend_confirmation_email": "확인 이메일 재전송",
      "active_links": "활성 링크",
      "revoked_expired_links": "취소됨 및 만료됨 링크",
      "no_projects_created": "아직 생성된 프로젝트가 없습니다. 시작하려면 사이드바에서 \"프로젝트 생성\"을 클릭하세요.",
      "unexpected_error": "예기치 않은 오류가 발생했습니다. 나중에 다시 시도하세요.",
      "timestamp_confirmation_email_sent": "타임스탬프 확인 이메일이 발송되었습니다",
      "show_all_versions": "모든 버전 보기",
      "type_to_search": "검색어 입력...",
      "go_back_to": "돌아가기",
      "select_profile": "다른 프로필 선택",
      "app_description": "",
      "creation_version_view_description": "작품의 새 버전을 업로드하거나 버전 기록을 확인하거나 다른 사람과 작품을 (다시) 공유할 수 있습니다. '작업'을 통해 타임스탬프 인증서와 파일을 다운로드할 수 있습니다.",
      "my_projects_description": "이 페이지에서는 모든 프로젝트를 한눈에 볼 수 있습니다. 프로젝트 폴더는 오디오 파일 및 노래 가사와 같은 음악 작품의 다양한 파일을 저장합니다. 이는 노래별로 또는 여러 노래에 대해 가능합니다.",
      "project_detail_description": "파일을 업로드할 때마다 (타임스탬프) 인증서가 포함된 확인 이메일을 받게 됩니다. 목록에서 파일 이름을 클릭하면 다른 사람과 파일을 공유하거나 타임스탬프의 추가 세부 정보를 볼 수 있습니다.",
      "create": "생성",
      "save": "저장",
      "cancel": "취소",
      "no": "아니요",
      "yes": "예",
      "edit": "편집",
      "delete": "삭제",
      "close": "닫기",
      "vault storage": "보관소 저장",
      "usedData": "사용한 데이터: <strong>{{dataMb}}</strong>",
      "usedDataWithLimit": "사용한 데이터: <strong>{{dataMb}} ({{dataPercent}}%)</strong> / 사용 가능한 공간",
      "name": "이름",
      "uploaded by": "업로드한 사람",
      "first upload date": "첫 업로드 날짜",
      "last modified": "마지막 수정 날짜",
      "last updated": "마지막 업데이트 날짜",
      "filename": "파일 이름",
      "file type": "파일 형식",
      "file size": "파일 크기",
      "file_processing_error": "이 파일을 처리하는 중 오류가 발생했습니다. 지원팀에 문의하세요",
      "file_currently_processing": "이 파일은 현재 처리 중입니다. 타임스탬프 정보를 나중에 다시 확인하세요",
      "delete_project_message": "이 프로젝트를 영구히 삭제하시겠습니까? 이 프로젝트의 모든 작품과 그 버전이 영구히 삭제됩니다.",
      "delete_creation_message": "이 작품을 영구히 삭제하시겠습니까? 이 작품의 모든 버전이 영구히 삭제됩니다.",
      "delete_creation_version_message": "이 작품의 이 버전을 영구히 삭제하시겠습니까?",
      "delete_sharing_link_message": "이 공유 링크를 삭제하시겠습니까?",
      "delete_project_error": "프로젝트를 삭제하는 중 오류가 발생했습니다. 나중에 다시 시도하세요",
      "delete_creation_version_error": "이 작품 버전을 삭제하는 중 오류가 발생했습니다. 나중에 다시 시도하세요",
      "project_form": {
        "title_create": "프로젝트 생성",
        "title_edit": "프로젝트 업데이트",
        "description": "파일을 저장할 프로젝트 폴더를 설정하세요",
        "fields": {
          "project_title_label": "프로젝트 제목",
          "project_title_helpertext": "프로젝트의 제목",
          "project_description_label": "프로젝트 설명",
          "project_description_helpertext": "프로젝트 설명 (선택 사항)"
        }
      },
      "upload_creation_form": {
        "title": "작품 업로드",
        "upload_error_already_exists": "이 파일은 이미 업로드되었습니다. 다른 파일을 선택하세요.",
        "upload_error_file_too_large": "업로드하려는 파일이 너무 큽니다. 500MB 이하의 파일을 업로드하세요.",
        "upload_error_file_type_not_allowed": "업로드하려는 파일 형식이 지원되지 않습니다. 다음 형식 중 하나의 파일을 업로드하세요: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov",
        "select_project_description": "이 작품을 업로드할 프로젝트를 선택하세요. 아직 프로젝트가 없다면 새 프로젝트를 생성할 수 있습니다. 기존 작품의 새 버전인 경우, 해당 작품을 선택하여 새 버전을 업로드할 수도 있습니다.",
        "select_project_helpertext": {
          "default": "이 파일을 업로드할 프로젝트를 선택하세요.",
          "project_selected_no_creations": "이 파일은 프로젝트에 새로운 작품으로 업로드됩니다",
          "project_selected": "아래에서 새 버전으로 업로드할 작품을 선택하세요.",
          "creation_selected": "이 파일은 작품에 새 버전으로 업로드됩니다"
        },
        "headers": {
          "uploaded_file_details": "업로드된 파일 세부 정보",
          "creation_details": "작품 세부 정보",
          "select_project": "프로젝트 선택"
        },
        "fields": {
          "creation_name_label": "작품 이름",
          "creation_name_helpertext": "업로드된 새로운 작품의 제목.",
          "creation_type_label": "작품 유형",
          "creation_type_helpertext": "업로드 중인 작품의 유형",
          "creation_description_label": "설명",
          "creation_description_helpertext": "이 작품에 대한 간단한 설명. 새 버전을 업로드하는 경우, 변경된 내용을 설명할 수 있습니다."
        },
        "buttons": {
          "create_new_project": "새 프로젝트 생성",
          "upload": "업로드"
        }
      },
      "share_creation_form": {
        "title": "작품 버전 공유: {{ creation_name }}",
        "description": "이 버전의 작품을 다른 사람과 공유하세요. 이메일의 고유 링크를 통해 지정한 기간 동안 작품을 볼 수 있습니다.",
        "fields": {
          "email_label": "이메일 주소",
          "email_helpertext": "공유하려는 사람의 이메일 주소를 입력하세요",
          "duration_label": "기간",
          "duration_helpertext": "고유 링크의 유효 기간을 지정하세요",
          "notes_label": "메모",
          "notes_helpertext": "수신자에게 남길 메모를 추가하세요"
        }
      },
      "shared_links_search_form": {
        "placeholder": "프로젝트, 작품 또는 수신자 이메일로 검색"
      },
      "duration_units": {
        "minutes": "분",
        "hour": "시간",
        "hours": "시간",
        "day": "일",
        "days": "일",
        "week": "주",
        "weeks": "주",
        "month": "월",
        "months": "월",
        "year": "년",
        "years": "년",
        "all": "전체 시간"
      },
      "Recording": "녹음",
      "Lyric": "가사",
      "Sheet Music": "악보",
      "Video": "비디오",
      "Picture": "사진",
      "Agreement": "계약",
      "Other": "기타",
      "shared_by": "{{ name }}이(가) {{ date }}에 공유함",
      "timestamped_by": "{{ name }}이(가) 타임스탬프함",
      "link_expires_on": "이 링크는 {{ date }}에 만료됩니다",
      "link_expired": "이 링크는 만료되었거나 파일 권한이 수정되었습니다.",
      "external_share": {
        "otp_code_requested_check_email": "일회성 접근 코드가 요청되었습니다. 이메일을 확인하세요",
        "otp_code_invalid": "입력한 일회성 접근 코드가 유효하지 않습니다",
        "otp_code_request_error": "일회성 접근 코드 요청 중 오류가 발생했습니다",
        "access_protected_content": "보호된 콘텐츠에 접근",
        "content_exclusive_for": "이 콘텐츠는 독점적으로 제공됩니다",
        "enter_otp": "일회성 접근 코드를 입력하세요",
        "enter_otp_caption": "이메일로 전송된 6자리 영숫자 코드를 입력하세요",
        "otp_dont_have_code": "코드를 받지 못하셨나요?",
        "request_otp": "접근 코드 요청",
        "resend_otp": "코드 재전송",
        "otp_proceed_request_otp": "콘텐츠로 이동하려면 아래 버튼을 눌러 일회성 접근 코드를 요청할 수 있습니다.",
        "already_member_login": "이미 이 이메일 주소로 계정이 있나요?",
        "login_to_access": "로그인하여 접근하세요.",
        "member_login": "로그인",
        "or": "또는"
      }
    }
  },
  ja: {
    "translation": {
      "admin": "管理者",
      "dashboard": "ダッシュボード",
      "create project": "プロジェクトを作成",
      "upload file": "ファイルをアップロード",
      "upload date": "アップロード日",
      "my projects": "私のプロジェクト",
      "projects": "プロジェクト",
      "starred": "スター付き",
      "shared": "共有済み",
      "share": "共有",
      "expires": "有効期限",
      "expired": "期限切れ",
      "expired on": "期限切れ日",
      "expires in": "有効期限内",
      "expires on": "有効期限",
      "never expires": "期限なし",
      "revoked": "取り消し済み",
      "view": "表示",
      "viewed": "表示済み",
      "view_details": "詳細を見る",
      "view_count": "総表示回数",
      "settings": "設定",
      "recent creations": "最近の作成",
      "recent projects": "最近のプロジェクト",
      "project": "プロジェクト",
      "creation": "作成",
      "creations": "作成物",
      "version": "バージョン",
      "versions": "バージョン",
      "versions uploaded": "アップロードされたバージョン",
      "timestamps": "タイムスタンプ",
      "timestamp": "タイムスタンプ",
      "file": "ファイル",
      "files": "ファイル",
      "storage": "ストレージ",
      "users": "ユーザー",
      "shared_link": "共有リンク",
      "shared links": "共有リンク",
      "cumulative": "累積",
      "Click or drag file": "クリックまたはドラッグしてファイルをアップロードします。",
      "file formats": "サポートされているファイルタイプ: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov (最大500MB)",
      "search": "検索",
      "new version": "新しいバージョン",
      "version history": "バージョン履歴",
      "shared_with": "共有先",
      "shared_links": "共有リンク",
      "info": "情報",
      "profile": "プロフィール",
      "logout": "ログアウト",
      "version not shared": "このバージョンはまだ共有されていません",
      "file hash": "ファイルハッシュ",
      "confirm_delete": "削除を確認",
      "description": "説明",
      "no_description_available": "説明は利用できません",
      "created": "作成日",
      "date_created": "作成日",
      "date_shared": "共有日",
      "notes": "ノート",
      "actions": "アクション",
      "download_certificate": "証明書をダウンロード",
      "download_file": "ファイルをダウンロード",
      "downloading_certificate": "証明書をダウンロード中",
      "downloading_file": "ファイルをダウンロード中",
      "resend_confirmation_email": "確認メールを再送信",
      "active_links": "アクティブリンク",
      "revoked_expired_links": "取り消し済み & 期限切れリンク",
      "no_projects_created": "まだプロジェクトを作成していません。サイドバーの「プロジェクトを作成」をクリックして開始してください。",
      "unexpected_error": "予期しないエラーが発生しました。後でもう一度お試しください。",
      "timestamp_confirmation_email_sent": "タイムスタンプ確認メールが送信されました",
      "show_all_versions": "すべてのバージョンを表示",
      "type_to_search": "検索するには入力してください...",
      "go_back_to": "戻る",
      "select_profile": "別のプロフィールを選択",
      "app_description": "",
      "creation_version_view_description": "新しいバージョンをアップロードしたり、バージョン履歴を表示したり、他の人と再度共有したりできます。タイムスタンプ証明書とファイルは「アクション」からダウンロードできます。",
      "my_projects_description": "このページでは、すべてのプロジェクトの概要を確認できます。プロジェクトフォルダには、音楽作品に貢献するさまざまなファイルが保存されます。これは、曲ごと、または複数の曲のために行うことができます。",
      "project_detail_description": "ファイルをアップロードするたびに、（タイムスタンプ）証明書が記載された確認メールが届きます。リスト内のファイル名をクリックして、ファイルを他の人と共有したり、タイムスタンプの詳細を表示したりできます。",
      "create": "作成",
      "save": "保存",
      "cancel": "キャンセル",
      "no": "いいえ",
      "yes": "はい",
      "edit": "編集",
      "delete": "削除",
      "close": "閉じる",
      "vault storage": "保管庫ストレージ",
      "usedData": "使用済みデータ量：<strong>{{dataMb}}</strong>",
      "usedDataWithLimit": "使用済みデータ量：<strong>{{dataMb}} ({{dataPercent}}%)</strong>／利用可能なスペース",
      "name": "名前",
      "uploaded by": "アップロード者",
      "first upload date": "最初のアップロード日",
      "last modified": "最終更新日",
      "last updated": "最終更新日",
      "filename": "ファイル名",
      "file type": "ファイルタイプ",
      "file size": "ファイルサイズ",
      "file_processing_error": "このファイルを処理中にエラーが発生しました。サポートに連絡してください。",
      "file_currently_processing": "このファイルは現在処理中です。後でタイムスタンプ情報を確認してください。",
      "delete_project_message": "このプロジェクトを完全に削除してもよろしいですか？このプロジェクト内のすべての作品およびそのバージョンが完全に削除されます。",
      "delete_creation_message": "この作品を完全に削除してもよろしいですか？この作品のすべてのバージョンが完全に削除されます。",
      "delete_creation_version_message": "この作品のバージョンを完全に削除してもよろしいですか？",
      "delete_sharing_link_message": "この共有リンクを削除してもよろしいですか？",
      "delete_project_error": "プロジェクトの削除中にエラーが発生しました。後でもう一度お試しください。",
      "delete_creation_version_error": "作品のバージョンを削除中にエラーが発生しました。後でもう一度お試しください。",
      "project_form": {
        "title_create": "プロジェクトを作成",
        "title_edit": "プロジェクトを更新",
        "description": "ファイルを保存するためのプロジェクトフォルダを設定します",
        "fields": {
          "project_title_label": "プロジェクトタイトル",
          "project_title_helpertext": "プロジェクトのタイトル",
          "project_description_label": "プロジェクトの説明",
          "project_description_helpertext": "プロジェクトの説明（任意）"
        }
      },
      "upload_creation_form": {
        "title": "作成をアップロード",
        "upload_error_already_exists": "このファイルはすでにアップロードされています。別のファイルを選択してください。",
        "upload_error_file_too_large": "アップロードしようとしているファイルが大きすぎます。500MB未満のファイルをアップロードしてください。",
        "upload_error_file_type_not_allowed": "アップロードしようとしているファイルタイプはサポートされていません。次のタイプのファイルをアップロードしてください: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov",
        "select_project_description": "この作成をアップロードするプロジェクトを選択してください。まだプロジェクトがない場合は、新しいプロジェクトを作成できます。既存の作成の新しいバージョンをアップロードする場合は、作成を選択して新しいバージョンをアップロードすることもできます。",
        "select_project_helpertext": {
          "default": "このファイルをアップロードするプロジェクトを選択してください。",
          "project_selected_no_creations": "このファイルはプロジェクトに新しい作成としてアップロードされます。",
          "project_selected": "以下から作成を選択して新しいバージョンとしてアップロードします。",
          "creation_selected": "このファイルは作成の新しいバージョンとしてアップロードされます。"
        },
        "headers": {
          "uploaded_file_details": "アップロードされたファイルの詳細",
          "creation_details": "作成の詳細",
          "select_project": "プロジェクトを選択"
        },
        "fields": {
          "creation_name_label": "作成名",
          "creation_name_helpertext": "新しくアップロードされた作成のタイトル。",
          "creation_type_label": "作成タイプ",
          "creation_type_helpertext": "アップロードしている作成のタイプ",
          "creation_description_label": "説明",
          "creation_description_helpertext": "この作成に関する簡単な説明です。新しいバージョンをアップロードしている場合、変更点を説明することができます。"
        },
        "buttons": {
          "create_new_project": "新しいプロジェクトを作成",
          "upload": "アップロード"
        }
      },
      "share_creation_form": {
        "title": "作成バージョンを共有: {{ creation_name }}",
        "description": "このバージョンの作品を他の人と共有します。メール内のユニークなリンクを通じて、指定した期間中に作品を表示または視聴することができます。",
        "fields": {
          "email_label": "メールアドレス",
          "email_helpertext": "共有したい相手のメールアドレスを入力してください",
          "duration_label": "期間",
          "duration_helpertext": "ユニークリンクが有効な期間を指定してください",
          "notes_label": "ノート",
          "notes_helpertext": "受信者にメモを追加"
        }
      },
      "shared_links_search_form": {
        "placeholder": "プロジェクト、作成または受信者のメールで検索"
      },
      "duration_units": {
        "minutes": "分",
        "hour": "時間",
        "hours": "時間",
        "day": "日",
        "days": "日",
        "week": "週",
        "weeks": "週間",
        "month": "月",
        "months": "月",
        "year": "年",
        "years": "年",
        "all": "すべての期間"
      },
      "Recording": "録音",
      "Lyric": "歌詞",
      "Sheet Music": "楽譜",
      "Video": "ビデオ",
      "Picture": "画像",
      "Agreement": "合意",
      "Other": "その他",
      "shared_by": "{{ name }} が {{ date }} に共有しました",
      "timestamped_by": "{{ name }} によってタイムスタンプされました",
      "link_expires_on": "このリンクの有効期限は {{ date }} です",
      "link_expired": "このリンクは期限が切れたか、ファイルの権限が変更されました。",
      "external_share": {
        "otp_code_requested_check_email": "ワンタイムアクセスコードがリクエストされました。メールを確認してください",
        "otp_code_invalid": "入力したワンタイムアクセスコードは無効です",
        "otp_code_request_error": "ワンタイムアクセスコードのリクエスト中にエラーが発生しました",
        "access_protected_content": "保護されたコンテンツへのアクセス",
        "content_exclusive_for": "このコンテンツは限定的に提供されています",
        "enter_otp": "ワンタイムアクセスコードを入力してください",
        "enter_otp_caption": "メールアドレスに送信された6桁の英数字コードを入力してください",
        "otp_dont_have_code": "コードが届いていませんか？",
        "request_otp": "アクセスコードをリクエスト",
        "resend_otp": "コードを再送",
        "otp_proceed_request_otp": "コンテンツにアクセスするためには、下のボタンを押してワンタイムアクセスコードをリクエストできます。",
        "already_member_login": "このメールアドレスでアカウントをお持ちですか？",
        "login_to_access": "アクセスするにはログインしてください。",
        "member_login": "ログイン",
        "or": "または"
      }
    }
  },
  "zh": {
    "translation": {
      "admin": "管理员",
      "dashboard": "仪表板",
      "create project": "创建项目",
      "upload file": "上传文件",
      "upload date": "上传日期",
      "my projects": "我的项目",
      "projects": "项目",
      "starred": "加星",
      "shared": "已共享",
      "share": "分享",
      "expires": "过期",
      "expired": "已过期",
      "expired on": "过期于",
      "expires in": "过期时间",
      "expires on": "过期于",
      "never expires": "永不过期",
      "revoked": "已撤销",
      "view": "查看",
      "viewed": "已查看",
      "view_details": "查看详情",
      "view_count": "总查看次数",
      "settings": "设置",
      "recent creations": "最近的创作",
      "recent projects": "最近的项目",
      "project": "项目",
      "creation": "创作",
      "creations": "创作",
      "version": "版本",
      "versions": "版本",
      "versions uploaded": "上传的版本",
      "timestamps": "时间戳",
      "timestamp": "时间戳",
      "file": "文件",
      "files": "文件",
      "storage": "存储",
      "users": "用户",
      "shared_link": "共享链接",
      "shared links": "共享链接",
      "cumulative": "累计",
      "Click or drag file": "点击或拖动上传文件",
      "file formats": "支持的文件类型: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov (最大500MB)",
      "search": "搜索",
      "new version": "新版本",
      "version history": "版本历史",
      "shared_with": "共享对象",
      "shared_links": "共享链接",
      "info": "信息",
      "profile": "个人资料",
      "logout": "登出",
      "version not shared": "此版本尚未共享",
      "file hash": "文件哈希",
      "confirm_delete": "确认删除",
      "description": "描述",
      "no_description_available": "无可用描述",
      "created": "已创建",
      "date_created": "创建日期",
      "date_shared": "共享日期",
      "notes": "笔记",
      "actions": "操作",
      "download_certificate": "下载证书",
      "download_file": "下载文件",
      "downloading_certificate": "正在下载证书",
      "downloading_file": "正在下载文件",
      "resend_confirmation_email": "重新发送确认邮件",
      "active_links": "活跃链接",
      "revoked_expired_links": "已撤销和已过期链接",
      "no_projects_created": "您尚未创建任何项目，点击侧栏中的“创建项目”以开始。",
      "unexpected_error": "发生意外错误。请稍后再试。",
      "timestamp_confirmation_email_sent": "时间戳确认邮件已发送",
      "show_all_versions": "显示所有版本",
      "type_to_search": "输入搜索...",
      "go_back_to": "返回",
      "select_profile": "选择不同的配置文件",
      "app_description": "",
      "creation_version_view_description": "您可以上传新的作品版本，查看版本历史，或再次与他人分享您的作品。可以通过“操作”下载时间戳证书和您的文件",
      "my_projects_description": "此页面概述了您的所有项目。项目文件夹存储构成音乐作品的不同文件，例如音频文件和歌词文本。可以按歌曲或多首歌曲进行存储。",
      "project_detail_description": "每次上传文件时，您都会收到一封带有（时间戳）证书的确认邮件。点击列表中的任意文件名与他人共享文件或查看时间戳的更多详细信息。",
      "create": "创建",
      "save": "保存",
      "cancel": "取消",
      "no": "否",
      "yes": "是",
      "edit": "编辑",
      "delete": "删除",
      "close": "关闭",
      "vault storage": "保险库存储",
      "usedData": "您已使用 <strong>{{dataMb}}</strong>",
      "usedDataWithLimit": "您已使用 <strong>{{dataMb}} ({{dataPercent}}%)</strong> 的可用空间",
      "name": "名称",
      "uploaded by": "上传者",
      "first upload date": "首次上传日期",
      "last modified": "最后修改",
      "last updated": "最后更新",
      "filename": "文件名",
      "file type": "文件类型",
      "file size": "文件大小",
      "file_processing_error": "处理此文件时发生错误。请联系支持",
      "file_currently_processing": "此文件正在处理。请稍后检查时间戳信息",
      "delete_project_message": "您确定要永久删除此项目吗？此项目中的所有作品及其版本将被永久删除。",
      "delete_creation_message": "您确定要永久删除此作品吗？此作品的所有版本将被永久删除。",
      "delete_creation_version_message": "您确定要永久删除此作品的版本吗？",
      "delete_sharing_link_message": "您确定要删除此共享链接吗？",
      "delete_project_error": "删除此项目时发生错误。请稍后再试",
      "delete_creation_version_error": "删除此作品版本时发生错误。请稍后再试",
      "project_form": {
        "title_create": "创建项目",
        "title_edit": "更新项目",
        "description": "设置一个项目文件夹来存储您的文件",
        "fields": {
          "project_title_label": "项目标题",
          "project_title_helpertext": "项目的标题",
          "project_description_label": "项目描述",
          "project_description_helpertext": "您的项目描述（可选）"
        }
      },
      "upload_creation_form": {
        "title": "上传创作",
        "upload_error_already_exists": "您之前已经上传过此文件。请选择其他文件。",
        "upload_error_file_too_large": "您尝试上传的文件太大。请上传小于500MB的文件。",
        "upload_error_file_type_not_allowed": "您尝试上传的文件类型不受支持。请上传以下类型的文件之一: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov",
        "select_project_description": "选择您希望在其下上传此创作的项目。如果您还没有项目，可以创建一个新项目。如果这是现有创作的新版本，您还可以选择创作以上传新版本。",
        "select_project_helpertext": {
          "default": "选择一个项目以在其下上传此文件。",
          "project_selected_no_creations": "此文件将作为新创作上传到项目下",
          "project_selected": "在下方选择创作以作为新版本上传。",
          "creation_selected": "此文件将作为创作的新版本上传"
        },
        "headers": {
          "uploaded_file_details": "上传的文件详情",
          "creation_details": "创作详情",
          "select_project": "选择项目"
        },
        "fields": {
          "creation_name_label": "创作名称",
          "creation_name_helpertext": "新上传的创作的标题。",
          "creation_type_label": "创作类型",
          "creation_type_helpertext": "上传的创作类型",
          "creation_description_label": "描述",
          "creation_description_helpertext": "关于此创作的简要描述。如果您正在上传新版本，您可能需要描述所做的更改。"
        },
        "buttons": {
          "create_new_project": "创建新项目",
          "upload": "上传"
        }
      },
      "share_creation_form": {
        "title": "分享创作版本: {{ creation_name }}",
        "description": "与他人分享此版本的作品。通过电子邮件中的唯一链接，您的作品可以在您指定的时间内被查看或聆听。",
        "fields": {
          "email_label": "电子邮件地址",
          "email_helpertext": "输入您要分享对象的电子邮件地址",
          "duration_label": "持续时间",
          "duration_helpertext": "指定唯一链接的有效期",
          "notes_label": "备注",
          "notes_helpertext": "为收件人添加备注"
        }
      },
      "shared_links_search_form": {
        "placeholder": "按项目、创作或收件人电子邮件搜索"
      },
      "duration_units": {
        "minutes": "分钟",
        "hour": "小时",
        "hours": "小时",
        "day": "天",
        "days": "天",
        "week": "周",
        "weeks": "周",
        "month": "月",
        "months": "月",
        "year": "年",
        "years": "年",
        "all": "所有时间"
      },
      "Recording": "录音",
      "Lyric": "歌词",
      "Sheet Music": "乐谱",
      "Video": "视频",
      "Picture": "图片",
      "Agreement": "协议",
      "Other": "其他",
      "shared_by": "由 {{ name }} 于 {{ date }} 分享",
      "timestamped_by": "由 {{ name }} 进行了时间戳",
      "link_expires_on": "此链接将于 {{ date }} 过期",
      "link_expired": "此链接已过期或文件权限已被修改",
      "external_share": {
        "otp_code_requested_check_email": "已请求一次性访问代码，请检查您的电子邮件",
        "otp_code_invalid": "您输入的一次性访问代码无效",
        "otp_code_request_error": "请求一次性访问代码时出错",
        "access_protected_content": "访问受保护内容",
        "content_exclusive_for": "该内容专为提供",
        "enter_otp": "请输入您的一次性访问代码",
        "enter_otp_caption": "请输入我们发送到您电子邮件地址的6位字母数字代码",
        "otp_dont_have_code": "没有收到代码？",
        "request_otp": "请求访问代码",
        "resend_otp": "重新发送代码",
        "otp_proceed_request_otp": "要访问内容，您可以使用下面的按钮请求一次性访问代码。",
        "already_member_login": "您已经有这个电子邮件地址的帐户了吗？",
        "login_to_access": "登录以访问。",
        "member_login": "登录",
        "or": "或"
      }

    }
  },
  // ar: {
  //   "translation": {
  //     "admin": "مشرف",
  //     "dashboard": "لوحة التحكم",
  //     "create project": "إنشاء مشروع",
  //     "upload file": "رفع ملف",
  //     "upload date": "تاريخ الرفع",
  //     "my projects": "مشاريعي",
  //     "projects": "المشاريع",
  //     "starred": "المميزة",
  //     "shared": "المشتركة",
  //     "share": "مشاركة",
  //     "expires": "ينتهي",
  //     "expired": "منتهي",
  //     "expired on": "انتهى في",
  //     "expires in": "ينتهي في",
  //     "expires on": "ينتهي في",
  //     "never expires": "لا ينتهي أبدًا",
  //     "revoked": "تم إلغاؤه",
  //     "view": "عرض",
  //     "viewed": "تمت المشاهدة",
  //     "view_details": "عرض التفاصيل",
  //     "view_count": "إجمالي المشاهدات",
  //     "settings": "الإعدادات",
  //     "recent creations": "الإبداعات الأخيرة",
  //     "recent projects": "المشاريع الأخيرة",
  //     "project": "مشروع",
  //     "creation": "إبداع",
  //     "creations": "الإبداعات",
  //     "version": "إصدار",
  //     "versions": "الإصدارات",
  //     "versions uploaded": "الإصدارات المرفوعة",
  //     "timestamps": "الطوابع الزمنية",
  //     "timestamp": "الطابع الزمني",
  //     "file": "ملف",
  //     "files": "الملفات",
  //     "storage": "التخزين",
  //     "users": "المستخدمون",
  //     "shared_link": "رابط مشترك",
  //     "shared links": "الروابط المشتركة",
  //     "cumulative": "تراكمي",
  //     "Click or drag file": "انقر أو اسحب لتحميل ملف.",
  //     "file formats": "أنواع الملفات المدعومة: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov (الحد الأقصى 500 ميجابايت)",
  //     "search": "بحث",
  //     "new version": "إصدار جديد",
  //     "version history": "تاريخ الإصدار",
  //     "shared_with": "مشترك مع",
  //     "shared_links": "الروابط المشتركة",
  //     "info": "معلومات",
  //     "profile": "الملف الشخصي",
  //     "logout": "تسجيل الخروج",
  //     "version not shared": "لم يتم مشاركة هذا الإصدار بعد",
  //     "file hash": "تجزئة الملف",
  //     "confirm_delete": "تأكيد الحذف",
  //     "description": "الوصف",
  //     "no_description_available": "لا يوجد وصف متاح",
  //     "created": "تم الإنشاء",
  //     "date_created": "تاريخ الإنشاء",
  //     "date_shared": "تاريخ المشاركة",
  //     "notes": "ملاحظات",
  //     "actions": "الإجراءات",
  //     "download_certificate": "تنزيل الشهادة",
  //     "download_file": "تنزيل الملف",
  //     "downloading_certificate": "جاري تنزيل الشهادة",
  //     "downloading_file": "جاري تنزيل الملف",
  //     "resend_confirmation_email": "إعادة إرسال بريد التأكيد",
  //     "active_links": "الروابط النشطة",
  //     "revoked_expired_links": "الروابط الملغاة والمنتهية",
  //     "no_projects_created": "لم تقم بإنشاء أي مشاريع بعد، انقر على \"إنشاء مشروع\" في الشريط الجانبي للبدء.",
  //     "unexpected_error": "حدث خطأ غير متوقع. يرجى المحاولة مرة أخرى لاحقًا.",
  //     "timestamp_confirmation_email_sent": "تم إرسال بريد تأكيد الطابع الزمني",
  //     "show_all_versions": "عرض جميع الإصدارات",
  //     "type_to_search": "اكتب للبحث...",
  //     "go_back_to": "العودة إلى",
  //     "select_profile": "اختر ملف شخصي مختلف",
  //     "app_description": "",
  //     "creation_version_view_description": "يمكنك تحميل إصدارات جديدة من عملك، عرض تاريخ الإصدار، أو مشاركة عملك (مرة أخرى) مع الآخرين. يمكن تنزيل شهادة الطابع الزمني وملفك عبر 'الإجراءات'",
  //     "my_projects_description": "تقدم هذه الصفحة نظرة عامة على جميع مشاريعك. يخزن مجلد المشروع الملفات المختلفة التي تساهم في العمل الموسيقي، مثل ملفات الصوت والنصوص الغنائية. يمكن أن يكون ذلك لكل أغنية، أو لعدة أغاني.",
  //     "project_detail_description": "في كل مرة ترفع فيها ملفًا، ستتلقى بريدًا تأكيديًا مع شهادة (طابع زمني). انقر على أي اسم ملف في القائمة لمشاركة الملف مع الآخرين أو لعرض مزيد من التفاصيل حول الطابع الزمني.",
  //     "create": "إنشاء",
  //     "save": "حفظ",
  //     "cancel": "إلغاء",
  //     "no": "لا",
  //     "yes": "نعم",
  //     "edit": "تعديل",
  //     "delete": "حذف",
  //     "close": "إغلاق",
  //     "vault storage": "تخزين القبو",
  //     "usedData": "لقد استخدمت <strong>{{dataMb}}</strong>",
  //     "usedDataWithLimit": "لقد استخدمت <strong>{{dataMb}} ({{dataPercent}}%)</strong> من مساحتك المتاحة",
  //     "name": "الاسم",
  //     "uploaded by": "تم الرفع بواسطة",
  //     "first upload date": "تاريخ الرفع الأول",
  //     "last modified": "آخر تعديل",
  //     "last updated": "آخر تحديث",
  //     "filename": "اسم الملف",
  //     "file type": "نوع الملف",
  //     "file size": "حجم الملف",
  //     "file_processing_error": "حدث خطأ أثناء معالجة هذا الملف. يرجى الاتصال بالدعم",
  //     "file_currently_processing": "يتم حاليًا معالجة هذا الملف. يرجى التحقق لاحقًا للحصول على معلومات الطابع الزمني",
  //     "delete_project_message": "هل أنت متأكد أنك تريد حذف هذا المشروع نهائيًا؟ سيتم حذف جميع الأعمال وإصداراتها في هذا المشروع نهائيًا.",
  //     "delete_creation_message": "هل أنت متأكد أنك تريد حذف هذا العمل نهائيًا؟ سيتم حذف جميع إصدارات هذا العمل نهائيًا.",
  //     "delete_creation_version_message": "هل أنت متأكد أنك تريد حذف هذا الإصدار من عملك نهائيًا؟",
  //     "delete_sharing_link_message": "هل أنت متأكد أنك تريد حذف رابط المشاركة هذا؟",
  //     "delete_project_error": "حدث خطأ عند حذف هذا المشروع. يرجى المحاولة مرة أخرى لاحقًا",
  //     "delete_creation_version_error": "حدث خطأ عند حذف هذا الإصدار من عملك. يرجى المحاولة مرة أخرى لاحقًا",
  //     "project_form": {
  //       "title_create": "إنشاء مشروع",
  //       "title_edit": "تحديث مشروع",
  //       "description": "قم بإعداد مجلد مشروع لتخزين ملفاتك",
  //       "fields": {
  //         "project_title_label": "عنوان المشروع",
  //         "project_title_helpertext": "عنوان المشروع",
  //         "project_description_label": "وصف المشروع",
  //         "project_description_helpertext": "وصف مشروعك (اختياري)"
  //       }
  //     },
  //     "upload_creation_form": {
  //       "title": "رفع إبداع",
  //       "upload_error_already_exists": "لقد قمت بالفعل برفع هذا الملف من قبل. يرجى اختيار ملف مختلف.",
  //       "upload_error_file_too_large": "الملف الذي تحاول رفعه كبير جدًا. يرجى رفع ملف أصغر من 500 ميجابايت.",
  //       "upload_error_file_type_not_allowed": "نوع الملف الذي تحاول رفعه غير مدعوم. يرجى رفع ملف من الأنواع التالية: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov",
  //       "select_project_description": "اختر المشروع الذي ترغب في رفع هذا الإبداع ضمنه. إذا لم يكن لديك مشروع بعد، يمكنك إنشاء مشروع جديد. إذا كان هذا إصدارًا جديدًا من إبداع موجود، يمكنك أيضًا اختيار الإبداع لرفع الإصدار الجديد ضمنه.",
  //       "select_project_helpertext": {
  //         "default": "اختر مشروعًا لرفع هذا الملف ضمنه.",
  //         "project_selected_no_creations": "سيتم رفع هذا الملف كإبداع جديد ضمن المشروع",
  //         "project_selected": "اختر إبداعًا أدناه لرفع كإصدار جديد.",
  //         "creation_selected": "سيتم رفع هذا الملف كإصدار جديد ضمن الإبداع"
  //       },
  //       "headers": {
  //         "uploaded_file_details": "تفاصيل الملف المرفوع",
  //         "creation_details": "تفاصيل الإبداع",
  //         "select_project": "اختر مشروع"
  //       },
  //       "fields": {
  //         "creation_name_label": "اسم الإبداع",
  //         "creation_name_helpertext": "عنوان الإبداع المرفوع حديثًا.",
  //         "creation_type_label": "نوع الإبداع",
  //         "creation_type_helpertext": "نوع الإبداع الذي يتم رفعه",
  //         "creation_description_label": "الوصف",
  //         "creation_description_helpertext": "وصف موجز عن هذا الإبداع. إذا كنت ترفع إصدارًا جديدًا، قد ترغب في وصف التغييرات التي تم إجراؤها."
  //       },
  //       "buttons": {
  //         "create_new_project": "إنشاء مشروع جديد",
  //         "upload": "رفع"
  //       }
  //     },
  //     "share_creation_form": {
  //       "title": "مشاركة إصدار الإبداع: {{ creation_name }}",
  //       "description": "شارك هذا الإصدار من عملك مع شخص آخر. من خلال رابط فريد في البريد الإلكتروني، يمكن عرض عملك أو الاستماع إليه للفترة التي تحددها أدناه.",
  //       "fields": {
  //         "email_label": "عنوان البريد الإلكتروني",
  //         "email_helpertext": "أدخل عنوان البريد الإلكتروني للشخص الذي ترغب في المشاركة معه",
  //         "duration_label": "المدة",
  //         "duration_helpertext": "حدد المدة التي يكون الرابط الفريد فيها صالحًا",
  //         "notes_label": "ملاحظات",
  //         "notes_helpertext": "أضف ملاحظة للمستلم"
  //       }
  //     },
  //     "shared_links_search_form": {
  //       "placeholder": "البحث بواسطة المشروع، الإبداع أو البريد الإلكتروني للمستلم"
  //     },
  //     "duration_units": {
  //       "minutes": "دقائق",
  //       "hour": "ساعة",
  //       "hours": "ساعات",
  //       "day": "يوم",
  //       "days": "أيام",
  //       "week": "أسبوع",
  //       "weeks": "أسابيع",
  //       "month": "شهر",
  //       "months": "أشهر",
  //       "year": "سنة",
  //       "years": "سنوات",
  //       "all": "جميع الأوقات"
  //     },
  //     "Recording": "تسجيل",
  //     "Lyric": "كلمات",
  //     "Sheet Music": "نوتة موسيقية",
  //     "Video": "فيديو",
  //     "Picture": "صورة",
  //     "Agreement": "اتفاقية",
  //     "Other": "آخر",
  //     "shared_by": "مشارك بواسطة {{ name }} في {{ date }}",
  //     "timestamped_by": "تم ختم الطابع الزمني بواسطة {{ name }}",
  //     "link_expires_on": "ينتهي هذا الرابط في {{ date }}",
  //     "link_expired": "لقد انتهى هذا الرابط أو تم تعديل أذونات الملف.",
  // "external_share": {
  //   "otp_code_requested_check_email": "تم طلب رمز OTP، يرجى التحقق من بريدك الإلكتروني",
  //   "otp_code_invalid": "رمز OTP الذي أدخلته غير صالح",
  //   "otp_code_request_error": "حدث خطأ أثناء طلب رمز OTP",
  //   "access_protected_content": "الوصول إلى المحتوى المحمي",
  //   "content_exclusive_for": "هذا المحتوى حصري لـ",
  //   "enter_otp": "أدخل رمز كلمة المرور لمرة واحدة",
  //   "enter_otp_caption": "يرجى إدخال الرمز الأبجدي الرقمي المكون من 6 أحرف المرسل إلى بريدك الإلكتروني",
  //   "otp_dont_have_code": "لا تملك رمزًا؟",
  //   "request_otp": "طلب OTP",
  //   "otp_proceed_request_otp": "للمتابعة، اطلب رمز المرور لمرة واحدة بالضغط على الزر أدناه.",
  //   "already_member_login": "هل أنت بالفعل عضو بهذه البريد الإلكتروني؟",
  //   "login_to_access": "سجّل الدخول للوصول إلى المحتوى.",
  //   "member_login": "تسجيل دخول الأعضاء",
  //   "or": "أو",
  // }
  //   }
  // }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('lng') || themes[THEME].defaultLanguage,
    fallbackLng: themes[THEME].defaultLanguage,
    interpolation: {
      escapeValue: false,
    },
  });

// Set Moment.js locale
moment.locale(i18n.language);

// set document to rtl if arabic
if (i18n.language === 'ar') {
  document.dir = 'rtl';
}

export default i18n;

import { Avatar, Box } from "@mui/material";
import { THEME } from "./Settings";
import { themes } from "./theme/themes";
import moment from "moment";

const themeName = THEME
const theme = themes[themeName];

// Helper to generate colorful avatar
function stringToColor(string: string) {
  // return theme.avatarcolor if exists
  if (theme.avatarColor) {
    return theme.avatarColor;
  }

  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string) {
  // If name has no spaces, use first 2 characters
  let children;
  if (name.indexOf(" ") === -1) {
    children = name.substring(0, 2).toUpperCase();
  } else {
    children = `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
  }
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: children,
  };
}

export const getUserDisplayName = (user) => {
  let displayName = `${user?.first_name} ${user?.last_name}`;
  if (!user?.first_name || !user?.last_name) {
    displayName = user?.email;
  }
  return displayName;
}

export const getUserAvatar = (user) => {
  const displayName = getUserDisplayName(user);
  const avatarProps = stringAvatar(displayName);
  return (
    <Avatar
      sx={{
        bgcolor: avatarProps.sx.bgcolor,
        fontSize: 12,
        width: 24,
        height: 24,
      }}
    >
      {avatarProps.children}
    </Avatar>
  );
};

export const getUserAvatarWithText = (user) => {
  const displayName = getUserDisplayName(user);
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {getUserAvatar(user)}
      {displayName}
    </Box>
  )
}

export const renderExpiryDate = (dateString: string, t) => {
  let date = moment.utc(dateString).local().format("LLL");
  return date === "Invalid date" ? t("never expires").toLowerCase() : date;
}
import { Alert, Snackbar } from '@mui/material'
import React from 'react'

interface DeleteVersionErrorProps {
    open: boolean
    onClose: () => void
    message: string
}

const ErrorAlert: React.FC<DeleteVersionErrorProps> = ({ open, onClose, message }) => {
    return (
        <Snackbar
            open={open}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert severity="error" variant="filled" onClose={onClose}>{message}</Alert>
        </Snackbar>
    )
}

export default ErrorAlert

import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Box, Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import CreationTypeIcon from './CreationTypeIcon';
import moment from 'moment';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import { Link } from 'react-router-dom';

interface RecentCreationsItemProps {
  id: string;
  projectId: string;
  projectName: string;
  creationType: string;
  currentVersionName: string;
  dateModified: string;
}

const RecentCreationsItem: React.FC<RecentCreationsItemProps> = (({ id, projectId, projectName, creationType, currentVersionName, dateModified }) => {
  const theme = useTheme();

  return (
    <Card key={id} sx={{
      background: "#F4F7FE9E",
      boxShadow: 0,
      margin: 1,
      minWidth: { md: "200px", xs: "170px" },

      width: 200,
      height: 200

    }}>
      <Link
        to={`/projects/${projectId}/${id}`}
        style={{
          textDecoration: "none",
        }}
      >
        <CardContent sx={{ display: "flex", gap: 1, flexDirection: "column", height: "100%" }}>
          <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1, color: theme.palette.text.primary }}>
            <DescriptionRoundedIcon fontSize="small" /><Typography
              variant={"body2"}
              title={currentVersionName}
              style={{
                whiteSpace: 'normal',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                wordWrap: 'break-word',
                // maxWidth: '150ch'
              }}
            >
              {currentVersionName}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", height: 100, justifyItems: "end", gap: 1, mt: "auto" }}>
            <Box sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: theme.palette.secondary.main,
              p: 2,
              borderRadius: 2,
            }}>
              <Avatar sx={{ bgcolor: theme.palette.primary.main }} variant="rounded">
                <CreationTypeIcon sx={{ color: "white" }} creationType={creationType} />
              </Avatar>
            </Box>
            <Typography variant="caption" sx={{ color: "rgba(172, 172, 185, 1)" }}>
              {moment(dateModified).format("LLL")}
            </Typography>
          </Box>
        </CardContent>
      </Link>
    </Card>
  )
}
)


export default RecentCreationsItem


import React from 'react';
import { PaginationProps as MuiPaginationProps } from '@mui/material/Pagination';
import { Box, Pagination as MuiPagination } from '@mui/material';



const Pagination = (props) => { // Set defaultPage to 1 if not provided
    if (props?.count < 2) {
        return null;
    }

    let p = "20px 0px"
    if (props?.padding) {
        p = props.padding
    }

    return (
        <Box
            sx={{
                display: "flex",
                gap: "20px",
                justifyContent: "center",
                p: p,
            }}
        >
            <MuiPagination
                variant="outlined" shape="rounded"
                sx={{ display: 'flex', justifyContent: 'center', p: p }}
                {...props}
            />
        </Box>
    );
};

export default Pagination;

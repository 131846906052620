import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { CssBaseline } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { IDPProvider, defaultDevSettings } from '@copyrightdelta/drx-frontend-api'
import { IDP_PROVIDER_URL } from "./Settings";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient(
  {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1,
      },
    },
  }
)

var settings = Object.assign({}, defaultDevSettings)
// TODO: URL must come from environment variable or configuration settings
settings = Object.assign(settings, { apiBaseUrl: IDP_PROVIDER_URL })

root.render(
  <>
    {/* <React.StrictMode> */}
    <CssBaseline />
    <IDPProvider idpConfig={settings}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </IDPProvider>
    {/* </React.StrictMode> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useContext } from 'react'
import i18n from '../i18n';
import moment from 'moment';
import { LANGUAGES } from '../Constants';
import Flag from "react-world-flags";
import { updateUser } from '../api/user/updateUser';
import { useMutation } from '@tanstack/react-query';
import { TenantConfigContext } from '../context/tenantConfigContext';

type LanguageSelectProps = {
    small?: boolean;
    width?: string;
    shorthand?: boolean;
    isExternal?: boolean;
}

const LanguageSelect: React.FC<LanguageSelectProps> = (props) => {
    const tenantConfig = useContext(TenantConfigContext);
    const { mutate } = useMutation({
        mutationFn: updateUser,
        onSuccess: (data) => {
            let lng = data.language;
            if (data.language === 'zh-hans') {
                lng = 'zh';
            }

            i18n.changeLanguage(lng);
            localStorage.setItem('lng', lng);

            let locale = i18n.language;
            if (lng === 'zh') {
                locale = 'zh-cn';
            }
            moment.locale(locale);
            // if rtl language set dir to rtl
            if (["ar", "he", "fa"].includes(lng)) {
                document.dir = "rtl";
            } else {
                document.dir = "ltr";
            }
        },
        onError: (error) => {
            console.log("Error updating language", error)
        }
    });

    const onChangeLang = async (e: SelectChangeEvent<string>) => {
        if (props?.isExternal) {
            localStorage.setItem('lng', e.target.value);
            i18n.changeLanguage(e.target.value);
            return;
        }
        mutate({ language: e.target.value });
    };

    const envLanguages = LANGUAGES.filter((lang) => tenantConfig?.languages?.includes(lang.code));

    const style = props?.small ? { m: 0, p: 0 } : {};
    return (
        <MenuItem sx={style}>
            <FormControl sx={{ m: 0, border: 0, width: props?.width || "100%" }} size="small">
                <Select
                    defaultValue={i18n.language}
                    onChange={onChangeLang}
                    value={i18n.language}
                >
                    {envLanguages.map(({ label, code, flag }) => {
                        if (props?.shorthand) {
                            label = code.toUpperCase()
                        }

                        return (
                            <MenuItem key={code} value={code} sx={{ width: props?.width || "100%" }}>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Flag code={flag} height={20} width={20} /> {label}
                                </Box>
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </MenuItem>
    )
}

export default LanguageSelect

import api from "../base";
import { useQuery } from "@tanstack/react-query";


type Project = {
    id: string;
    name: string;
    description: string;
    starred: boolean;
    parent_id: string;
    date_created: string;
    date_updated: string;
    created_by: any;
    updated_by: any;
};

type GetProjectOptions = {
    projectId: string;
};

export async function getProject({ projectId }: GetProjectOptions): Promise<Project> {
    const response = await api.get(`/projects/${projectId}/`);
    return response.data;
}

type UseGetProjectOptions = {
    projectId: string;
    config?: any;
};

export const useGetProject = ({ projectId, config = {} }: UseGetProjectOptions) => {
    return useQuery<Project>({
        ...config,
        queryKey: ["project", projectId],
        queryFn: () => getProject({ projectId }),
        retry: 1,
    });
};

import { Divider, FormControlLabel, FormGroup, Paper, Stack, Switch, ToggleButton, ToggleButtonGroup, styled } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
        textTransform: 'capitalize',
    },
}));


interface ChartOptionsProps {
    timeframe: string,
    cumulative: boolean,
    setTimeframe: (timeframe: string) => void,
    setCumulative: (cumulative: boolean) => void
    handleTimeframe: (event: React.MouseEvent<HTMLElement>, newTimeframe: string | null) => void
}

const ChartOptions = (props: ChartOptionsProps) => {
    const { timeframe, cumulative, setTimeframe, setCumulative, handleTimeframe } = props;
    const { t } = useTranslation()
    return (
        <Stack direction="row" gap={2}>
            <Paper
                elevation={0}
                sx={{
                    display: 'flex',
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    flexWrap: 'wrap',
                    gap: 1,
                    alignItems: 'center',
                }}
            >
                <StyledToggleButtonGroup
                    size="small"
                    value={timeframe}
                    exclusive
                    onChange={handleTimeframe}
                    aria-label="text alignment"
                    sx={{ textTransform: 'none' }}
                >
                    <ToggleButton value="day" aria-label="24 hours">24 {t('duration_units.hours')}</ToggleButton>
                    <ToggleButton value="week" aria-label="7 days">7 {t('duration_units.days')}</ToggleButton>
                    <ToggleButton value="month" aria-label="30 days">30 {t('duration_units.days')}</ToggleButton>
                    <ToggleButton value="6_months" aria-label="1 year">6 {t('duration_units.months')}</ToggleButton>
                    <ToggleButton value="year" aria-label="1 year">1 {t('duration_units.year')}</ToggleButton>
                    <ToggleButton value="all" aria-label="All time">{t('duration_units.all')}</ToggleButton>
                </StyledToggleButtonGroup>
                <Divider flexItem orientation="vertical" />
                <FormGroup sx={{ marginLeft: 1 }}>
                    <FormControlLabel
                        control={<Switch checked={cumulative} size="small"
                            onChange={() => setCumulative(!cumulative)} />}
                        label={t('cumulative')}
                    />
                </FormGroup>
            </Paper>
        </Stack >
    )
}

export default ChartOptions

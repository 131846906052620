import { TableCell, styled } from "@mui/material";


const Cell = styled(TableCell)(({ theme }) => ({
    borderBottom: "none",
    verticalAlign: "top",
}));
const CellHeader = styled(Cell)(({ theme }) => ({
    fontSize: "1rem",
    fontWeight: 500,
    color: theme.palette.text.primary,
    paddingTop: 20,
}));
export const CellKey = styled(Cell)(({ theme }) => ({
    width: "35%",
    wordBreak: "keep-all"
}));
export const CellValue = styled(Cell)(({ theme }) => ({
    color: "black",
    width: "65%",
}));
export const CellValueBreakAll = styled(Cell)(({ theme }) => ({
    color: "black",
    width: "65%",
    wordBreak: "break-all"
}));
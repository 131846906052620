import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Modal,
  Typography,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemText,
  Stack,
  useTheme,
  MenuItem,
  CircularProgress,
  Snackbar,
  Alert,
  useMediaQuery,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MarkEmailReadRoundedIcon from '@mui/icons-material/MarkEmailReadRounded';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams, useNavigate } from "react-router-dom";
import {
  creationAPI,
  versionsAPI,
  sharingAPI,
} from "../api/TimestampAPI";
import { useStateCallback } from "../Constants";
import FileDisplay from "../components/FileDisplay";
import UploadCreationVersion from "../modals/CreateCreationOrVersion";
import ShareVersion from "../modals/ShareVersion";
import VersionHistoryTimeline from "../components/VersionHistoryTimeline";
import CreationTypeIcon from "../components/CreationTypeIcon";
import TimestampDisplay from "../components/TimestampDisplay";
import SharedList from "../components/SharedList";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import WorkspacePremiumRoundedIcon from '@mui/icons-material/WorkspacePremiumRounded';
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useGetProject } from "../api/projects/getProject";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { StyledMenu } from "../components/Menu/menu";
import { getCreationVersionTimestampCert } from "../api/creations/downloadCreationVersion";
import { resendTimestampConfirmationEmail } from "../api/creations/resendTimestampConfirmationEmail";
import { getfile } from "../api/files";
import fileDownload from "js-file-download";
import { API_ENDPOINT } from "../Settings";
import ConfirmDeleteModal from "../modals/ConfirmDeleteDialog";
import ErrorAlert from "../components/Snackbar/ErrorAlert";
import { Buffer } from "buffer";

const DisplayTitle = ({ project, creationVersion }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box>
      {isSmallScreen ? (
        <>
          <Box display="flex" alignItems="center">
            <IconButton sx={{ pl: 0 }} onClick={() => navigate(`/projects/${project?.id}`)}>
              <ArrowBackIcon sx={{ fontSize: "1rem" }} />
            </IconButton>
            <FolderIcon sx={{ ml: 0, mr: 1, fontSize: "1rem" }} />
            <Typography variant="h6"
              sx={{
                flexGrow: 1,
                fontWeight: 400,
                fontSize: "1rem",
              }}
            >
              {project?.name}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
            <CreationTypeIcon creationType={creationVersion?.creation?.creation_type} sx={{ mr: 1 }} />
            <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: 400 }}>
              {creationVersion?.name}
            </Typography>
          </Box>
        </>
      ) : (
        <Box display="flex" alignItems="center">
          <IconButton onClick={() => navigate(`/projects/${project?.id}`)}>
            <ArrowBackIcon />
          </IconButton>
          <FolderIcon sx={{ ml: 1, mr: 1 }} />
          <Typography variant="h6" sx={{ fontWeight: 400, }}>
            {project?.name}
          </Typography>
          <Typography variant="h6" sx={{ mx: 1 }}>
            /
          </Typography>
          <CreationTypeIcon creationType={creationVersion?.creation?.creation_type} sx={{ mr: 1 }} />
          <Typography variant="h6" sx={{ fontWeight: 400 }}>
            {creationVersion?.name}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const getFileNameFromDownloadUrl = (url: string) => {
  // remove query params from filename
  const queryIndex = url.indexOf('?');
  if (queryIndex > -1) {
    url = url.substring(0, queryIndex);
  }
  // remove everything up to and including first underscore
  const underscoreIndex = url.indexOf('_');
  if (underscoreIndex > -1) {
    url = url.substring(underscoreIndex + 1);
  }
  return url;
}

function VersionedFile() {
  const navigate = useNavigate();
  const { projectId, creationId, versionNr } = useParams();
  // if any are null or udefined, return to previous page
  if (!creationId) {
    navigate(`/projects/${projectId}`);
  } else if (!projectId) {
    navigate(`/projects`);
  }
  const [selectedFile, setSelectFile] = useState(null);
  const [sharingModal, setSharingModal] = useState(false);
  const [droppedFiles, setFiles] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(projectId);
  const [selectedCreationId, setSelectedCreationId] = useState(creationId);
  const [version, setVersion] = useStateCallback(versionNr || -1);
  const [creationVersion, setCreationVersion] = useStateCallback(null);
  const [versions, setVersions] = useState([]);
  const [sharing, setSharing] = useState([]);
  const [content, setContent] = useState(null);
  const [contentType, setContentType] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDownloadingCertificate, setIsDownloadingCertificate] = useState(false);
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
  const [emailSnackBarOpen, setEmailSnackBarOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDeleteErrorOpen, setDeleteErrorOpen] = useState(false);
  const actionsMenuOpen = Boolean(anchorEl);
  const theme = useTheme();
  const { error: getProjectError, data: project } = useGetProject({ projectId });

  if (getProjectError?.message === "Request failed with status code 403") {
    // forbidden
    navigate("/projects");
  }

  useEffect(() => {
    (async () => {
      var localVersion = parseInt(versionNr);

      if (localVersion === -1 || isNaN(localVersion)) {
        try {
          var creation = await creationAPI.getSingle(creationId);
        } catch (error) {
          navigate(`/projects/${projectId}`);
          return;
        }
        localVersion = creation.current_version.version;
        setVersion(localVersion);
      }
      setVersion(localVersion);
      setSharing(await sharingAPI.getShares(creationId, localVersion, false));
      setCreationVersion(
        await versionsAPI.getSingle(creationId, localVersion),
        async (s) => {
          setContent(await sharingAPI.getFile(s.id, s.content_type));
          setContentType(s.content_type);
        }
      );
      setVersions(await versionsAPI.get(creationId));
    })();
  }, [versionNr, version]);

  // Create a function that returns the url using getFile
  const getUpdatedFileDownloadUrl = async () => {
    const file = await sharingAPI.getFile(creationVersion.id, contentType);
    let url = file?.url;
    if (url.startsWith("/")) {
      url = API_ENDPOINT + url;
    }
    return url;
  };


  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
    if (acceptedFiles.length > 0) {
      setSelectFile(acceptedFiles[0]);
    }
  }, []);

  const handleCloseShareingModal = async () => {
    setSharingModal(false);
    setSharing(await sharingAPI.getShares(creationId, version, false));
    setFiles([]);
  };

  const handleCloseCreationModal = () => {
    setSelectFile(null);
    setFiles([]);
  };

  const handleActionsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionsMenuClose = () => {
    setAnchorEl(null);
  };

  const fileIsTimestamped = () => {
    if (!creationVersion?.timestamps) {
      return false;
    }
    if (creationVersion.timestamps.length === 0) {
      return false;
    }
    return creationVersion?.timestamps.every(
      (timestamp) => timestamp.status === "Completed"
    );
  }

  const downloadFile = async () => {
    setIsDownloadingFile(true);
    const downloadResp = await getfile(creationVersion.id, creationVersion.content_type, true);
    let downloadUrl = downloadResp?.url
    if (downloadUrl.startsWith("/")) {
      downloadUrl = API_ENDPOINT + downloadUrl;
    }
    const url = window.URL.createObjectURL(new Blob([downloadUrl]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `${getFileNameFromDownloadUrl(downloadUrl)}`);
    document.body.appendChild(link);
    link.click();
    setIsDownloadingFile(false);
    handleActionsMenuClose();
  }

  const downloadCertificate = async () => {
    setIsDownloadingCertificate(true);
    const base64Pdf = await getCreationVersionTimestampCert({ creationId, versionNum: version, filename: `${creationVersion.name}_timestamp_certificate.pdf` });
    const buff = Buffer.from(base64Pdf, 'base64');
    fileDownload(buff, `${creationVersion.name}_timestamp_certificate.pdf`, 'application/pdf');
    setIsDownloadingCertificate(false);
    handleActionsMenuClose();
  }

  const sendConfirmationEmail = async () => {
    const resp = await resendTimestampConfirmationEmail({ creationId, versionNum: version });
    if (resp.status === 202) {
      setEmailSnackBarOpen(true);
      // delay for 5s to allow the email to be sent
      setTimeout(() => {
        handleActionsMenuClose();
      }, 2000);
    }
  }

  const handleDeleteCreationVersion = async () => {
    try {
      await versionsAPI.delete(creationId, version);
      setDeleteModalOpen(false);
      // Go to latest version if exists, else go to project page
      if (versions.length === 1) {
        navigate(`/projects/${projectId}`);
      } else if (version === versions[versions.length - 1].version) {
        const newVersionNo = versions[versions.length - 2].version;
        setVersion(newVersionNo);
        navigate(`/projects/${projectId}/${creationId}/${newVersionNo}`);
      }
      navigate(`/projects/${projectId}/${creationId}`);
    } catch (error) {
      setDeleteModalOpen(false);
      setDeleteErrorOpen(true);
    }
  }

  const handlePostCreateCreation = async () => {
    const versions = await versionsAPI.get(creationId);
    setSelectFile(null);
    setFiles([]);
    setVersions(versions); // Specify the type as 'any[]'
    // get latest version from versions
    const newVersionNo = versions[versions.length - 1].version;
    setVersion(newVersionNo);
    navigate(`/projects/${projectId}/${creationId}/${newVersionNo}`);
  };
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        p: 1,
        overflow: "auto",
        flexWrap: { lg: "no-wrap", xs: "wrap" },
      }}
    >
      <Modal open={sharingModal}>
        <ShareVersion
          open={sharingModal}
          onClose={() => {
            handleCloseShareingModal();
          }}
          version_object={creationVersion}
          current_shares={sharing}
        />
      </Modal>

      <UploadCreationVersion
        open={selectedFile != null}
        onClose={handleCloseCreationModal}
        onSubmit={handlePostCreateCreation}
        selectedFile={selectedFile}
        creationId={selectedCreationId}
        projectId={selectedProjectId}
      />
      <Box sx={{ flex: "2 1 0", pr: { lg: 0, xs: "0" }, marginTop: "0px", maxWidth: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >

          <DisplayTitle project={project} creationVersion={creationVersion} />

          <Box
            sx={{
              display: "flex",
              gap: "15px",
              flexWrap: { lg: "no-wrap", xs: "wrap" },
              justifyContent: { lg: "inherit", xs: "end" },
            }}
          >
            <Button
              id="actions-button"
              aria-controls={actionsMenuOpen ? 'actions-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={actionsMenuOpen ? 'true' : undefined}
              variant="outlined"
              onClick={handleActionsMenuClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              {t("actions")}
            </Button>
            <StyledMenu
              anchorEl={anchorEl}
              open={actionsMenuOpen}
              onClose={handleActionsMenuClose}
              id="actions-menu"
            >
              <MenuItem onClick={() => downloadFile()} disableRipple>
                {!isDownloadingFile ?
                  (<><FileDownloadOutlinedIcon /> {t("download_file")}</>) :
                  (<><CircularProgress size={18} sx={{ mr: "12px" }} /> {t("downloading_file")}</>)}
              </MenuItem>
              <MenuItem onClick={() => downloadCertificate()} disableRipple disabled={!fileIsTimestamped()}>
                {!isDownloadingCertificate ?
                  (<><WorkspacePremiumRoundedIcon /> {t("download_certificate")}</>) :
                  (<><CircularProgress size={18} sx={{ mr: "12px" }} /> {t("downloading_certificate")}</>)}
              </MenuItem>
              <MenuItem onClick={() => sendConfirmationEmail()} disableRipple disabled={!fileIsTimestamped()}>
                {emailSnackBarOpen ? <MarkEmailReadRoundedIcon /> : <ForwardToInboxOutlinedIcon />} {t("resend_confirmation_email")}
              </MenuItem>
              <MenuItem onClick={() => setDeleteModalOpen(true)} disableRipple>
                <DeleteIcon />
                {t("delete")}
              </MenuItem>
            </StyledMenu>
            <ConfirmDeleteModal
              open={isDeleteModalOpen}
              message={t("delete_creation_version_message")}
              onConfirm={handleDeleteCreationVersion}
              onClose={() => setDeleteModalOpen(false)}
            />
            <Snackbar
              open={emailSnackBarOpen}
              autoHideDuration={5000}
              onClose={() => setEmailSnackBarOpen(false)}
              message={t("timestamp_confirmation_email_sent")}
            />
            <ErrorAlert
              open={isDeleteErrorOpen}
              onClose={() => setDeleteErrorOpen(false)}
              message={t("delete_creation_version_error")}
            />
            <Dropzone onDrop={onDrop}>
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <Button
                    variant="contained"
                    startIcon={<LayersOutlinedIcon />}
                  >
                    {t("new version")}
                  </Button>
                </div>
              )}
            </Dropzone>
          </Box>
        </Box>
        <Typography variant="subtitle1" color={theme.palette.text.secondary} sx={{ my: 2 }}>
          {t('creation_version_view_description')}
        </Typography>
        <Box
          sx={{
            // maxWidth: { lg: "99%", xs: "100%" },
            // height: "80vh",
            m: "0 auto",
            boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.1)",
            p: { lg: "30px", xs: "10px" },
            py: { lg: "20px", xs: "10px" },
            mb: 2,
            borderRadius: "5px",
            backgroundColor: "background.paper",
          }}
        >
          <Box
            sx={{ maxHeight: "80vh", overflow: "auto", height: "100%" }}
            className="versionOverflow"
          >
            {content?.url != null ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <FileDisplay
                  fileExtention={creationVersion?.file_type}
                  url={content.url}
                  fileName={creationVersion?.name}
                  createdBy={
                    creationVersion?.created_by?.first_name +
                    " " +
                    creationVersion?.created_by?.last_name
                  }
                  getUpdatedFileDownloadUrl={getUpdatedFileDownloadUrl}
                />
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          flex: "1 1 auto",
          pl: { lg: 2, xs: "0" },
          maxWidth: { lg: "30%", xs: "100%" },
          overflow: "hidden",
          marginTop: "0px",
        }}
      >
        <Typography variant="h6">
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <LayersOutlinedIcon /> {t("version history")}
          </Box>
        </Typography>
        <VersionHistoryTimeline
          versions={versions}
          current_version={version}
          navigate={navigate}
        />
        <Typography
          variant="h6"
          gutterBottom
          sx={{ display: "flex", alignItems: "center", gap: "10px", pt: 3 }}
        >
          <GroupOutlinedIcon />
          <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {t("shared_with")}
            <Button variant="outlined" size="small" startIcon={<PersonAddAltIcon />} onClick={() => setSharingModal(true)}>
              {t("share")}
            </Button>
          </Box>

        </Typography>
        <List>
          {sharing?.length === 0 ? (
            <ListItem>
              <ListItemText primary={t("version not shared")} primaryTypographyProps={{ variant: "body2" }} sx={{ color: "rgba(92, 92, 111, 1)" }} />
            </ListItem>
          ) : null}
          <SharedList
            shares={sharing}
            creationId={creationId}
            version={version}
          />
        </List>
        <Typography variant="h6" gutterBottom
          sx={{ display: "flex", alignItems: "center", gap: "10px", pt: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <InfoOutlinedIcon /> {t("info")}
          </Box>
        </Typography>
        <TimestampDisplay creationVersion={creationVersion} />
      </Box>
    </Box >
  );
}

export default VersionedFile;

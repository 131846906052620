import { Alert, Box, Button, CircularProgress, Divider, OutlinedInput, Snackbar, Stack, Typography } from '@mui/material';
import React, { useRef, useEffect } from 'react';
import { sharingAPI } from '../../api/TimestampAPI';
import { useIDPContext } from '@copyrightdelta/drx-frontend-api';
import PinRoundedIcon from '@mui/icons-material/PinRounded';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import { useTranslation } from 'react-i18next';

type Props = {
    onSuccess?: (otpCode: string) => void;
    onError?: () => void;
    onRequestSuccess?: () => void;
    onRequestError?: () => void;
    shareId: string;
    email: string;
};

// const exampleOtpLocalStorageValue = {
//     "<shareId>": {
//         otp_id: "123456",
//         expiry: "2022-12 12:00:00"
//     },
//     "<shareId2": {
//         otp_id: "123456",
//         expiry: "2022-12 12:00:00"
//     }
// }

const DRX_TS_OTP_SHARING_KEY = "drx-ts-otp-sharing";

// modified from https://gist.github.com/Bloody-Badboy/cf779c4768230ecbdc710787422f1234
const OtpInput: React.FC<Props> = ({ onSuccess, onError, onRequestSuccess, onRequestError, shareId, email }) => {
    const inputsRef = useRef<Array<HTMLInputElement>>([]);
    const { login, OTPLogin } = useIDPContext();
    const [otpId, setOtpId] = React.useState("");
    const [otpIdExpiration, setOtpIdExpiration] = React.useState("");
    const [otpRequestedPopup, setOtpRequestedPopUp] = React.useState(false);
    const [otpVerified, setOtpVerified] = React.useState(false);
    const [otpInputErrorPopup, setOtpInputErrorPopup] = React.useState(false);
    const [otpRequestErrorPopup, setOtpRequestErrorPopup] = React.useState(false);
    const [requestingOtp, setRequestingOtp] = React.useState(false);

    const { t } = useTranslation();

    const currentUrl = window.location.href;

    useEffect(() => {
        if (otpId) {
            inputsRef.current[0].focus();
        }
    }, []);

    // Check if OTP code is already requested and saved in local storage
    useEffect(() => {
        const otpSharingDataJson = localStorage.getItem(DRX_TS_OTP_SHARING_KEY);
        let otpSharingData = JSON.parse(otpSharingDataJson || "{}");
        const otpData = otpSharingData[shareId];
        if (otpData) {
            const { otp_id, expiration } = otpData;
            // check if the otp is expired
            const now = new Date();
            const expirationDate = new Date(expiration);
            if (now < expirationDate) {
                setOtpId(otp_id);
                setOtpIdExpiration(expiration);
            } else {
                setOtpId("");
                setOtpIdExpiration("");
                delete otpSharingData[shareId];
                localStorage.setItem(DRX_TS_OTP_SHARING_KEY, JSON.stringify(otpSharingData));
            }
        }
    }, []);

    const loginWithRedirect = () => {
        // set the redirect url to the current url in local storage
        localStorage.setItem("drx-ts-redirectUrl", currentUrl);
        login();
    }

    const requestOtp = async () => {
        // Request from API
        try {
            setRequestingOtp(true);
            const resp = await sharingAPI.requestOtpCode(shareId);
            const otp_id = resp.otp_id;
            const expiration = resp.expiration;
            setOtpId(otp_id);
            setOtpIdExpiration(expiration);
            // Save to local storage
            const otpSharingDataJson = localStorage.getItem(DRX_TS_OTP_SHARING_KEY);
            let otpSharingData = JSON.parse(otpSharingDataJson || "{}");
            otpSharingData[shareId] = { otp_id, expiration };
            localStorage.setItem(DRX_TS_OTP_SHARING_KEY, JSON.stringify(otpSharingData));
            setOtpRequestedPopUp(true);
            setRequestingOtp(false);
            onRequestSuccess && onRequestSuccess();
        } catch (e) {
            setOtpRequestErrorPopup(true);
            onRequestError && onRequestError();
            return;
        }
    }

    const verifyOtp = async (otpCode) => {
        // verify otp
        try {
            await OTPLogin(otpId, otpCode);
            setOtpVerified(true);
        } catch (e) {
            setOtpInputErrorPopup(true);
        }
    }


    const sendResult = () => {
        const res = inputsRef.current.map((input) => input.value).join('');
        res.length === 6 && verifyOtp(res);
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value },
        } = e;

        const nextElementSibling = e.target.parentElement?.nextElementSibling?.firstChild;

        if (value.length > 1) {
            e.target.value = value.charAt(0);
            nextElementSibling && (nextElementSibling as HTMLInputElement).focus();
        } else {
            if (value.match('[0-9A-Za-z]{1}')) {
                // check if next element is an input field (not a submit button or anything else)
                if (nextElementSibling && (nextElementSibling as HTMLInputElement).tagName === 'INPUT') {
                    nextElementSibling && (nextElementSibling as HTMLInputElement).focus();
                }
            } else {
                e.target.value = '';
            }
        }
        sendResult();
    };

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = e;
        const target = e.target as HTMLInputElement;
        const previousElementSibling = target.parentElement?.previousElementSibling?.firstChild;
        if (key === 'Backspace') {
            if (target.value === '' && previousElementSibling) {
                (previousElementSibling as HTMLInputElement).focus();
                (previousElementSibling as HTMLInputElement).value = "";
                e.preventDefault();
            } else {
                target.value = '';
            }
            sendResult();
        }
    };

    const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.select();
    };

    const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const pastedValue = e.clipboardData.getData('Text');

        let currentInput = 0;

        for (let i = 0; i < pastedValue.length; i++) {
            const pastedCharacter = pastedValue.charAt(i);
            const currentInputElement = inputsRef.current[currentInput];
            const currentValue = currentInputElement.value;
            if (pastedCharacter.match('[0-9A-Za-z]{1}')) {
                if (!currentValue) {
                    currentInputElement.value = pastedCharacter;
                    const nextElementSibling =
                        currentInputElement.parentElement?.nextElementSibling?.firstChild;

                    if (nextElementSibling !== null && nextElementSibling !== undefined) {
                        (nextElementSibling as HTMLInputElement).focus();
                        currentInput++;
                    }
                }
            }
        }
        sendResult();

        e.preventDefault();
    };

    return (
        <Stack gap={2}>
            {/* OTP Requested Popup */}
            <Snackbar
                open={otpRequestedPopup}
                autoHideDuration={10000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={(e, r) => setOtpRequestedPopUp(false)}
            >
                <Alert
                    onClose={() => setOtpRequestedPopUp(false)}
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    {t("external_share.otp_code_requested_check_email")}
                </Alert>
            </Snackbar>
            {/* Otp Validation Error Popup */}
            <Snackbar
                open={otpInputErrorPopup}
                autoHideDuration={10000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={(e, r) => setOtpInputErrorPopup(false)}
            >
                <Alert
                    onClose={() => setOtpInputErrorPopup(false)}
                    severity="error"
                    sx={{ width: '100%' }}
                >
                    {t("external_share.otp_code_invalid")}
                </Alert>
            </Snackbar>
            {/* OTP Request Error */}
            <Snackbar
                open={otpRequestErrorPopup}
                autoHideDuration={10000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={(e, r) => setOtpRequestErrorPopup(false)}
            >
                <Alert
                    onClose={() => setOtpRequestErrorPopup(false)}
                    severity="error"
                    sx={{ width: '100%' }}
                >
                    {t("external_share.otp_code_request_error")}
                </Alert>
            </Snackbar>

            <Typography variant="h6">
                {t("external_share.access_protected_content")}
            </Typography>
            <Box>
                <Typography variant="body2">{t("external_share.content_exclusive_for")}:</Typography>
                <Typography variant="body1" sx={{ fontWeight: 600, mt: 1 }}>{email}</Typography>
            </Box>

            {otpId ? (
                <>
                    <Typography variant="subtitle1">{t("external_share.enter_otp")}</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', m: "0 auto", textAlign: "center" }}>
                        {Array.from(Array(6).keys()).map((i) => (
                            <OutlinedInput
                                key={i}
                                onChange={handleOnChange}
                                onKeyDown={handleOnKeyDown}
                                onFocus={handleOnFocus}
                                onPaste={handleOnPaste}
                                inputProps={{
                                    maxLength: 1,
                                    style: { textAlign: 'center' },
                                }}
                                // type="tel"
                                inputRef={(el: HTMLInputElement) => (inputsRef.current[i] = el)}
                                autoComplete={i === 0 ? 'one-time-code' : 'off'}
                                sx={{
                                    height: 40,
                                    width: 40,
                                    minWidth: 40,
                                    minHeight: 40,
                                    mr: 0.5,
                                    ml: 0.5,
                                }}
                            />
                        ))}
                    </Box>

                    <Typography variant="caption" sx={{ color: 'gray' }}>
                        {t("external_share.enter_otp_caption")}
                    </Typography>
                    <Typography variant="body2">
                        {t("external_share.otp_dont_have_code")}
                    </Typography>
                    <Button startIcon={<PinRoundedIcon />} disabled={requestingOtp} onClick={requestOtp} variant="contained" sx={{ alignSelf: 'center' }}>
                        {t("external_share.resend_otp")}
                        {requestingOtp && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }} />
                        )}
                    </Button>
                </>
            ) : (
                <>
                    <Typography variant="body2" display="inline">
                        {t("external_share.otp_proceed_request_otp")}
                    </Typography>
                    <Button startIcon={<PinRoundedIcon />} disabled={requestingOtp} onClick={requestOtp} variant="contained" sx={{ alignSelf: 'center' }}>
                        {t("external_share.request_otp")}
                        {requestingOtp && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }} />
                        )}
                    </Button>
                </>
            )}
            <Divider>
                <Typography variant="caption">{t("external_share.or")}</Typography>
            </Divider>
            <Typography variant="body2">{t("external_share.already_member_login")} {t("external_share.login_to_access")}</Typography>
            <Button startIcon={<LoginRoundedIcon />} onClick={loginWithRedirect} variant="contained" sx={{ alignSelf: 'center' }}>{t("external_share.member_login")}</Button>
        </Stack >
    );
};

export default OtpInput;
import { Box, FormControl, MenuItem, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useIDPContext } from '@copyrightdelta/drx-frontend-api';
import { setProfileIdInHeader } from '../api/base';

const ProfileSelect = () => {
    const { switchProfile, selectedProfile, profiles } = useIDPContext()
    // if not set in localstorage, set it to the selected profile
    const localStorageProfile = localStorage.getItem('selectedProfileId')
    if (!localStorageProfile || localStorageProfile === 'undefined') {
        localStorage.setItem('selectedProfileId', selectedProfile)
    }


    const handleProfileChange = async (event) => {
        localStorage.setItem('selectedProfileId', event.target.value)
        await switchProfile(event.target.value)
        // navigate to home page
        setProfileIdInHeader()
        // navigate to home page
        window.location.href = '/'
    }

    const { t } = useTranslation();

    if (profiles?.length === 0 || !profiles) return null

    return (
        <Box>
            <FormControl>
                <TextField
                    select
                    defaultValue={selectedProfile}
                    id="profile"
                    value={selectedProfile}
                    label={t("select_profile")}
                    onChange={(e) => handleProfileChange(e)}
                >
                    {profiles?.map((profile) => (
                        <MenuItem key={profile?.profile_id} value={profile.profile_id}>
                            {profile?.display_name}
                        </MenuItem>
                    ))}
                </TextField>
            </FormControl>
        </Box>
    )
}

export default ProfileSelect

import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { sharingAPI } from "../api/TimestampAPI";
import { stringAvatar } from "../Helper";
import { useState } from "react";
import MoreVert from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import SharingLinkMenu from "./SharingLinkMenu";

const SharedList = ({ shares, creationId, version }) => {
  const [selectedShare, setSelectedShare] = useState(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();


  const displayExpiration = (date) => {
    // If date is in the past, return "Expired"
    // If date is within the next week, return "Expires in X days"
    // If date is in the future, return "Expires on X"
    // If date is null, return "Never expires"
    let ret = "";
    let showTooltip = true;
    if (date === null) {
      ret = t("never expires");
      showTooltip = false;
    } else if (moment(date).isBefore(moment())) {
      ret = t("expired on") + " " + moment(date).format("LLL");
      showTooltip = false;
    } else if (moment(date).isBefore(moment().add(7, "days"))) {
      let days = moment(date).diff(moment(), "days");
      let hours = moment(date).diff(moment(), "hours") - days * 24;
      let minutes =
        moment(date).diff(moment(), "minutes") - days * 24 * 60 - hours * 60;
      if (days === 0) {
        if (hours === 0) {
          ret = `${t('expires in')} ${minutes} ${t('duration_units.minutes')}`;
        } else if (hours === 1) {
          ret = `${t('expires in')} ${hours} ${t('duration_units.hour')}, ${minutes} ${t('duration_units.minutes')}`;
        } else {
          ret = `${t('expires in')} ${hours} ${t('duration_units.hours')}, ${minutes} ${t('duration_units.minutes')}`;
        }
      } else if (days === 1) {
        ret = `${t('expires in')} ${days} ${t('duration_units.day')}, ${hours} ${t('duration_units.hours')}`;
      } else {
        ret = `${t('expires in')} ${days} ${t('duration_units.days')}, ${hours} ${t('duration_units.hours')}`;
      }
    } else {
      ret = `${t('expires on')} ${moment(date).format("LLL")}`;
      showTooltip = false;
    }

    return showTooltip ? (
      <Tooltip title={moment(date).format("LLL")} placement="top">
        <span>{ret}</span>
      </Tooltip>
    ) : (
      ret
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, share) => {
    setAnchorEl(event.currentTarget);
    setSelectedShare(share);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedShare(null);
  };

  const handleDelete = async () => {
    await sharingAPI.unshareFile(
      creationId,
      version,
      selectedShare.id
    );
    window.location.reload();
    handleClose();
  };
  return (
    <>
      <SharingLinkMenu anchorEl={anchorEl} handleClose={handleClose} selectedShare={selectedShare} handleDelete={handleDelete} />
      <List sx={{ width: "100%" }}>
        {shares && shares
          .slice()
          .sort(
            (a, b) =>
              new Date(a.date_expires).getTime() -
              new Date(b.date_expires).getTime()
          )
          .map((share) => (
            <ListItem
              key={share.id}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="options"
                  onClick={(e) => handleClick(e, share)}
                >
                  <MoreVert />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar {...stringAvatar(share.shared_email)}
                  sx={{
                    ...stringAvatar(share.shared_email).sx,
                    width: 36,
                    height: 36,
                    fontSize: 18,
                  }}
                >
                  {share.shared_email.substring(0, 2).toUpperCase()}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{ variant: "body2" }}
                secondaryTypographyProps={{ variant: "caption", sx: { fontSize: 13 } }}
                primary={share.shared_email}
                secondary={displayExpiration(share.date_expires)}
              />
            </ListItem>
          ))}
      </List>
    </>
  );
};

export default SharedList;
